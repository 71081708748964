import React from 'react';
import {CButton, CCol} from "@coreui/react-pro";
import parkway from "../../../assets/brand/parkway.png";
import cvIcon from "../../../assets/icons/e-wallet.svg";
import {isAndroid, isIOS} from "react-device-detect";

function ParkwayWalletAd() {

    const androidLink = 'https://play.google.com/store/apps/details?id=com.parkway.yurwallet';
    const appleLink = 'https://apps.apple.com/app/parkway-wallet/id6451072719';
    const webLink = 'https://parkwaywalletapp.page.link/?link=https%3A%2F%2Fwallet.parkway.ng%3FreferralId%3DWorkNationApp&apn=com.parkway.yurwallet&isi=6451072719&ibi=com.parkway.yurwallet';

    const handleRedirect = () => {
        if (isAndroid) {
            window.open(androidLink, "_blank");
            return
        }
        if (isIOS) {
            window.open(appleLink, "_blank");
            return;
        }
        window.open(webLink, "_blank");
    }

    return (

        <div className=" px-3 bg-black mx-auto text-white py-4 mb-4  rounded">
            <div className={'d-flex flex-row justify-content-end'}>
                <div className={'flex flex-row justify-content-end'}>
                    <img src={parkway} width={94} height={20} alt={""}/>
                </div>

            </div>
            <div className={'position-relative'}>
                <div>
                    <h5>
                        Create a Parkway E- Wallet
                    </h5>
                    <p className="text-small">
                        Experience seamless financial transactions with technology.
                    </p>
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <div className={'mt-4'}>
                        <CButton
                            className={`fw-normal rounded-2 px-3 py-2 border-0 text-white bg-orange-500 mt-2`}
                            onClick={() => handleRedirect()}
                        >
                            CREATE E-WALLET
                        </CButton>
                    </div>
                    <CCol sm={3} className=" d-flex justify-content-end">
                        <img src={cvIcon} width={150} height={80} alt={""}/>
                    </CCol>
                </div>

            </div>
        </div>

    );
}

export default ParkwayWalletAd;
