import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCol,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CRow,
} from '@coreui/react-pro'
import React from 'react'

const DropDownQuestionAccordion = ({ question, handleAnswerChange }) => {

  return (
    <CAccordion className="mt-3">
      <CAccordionItem itemKey={question?.id}>
        <CAccordionHeader>
          <strong>{question?.label}</strong>{' '}
        </CAccordionHeader>
        <CAccordionBody>
          <CRow>
            <CCol>
              <CFormLabel htmlFor={question?.type} className="fw-medium mb-0 labelColor">
                Ideal Answer
              </CFormLabel>
              <CFormSelect
                aria-label="Default select example"
                value={question?.answer}
                name="answer"
                onChange={(e) => handleAnswerChange(question?.id, 'answer', e.target.value)}
                options={question?.options}
                className="py-2 inputBorder"
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CFormCheck
              id={`recommendedOption-${question.id}`}
              label="Must have qualification"
              name="mustHave"
              checked={question?.mustHave}
              onChange={(e) => handleAnswerChange(question?.id, 'mustHave', e.target.checked)}
              className="text-primary fw-bold  w-auto ms-auto"
            />
          </CRow>
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  )
}

export default DropDownQuestionAccordion
