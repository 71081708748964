import React from 'react';
import {CImage, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react-pro";
import CareerBoxLogo from '../../../assets/images/CareerBox.jpg';

function CareerBox({visible, setVisible}) {
    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalHeader className="px-4">
                    <CModalTitle>CareerBox</CModalTitle>
                </CModalHeader>
                <CModalBody className="p-4">
                    <CImage src={CareerBoxLogo} className="w-25" />
                    <h3 className="text-3 mt-2">Get Ready for Work with CareerBox!</h3>
                    <p>🌟 Boost Your Career Potential! 🌟</p>
                    <p className="my-3">Are you ready to take the next step toward your dream job? Join our Readiness for Work Training Program and unlock the skills you need to stand out!</p>
                    <p className="my-3">🔑 What You’ll Gain:</p>
                    <ul>
                        <li>Professional development and interview preparation.</li>
                        <li>CV building and job application tips.</li>
                        <li>Essential workplace skills and confidence.</li>
                        <li>Limited Spots Available! Register now to secure your place and start your journey toward a successful career.</li>
                    </ul>
                    <div className="flex flex-row justify-content-end align-items-end mt-5">
                        <a href="/user/lms/login">
                            <button className="btn btn-md btn-primary text-white">
                                Sign up today with CareerBox!
                            </button>
                        </a>
                        <button className="btn btn-md mx-3 btn-default" onClick={() => setVisible(false)}>Close</button>
                    </div>
                </CModalBody>
            </CModal>
        </>
    );
}

export default CareerBox;
