import { CAvatar } from '@coreui/react-pro'
import React from 'react'
import { ReactComponent as GmailIcon } from '../../../assets/icons/gmailIcon.svg'
import { ReactComponent as TelePhone } from '../../../assets/icons/telePhone.svg'
import { SiGooglemeet } from 'react-icons/si'
import { BiCalendar } from 'react-icons/bi'
import { FaClock } from 'react-icons/fa'
import { getInitials } from 'src/components/Utility'

const ProfileInfoSection = ({ user }) => {
  const avatarInitials = getInitials(user?.fullname)
  return (
    <div className="pe-4 overflow-y-auto" style={{ height: '80vh', paddingBottom: '2rem' }}>
      <div className="d-flex flex-column align-items-center border-bottom pb-2 justify-content-center ">
        <CAvatar color="primary" size="lg" textColor="white">
          {avatarInitials}
        </CAvatar>
        <p className="textPrimary smallText fw-bold" style={{ fontSize: '16px' }}>
          {user.fullname}
        </p>
        <p className="text-muted fst-normal text-center smallText px-2">{user.role || 'Role'}</p>

        <button className="text-grey-700 border-0 bg-transparent fst-light mt-2 text-decoration-underline text-center fw-light smallText px-2">
          View Profile
        </button>
      </div>
      <div className="d-flex flex-column align-items-start gap-2 border-bottom py-4 justify-content-center">
        <p className="text-blue-500 border-0 bg-transparent d-flex gap-1 fst-light mt-2 text-decoration-underline text-center fw-light smallText px-2">
          <GmailIcon className="pr-2 mr-2" />
          <span className="pl-2 ml-2">{user?.email || 'Obed@gmail.com'}</span>
        </p>
        <p className="text-blue-500 border-0 bg-transparent d-flex gap-1 fst-light mt-2 text-decoration-underline text-center fw-light smallText px-2">
          <TelePhone />
          <span className="pl-2 ml-2">090880909090</span>
        </p>
      </div>
      <div className="d-flex flex-column align-items-start border-bottom py-4 px-2 justify-content-center">
        <p className="fw-bolder smallText">Applied For</p>
        <p className="fw-light smallText text-grey-500 d-flex gap-2">
          <span>Job:</span> <span className="pl-2">Front End Developer</span>
        </p>
        <p className="fw-light smallText text-grey-500 d-flex gap-2">
          <span>Date: </span> <span className="pl-2">June 25, 2024</span>
        </p>
      </div>
      <div className="d-flex flex-column align-items-start border-bottom py-4 px-2 justify-content-center">
        <p className="fw-bolder smallText">Skill Test Assessment</p>
        <p className="fw-light text-grey-500 d-flex gap-1" style={{ fontSize: '10px' }}>
          <span>Personality Assessment - </span>{' '}
          <span className="fw-bold text-success">Good Fit</span>
        </p>
        <p className="fw-light text-grey-500 d-flex gap-1" style={{ fontSize: '10px' }}>
          <span>Culture Fitness Assessment -</span>{' '}
          <span className="fw-bold text-warning"> Not A Good Fit</span>
        </p>
        <p className="fw-light text-grey-500 d-flex gap-1" style={{ fontSize: '10px' }}>
          <span>Soft Skill Assessment -</span>{' '}
          <span className="fw-bold  text-warning"> Not A Good Fit</span>
        </p>
        <p className="fw-light text-grey-500 d-flex gap-1" style={{ fontSize: '10px' }}>
          <span>Professional Assessment -</span>{' '}
          <span className="fw-bold  text-success"> Good Fit</span>
        </p>
      </div>

      {/* <div>
        <p className=" fw-bolder smallText pt-4 mb-2">Schedule</p>

        <div className="d-flex align-items rounded-2 overflow-hidden bg-grey100">
          <div
            style={{
              width: '8px',
              background:
                'linear-gradient(180deg, rgba(43, 52, 99, 0.8) 2.86%, rgba(7, 134, 73, 0.4) 123.81%)',
            }}
          />
          <div className="d-flex flex-column ps-2 pb-3" style={{ width: '100%' }}>
            <p className=" fw-bolder smallText greyColor w-100 py-3 border-bottom ">
              Management Interview <br /> Schedule
            </p>
            <div className="py-3 d-flex flex-column ">
              <p className="fw-bold greyColor smallText d-flex align-items-center gap-1 darkGreenText pb-2">
                <SiGooglemeet />
                Good Fit
              </p>
              <p className="fw-bold greyColor smallText d-flex align-items-center gap-1 darkGreenText pb-2">
                <BiCalendar />
                Wednesday, jun.24,2024
              </p>
              <p className="fw-bold greyColor smallText d-flex align-items-center gap-1 darkGreenText pb-2">
                <FaClock />
                10:30AM - 11:15AM
              </p>
            </div>
            <div className="d-flex justify-content-end">
              <button className="smallText bg-warning text-white border-0 me-4 px-2">
                Awaiting
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ProfileInfoSection
