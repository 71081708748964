import { CModal, CModalBody, CModalHeader, CModalTitle, CTable } from '@coreui/react-pro'
import React from 'react'
import ShowLoading from 'src/components/ShowLoading'
import { useGetLMSCourseModuleByID } from 'src/hooks/admin/adminApiHooks'

const columns = [
  { key: 'Name', label: 'Name', _props: { scope: 'col' } },
  { key: 'Summary', label: 'Summary', _props: { scope: 'col' } },
  { key: 'uservisible', label: 'Visible User', _props: { scope: 'col' } },
]

const ViewModuleModal = ({ visible, setVisible, id }) => {
  const { data, isLoading } = useGetLMSCourseModuleByID(id)
  const modules = data?.data?.data
  const items = modules?.map((item, index) => ({
    id: index + 1,
    Name: item?.name,
    Summary: item?.summary || 'NILL',
    uservisible: String(item?.uservisible).toUpperCase(),
  }))

  console.log({ data, isLoading })

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => {
        setVisible(false)
      }}
    >
      <CModalHeader onClose={() => setVisible(false)}>
        <CModalTitle>Course Module</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {isLoading && <ShowLoading />}
        <CTable columns={columns} items={items} striped />
      </CModalBody>
    </CModal>
  )
}

export default ViewModuleModal
