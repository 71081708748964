import React, {useState} from 'react'
import {CButton, CImage} from "@coreui/react-pro";
// import jobImage from "../../assets/images/testJob.png";
import logo from "../../assets/brand/WN-logo.svg";
import {FaAngleDown, FaRegEnvelope} from "react-icons/fa6";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png";
import {useAppContext} from "../../context/user/UserAppProvider";
import {success_notify, timeFormat} from "../../constant";
import {useNavigate} from "react-router-dom";
import appApiCalls from "../../services/api";
import {SOCKET_EVENTS, SOCKET_LISTENERS} from "../../constant/constants";
import useSocket from "../../hooks/user/useSocket";
import useSocketEvent from "../../hooks/user/useSocketEvent";

function SeeJobApplicants({data}) {
    const {user, userId} = useAppContext()
    const [showMoreProfile, setShowMoreProfile] = useState(false)
    const [showMoreFeedback, setShowMoreFeedback] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [applicationSummary, setApplicationSummary] = useState(data?.data?.data?.data?.items[0]?.applyForJob[0])
    const navigate = useNavigate()
    const [loadingMsg, setLoadingMsg] = useState(false)
    const {socket} = useSocket()

    useSocketEvent([
        {
            name: SOCKET_LISTENERS.CHAT_CREATED,
            cb: (data) => {},
        },
    ])

    const setDefaultSrc = (e) => {
        e.target.src = logo
    }

    const chatWithACandidate = async (user) => {
        setLoadingMsg(true)
        try {
            const { firstName, lastName, email } = user
            const chatData = await appApiCalls.getActiveChatUser(email)
            if (!chatData?.data) {
                //   initalize chat user
                const res = await appApiCalls.initializeChatUser({
                    email,
                    firstName,
                    lastName,
                    role: 'User',
                })
                socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: res?.data?.id })
                navigate(`/employer/message/chat?activeUserId=${res?.data?.id}`, {
                    replace: true,
                })
                success_notify('chat created')
            } else {
                socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: chatData?.data?.id })
                navigate(`/employer/message/chat?activeUserId=${chatData?.data?.id}`, {
                    replace: true,
                })

                success_notify('chat created')
            }
        } catch (error) {
            console.log(error)
        } finally {
            // navigate(`/employer/message/chat`, {
            //     replace: true,
            // })
            setLoadingMsg(false)
        }
    }

    return (
            <div className="bg-white h-100 w-90">
                <div className="bg-grey-100 w-100 px-3 py-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-1">
                        <CImage onError={(e) => setDefaultSrc(e)}
                                src={user?.data?.logoUrl || logo} alt="Logo" style={{
                            width: "60px",
                            objectFit: 'contain',
                        }}/>
                        <div className='d-flex flex-column'>
                            <p className="text-black fw-bolder"><strong>{data?.data?.data?.data?.items[0]?.title}</strong></p>
                            <small className="small-text-gray" style={{fontSize: '12px', marginTop: "-4px"}}>Dangote
                                Group, FCT-Abuja Nigeria</small>
                            <small className="text-black-50" style={{fontSize: '11px', marginTop: "-4px"}}>Open |
                                Opened {timeFormat(data?.data?.data?.data?.items[0]?.createdAt)}</small>

                        </div>
                    </div>
                    <div>
                        {/*<CButton className="text-small text-white rounded-0">Manage Job</CButton>*/}
                    </div>
                </div>
                <div className="px-2">
                    {false && <div className="d-flex gap-3 p-2 bg-grey-100 my-2">
                        <div
                            className="border border-1 p-0 position-relative border-primary bg-primary d-flex justify-content-between align-items-center"
                            style={{width: "155px", height: "32px"}}>

                            <select
                                name="isActive"
                                style={{width: "155px", height: "32px", appearance: "none", outline: "none"}}
                                className="bg-transparent text-white ps-2 border rounded-0 border-0 shadow-none text-small"
                            >
                                <option value="" selected>Sort by Relevance</option>

                            </select>
                            <FaAngleDown className="text-white position-absolute" style={{right: 6}}/>
                        </div>

                        <div
                            className="border border-1 p-0 position-relative d-flex justify-content-between align-items-center"
                            style={{width: "155px", height: "32px"}}>

                            <select
                                name="isActive"
                                style={{width: "155px", height: "32px", appearance: "none", outline: "none"}}
                                className="bg-transparent text-black-50 ps-2 border rounded-0 border-0 shadow-none text-small"
                            >
                                <option value="" selected>Location</option>

                            </select>
                            <FaAngleDown className="text-black-50 position-absolute" style={{right: 6}}/>
                        </div>
                        <div
                            className="border border-1 p-0 position-relative d-flex justify-content-between align-items-center"
                            style={{width: "170px", height: "32px"}}>

                            <select
                                name="isActive"
                                style={{width: "170px", height: "32px", appearance: "none", outline: "none"}}
                                className="bg-transparent text-black-50 ps-2 border rounded-0 border-0 shadow-none text-small"
                            >
                                <option value="" selected>Years of Experience</option>

                            </select>
                            <FaAngleDown className="text-black-50 position-absolute" style={{right: 6}}/>
                        </div>
                        <div
                            className="border border-1 p-0 position-relative d-flex justify-content-between align-items-center"
                            style={{width: "155px", height: "32px"}}>

                            <select
                                name="isActive"
                                style={{width: "155px", height: "32px", appearance: "none", outline: "none"}}
                                className="bg-transparent text-black-50 ps-1 border rounded-0 border-0 shadow-none text-small"
                            >
                                <option value="" selected>Skilled In</option>

                            </select>
                            <FaAngleDown className="text-black-50 position-absolute" style={{right: 6}}/>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-between gap-3 py-2">
                        <div className="bg-grey-100 p-2 pt-3 pe-0" style={{width: "430px"}}>
                            <div className="d-flex w-100 pe-2">
                                <small
                                    className="text-black border flex-fill border-start-0 border-top-0 border-end-0 border-bottom-2 pe-2">
                                    <strong>{data?.data?.data?.data?.items[0]?.applyForJob?.length} Candidate(s)</strong>
                                </small>
                            </div>
                            <div className="scrollable-div w-100 pe-1 pb-1" style={{maxHeight: "70vh"}}>
                                {
                                    data?.data?.data?.data?.items[0]?.applyForJob?.map((item, index)=>{
                                        return(
                                            <div
                                                onClick={()=>{
                                                    setApplicationSummary(item)
                                                    setCurrentIndex(index)
                                                }}
                                                key={index}
                                                 className={`${index === currentIndex ? 'bg-green-200 border-2': 'bg-transparent border-0 cursor-pointer'} w-100 border rounded rounded-2 p-2 border-dark mt-4`}>
                                                <div className="d-flex align-items-start">
                                                    <CImage src={item?.user?.profile?.passportUrl || DummyProfilePic} onError={(e) => {
                                                        e.target.src = DummyProfilePic
                                                    }
                                                    } alt="Profile" style={{
                                                        width: '32px',
                                                        height: '32px',
                                                        marginRight: '10px',
                                                        borderRadius: '50%',
                                                    }}/>
                                                    <div className='d-flex flex-column text-black'>
                                                        <small className="text-primary fw-bolder"><strong>{}</strong></small>
                                                        <small className="small-text-gray fw-medium"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            {item?.user?.profile?.professionalSkills?.map((item)=>{
                                                                return item + " | "
                                                            })}

                                                        </small>
                                                        <small className="text-black-50"
                                                               style={{fontSize: '11px', marginTop: "-2px"}}>{item?.user?.profile?.address}</small>

                                                        <div className='d-flex flex-column mt-2'>
                                                            {item?.user?.cvBuilders[0]?.workExperience?.map((item, index)=>{
                                                                return(
                                                                    <small key={index} className="small-text-gray fw-medium"
                                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                        {item?.jobTitle} at {item?.companyName} - <i>{item?.startDate} to {item?.endDate || "Present"}</i>
                                                                    </small>
                                                                )
                                                            })}

                                                            {item?.user?.cvBuilders[0]?.workExperience?.length > 3 && <small
                                                                style={{fontSize: '12px', marginTop: "-2px"}}
                                                                className="cursor-pointer small-text-gray fw-medium
                                                        text-decoration-underline text-italic">See more</small>}


                                                            <small className="small-text-gray fw-medium mt-2"
                                                                   style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                <span className={`${item?.user?.roleFitAnalyzers[0]?.category?.toLowerCase() == "may be good fit"  && "text-yellow"} ${item?.user?.roleFitAnalyzers[0]?.category?.toLowerCase() == "not a good fit"  && "text-danger"} ${item?.user?.roleFitAnalyzers[0]?.category?.toLowerCase() == "good fit"  && "text-green"} fw-bolder`}>{item?.user?.roleFitAnalyzers[0]?.category}</span> Applied ,
                                                                Jul 12, 2024
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                {index !== currentIndex && <hr/>}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div style={{maxWidth: "900px"}} className=" ps-2 d-flex flex-column gap-3 flex-grow-2 flex-fill">
                            <div className="bg-grey-100 p-2 px-3 py-3">
                                <small className="text-primary">Application Summary</small>
                                <div className="d-flex align-items-start mt-3">
                                    <CImage src={applicationSummary?.user?.profile?.passportUrl || DummyProfilePic} onError={(e) => {
                                        e.target.src = DummyProfilePic
                                    }
                                    } alt="Profile" style={{
                                        width: '32px',
                                        height: '32px',
                                        marginRight: '10px',
                                        borderRadius: '50%',
                                    }}/>
                                    <div className='d-flex flex-column text-black'>
                                        <small className="text-primary fw-bolder"><strong>{applicationSummary?.user?.firstName} {applicationSummary?.user?.lastName}</strong></small>

                                        <small className="small-text-gray fw-medium"
                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                            {applicationSummary?.user?.profile?.professionalSkills?.map((item)=>{
                                                return item + " | "
                                            })}
                                        </small>
                                        <small className="text-black-50"
                                               style={{fontSize: '11px', marginTop: "-2px"}}>{applicationSummary?.user?.profile?.address}</small>

                                    </div>
                                </div>
                                <br/>
                                <div className="d-flex align-items-center gap-3">
                                    <CButton
                                        className="text-small py-2 text-white rounded-0"
                                        onClick={() => {
                                            chatWithACandidate(applicationSummary?.user)
                                        }}
                                        disabled={loadingMsg}
                                    >
                                        <FaRegEnvelope className="text-white"/> Send Message
                                    </CButton>
                                    <small
                                        className="cursor-pointer text-green text-decoration-underline fw-bold"
                                        onClick={() => {
                                            navigate(`/employer/applicant/${applicationSummary?.user?.userUuid}`)
                                        }}
                                    >
                                        See Full Profile
                                    </small>
                                </div>
                            </div>

                            <div className="scrollable-div w-100 d-flex flex-column gap-3"
                                 style={{maxHeight: "49vh"}}>
                                <div className="bg-grey-100 p-2 px-3 py-3">
                                    <div className="d-flex">
                                        <small className="text-primary w-50">Profile Insights</small>
                                        <small className="text-primary fw-bolder cursor-pointer" onClick={() => {
                                            setShowMoreProfile(!showMoreProfile)
                                            setShowMoreFeedback(false)
                                        }}>Show {showMoreProfile ? "less" : "more"} <FaAngleDown
                                            className="text-primary"/></small>
                                    </div>
                                    {showMoreProfile &&
                                        <>
                                            <div className="d-flex gap-3 mt-4 align-items-start">
                                                <small className="text-black fw-bolder">Experience:</small>
                                                <div className='d-flex mt-1 flex-column'>

                                                    {applicationSummary?.user?.cvBuilders[0]?.workExperience?.length > 0 ?
                                                    <>
                                                        {applicationSummary?.user?.cvBuilders[0]?.workExperience?.map((item, index) => {
                                                            return (
                                                                <small key={index} className="small-text-gray"
                                                                       style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                    {item?.jobTitle} at {item?.companyName} - <i>{item?.startDate} to {item?.endDate || "Present"}</i>
                                                                </small>
                                                            )
                                                        })}
                                                    </> : <small className="small-text-gray"
                                                                 style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Nil
                                                        </small>
                                                    }

                                                    {applicationSummary?.user?.cvBuilders[0]?.workExperience?.length > 3 && <small
                                                        style={{fontSize: '12px', marginTop: "-2px"}}
                                                        className="cursor-pointer small-text-gray
                                                        text-decoration-underline text-italic">Show more</small>}

                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-4 align-items-start">
                                                <small className="text-black fw-bolder">Education:</small>
                                                <div className='d-flex mt-1 flex-column'>

                                                    {applicationSummary?.user?.cvBuilders[0]?.educations?.length > 0 ?
                                                        <>
                                                            {applicationSummary?.user?.cvBuilders[0]?.educations?.map((item, index) => {
                                                                return (
                                                                    <small key={index} className="small-text-gray"
                                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                        {item?.schoolName} ({item?.degree}, {item?.fieldOfStudy}) - <i>{item?.startDate} to {item?.endDate || "Present"}</i>
                                                                    </small>
                                                                )
                                                            })}
                                                        </> : <small className="small-text-gray"
                                                                     style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Nil
                                                        </small>
                                                    }

                                                    {applicationSummary?.user?.cvBuilders[0]?.educations?.length > 3 && <small
                                                        style={{fontSize: '12px', marginTop: "-2px"}}
                                                        className="cursor-pointer small-text-gray
                                                        text-decoration-underline text-italic">Show more</small>}

                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-4 align-items-start">
                                                <small className="text-black fw-bolder">Skill Tests:</small>
                                                <div className='d-flex mt-1 flex-column'>
                                                    <small className="small-text-gray"
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Personality Assessment - <b
                                                        className='fw-bolder text-black'>{applicationSummary?.user?.personalityTestResults[0]?.personalityCategory}</b>
                                                    </small>
                                                    <small className="small-text-gray"
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Culture Fitness Assessment - <b
                                                        className='fw-bolder text-black'>{applicationSummary?.user?.cultureFitResults[0]?.cultureCategory}</b>
                                                    </small>
                                                    <small className="small-text-gray "
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Soft Skill Assessment - <b
                                                        className='fw-bolder text-black'>{applicationSummary?.user?.skills[0]?.softSkillsCategory}</b>

                                                    </small>

                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="bg-grey-100 p-2 px-3 py-3">
                                    <div className="d-flex">
                                        <small className="text-primary w-50">Pre-Qualification Questions Feedback</small>
                                        <small className="text-primary fw-bolder cursor-pointer" onClick={() => {
                                            setShowMoreFeedback(!showMoreFeedback)
                                            setShowMoreProfile(false)
                                        }}>Show {showMoreFeedback ? "less" : "more"} <FaAngleDown
                                            className="text-primary"/></small>
                                    </div>
                                    {showMoreFeedback &&
                                        <div>
                                            <br/>
                                            <small className="text-black">Must have qualifications <b>3 out 3
                                                met</b></small>
                                            <div
                                                className="d-flex flex-wrap justify-content-between gap-3 mt-2 align-items-start">

                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <br/>
                                            <small className="text-black">Preferred qualifications <b>1 out 2
                                                met</b></small>
                                            <div
                                                className="d-flex flex-wrap justify-content-between gap-3 mt-2 align-items-start">

                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                                <div style={{maxWidth: "400px"}} className="d-flex">
                                                    <div
                                                        className='d-flex flex-grow-1 mt-1 flex-column'>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            How many years of work experience do you have as a
                                                            Frontend Developer?
                                                        </small>
                                                        <small className="small-text-gray"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Required: 4 years
                                                        </small>
                                                        <small className="text-black fw-bolder"
                                                               style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Answer: 6 Years
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    );
}

export default SeeJobApplicants;
