import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichTextForEmail = ({ editorState, setEditorState }) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ]
  const modules = {
    toolbar: toolbarOptions,
  }
  return (
    <ReactQuill
      onChange={(content) => setEditorState(content)}
      theme="snow"
      modules={modules}
      value={editorState}
    />
  )
}

export default RichTextForEmail
