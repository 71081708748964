import React from 'react'

const EmptyChat = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center text-muted"
      style={{ height: '71vh', overflowY: 'auto', paddingBottom: '2rem' }}
    >
      Select a user to chat with...
    </div>
  )
}

export default EmptyChat
