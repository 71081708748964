import React, {Suspense} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import PageLayout from '../../layout/general/PageLayout'
import EmployerHire from '../../assets/images/employer-hire.svg'
import EntryFormLayoutWithText from "../../layout/general/EntryFormLayoutWithText";
import ValidateTin from "../../employer/pages/ValidateTin";
import NotFound from "../../user/pages/NotFound";
import PrivateUserRoutes from "./PrivateUserRoutes";
import PrivateEmployerRoutes from "../employer/PrivateEmployerRoutes";
import PrivateAdminRoutes from "../admin/PrivateAdminRoutes";
import OtherRoutes from "../OtherRoutes";
import GuestRoutes from "../GuestRoutes";
import AccessDenied from "../../components/AccessDenied";
import EmployerDashboard from "../../employer/pages/dashboard/EmployerDashboard";
import PrivateTrainerRoutes from "../employer/PrivateTrainerRoutes";
import PrivateRouteTrainer from "./PrivateRouteTrainer";
import PrivateAdmin from "./PrivateAdminRoute";
import PrivateUser from "./PrivateUserRoute";
import NewEmployerDashboard from "../../employer/pages/dashboard/NewEmployerDashboard";
// import MessageDashboard from 'src/components/message/pages/dashboard/messageDashboard'

const DashboardLayoutWithContext = React.lazy(() => import('../../layout/users/UserDashboardLayout'))
const EmployerDashboardWithContext = React.lazy(() => import('../../layout/employers/EmployerDashboardLayout'),)
const AdminDashboardWithContext = React.lazy(() => import('../../layout/admin/AdminDashboardLayout'))
const PrivateRoute = React.lazy(() => import('./PrivateRoute'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse font-color"></div>
  </div>
)

const AppRouter = () => {
    return (<Router>
        <Suspense fallback={loading}>
            <Routes>
                {/*Public Routes*/}
                <Route path="*" element={<NotFound/>}/>
                <Route element={<PageLayout/>}>
                    {
                        GuestRoutes.map((route, idx) => {
                            return (route.element && (<Route
                                key={idx + 1}
                                path={route.path}
                                exact={route.exact}
                                element={<route.element/>}
                            />))
                        })}

                    <Route exact path="/employer/signup"
                           element={<EntryFormLayoutWithText image={EmployerHire} form={<ValidateTin/>}/>}
                    />
                </Route>
                <Route>
                    {OtherRoutes.map((route, idx) => {
                        return (route.element && (<Route
                            key={idx}
                            path={route.path}
                            element={<route.element image={route.image} form={<route.form/>}/>}
                        />))
                    })}
                </Route>
                {/*Private Routes*/}
                <Route element={<PrivateUser/>}>
                    <Route element={<DashboardLayoutWithContext/>}>
                        {
                            PrivateUserRoutes.map((route, idx) => {
                                return (route.element && (<Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    element={<route.element/>}
                                />))
                            })}
                    </Route>
                </Route>
                <Route element={<EmployerDashboardWithContext/>}>
                    <Route exact path="/access-denied" element={<AccessDenied/>}/>
                    {/*<Route exact path="/employer/dashboard" element={<EmployerDashboard/>}/>*/}
                    <Route exact path="/employer/dashboard" element={<NewEmployerDashboard/>}/>
                    {
                        PrivateEmployerRoutes.map((route, idx) => {
                            return (route.element && (
                                <Route element={<PrivateRoute allowedPermissions={route?.allowedPermissions}/>}
                                       key={idx}>
                                    <Route
                                        path={route.path}
                                        exact={route.exact}
                                        element={<route.element/>}
                                    />
                                </Route>))
                        })
                    }
                </Route>
                <Route element={<EmployerDashboardWithContext/>}>
                    <Route exact path="/access-denied" element={<AccessDenied/>}/>
                    <Route exact path="/trainer/dashboard" element={<EmployerDashboard/>}/>
                    {
                        PrivateTrainerRoutes.map((route, idx) => {
                            return (route.element && (
                                <Route
                                    element={<PrivateRouteTrainer allowedPermissions={route?.allowedPermissions}/>}
                                    key={idx}>
                                    <Route
                                        path={route.path}
                                        exact={route.exact}
                                        element={<route.element/>}
                                    />
                                </Route>))
                        })
                    }
                </Route>
                <Route path="/admin" element={<PrivateAdmin/>}>
                    <Route element={<AdminDashboardWithContext/>}>
                        {
                            PrivateAdminRoutes.map((route, idx) => {
                                return (route.element && (<Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    element={<route.element/>
                                    }
                                />))
                            })
                        }
                    </Route>
                </Route>

            </Routes>
        </Suspense>
    </Router>)

}

export default AppRouter
