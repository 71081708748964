import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  deleteUser,
  localStorageHelper,
  retrieveLoggedInUser,
  storeLoggedInUser,
} from '../../localStorage'
import { CHAT_USER } from 'src/constant/constants'

export const AppContext = React.createContext(null)
const getChatUser = localStorageHelper.get(CHAT_USER)

const UserAppProvider = ({ children }) => {
  const [toast, addToast] = useState(0)
  const [user, setUser] = useState(retrieveLoggedInUser())
  const [chatUser, setChatUser] = useState(getChatUser)
  const userId = user?.data?.id
  const userRole = user?.data?.role
  const stakeholderRoles = user?.data?.roles
  const permissions = user?.permissions || []
  const stakeholderUUID = user?.data?.stakeholderUuid

  const loadUserFromStorage = useCallback(() => {
    setUser(retrieveLoggedInUser())
  }, [])

  const storeChatUser = (data) => {
    localStorageHelper.set(CHAT_USER, data)
    setChatUser(data)
  }

  const storeUser = useCallback(
    (user) => {
      storeLoggedInUser(user)
      loadUserFromStorage()
    },
    [loadUserFromStorage],
  )

  const onLogout = useCallback(() => {
    deleteUser()
    localStorageHelper.delete(CHAT_USER)
    setChatUser(null)
    loadUserFromStorage()
  }, [loadUserFromStorage])

  const setRegistered = useCallback(() => {
    const newState = {
      ...user,
      data: {
        ...user?.data,
        registrationCompleted: true,
      },
    }
    storeUser(newState)
  }, [user, storeUser])

  const header = {
    headers: {
      authorization: `Bearer ${user?.accessToken}`,
    },
  }

  const value = {
    toast,
    addToast,
    loadUserFromStorage,
    chatUser,
    user,
    storeChatUser,
    storeUser,
    setRegistered,
    onLogout,
    header,
    userId,
    userRole,
    stakeholderRoles,
    permissions,
    stakeholderUUID,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)

UserAppProvider.propTypes = {
  children: PropTypes.element,
}

export default UserAppProvider
