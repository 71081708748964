import React, {useEffect, useState} from 'react'

import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CLoadingButton,
    CRow,
} from '@coreui/react-pro'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import avatar8 from '../../../assets/brand/useravater.png';
import {fail_notify, success_notify} from '../../../constant'
import 'react-datepicker/dist/react-datepicker.css'
import InfoTitle from "../../User/cv-builder/InfoTitle";
import careerObjective from "../../../assets/icons/careerObjective.svg";
import {useGetEmployerIndustryTypes, useUpdateEmployerQuery} from "../../../hooks/user/userApiHooks";
import {employerProfileUpdateSchema} from "../../../form-schema/stake-holder-schema/employerProfileUpdateSchema";
import TextEditor from "../../TextEditor";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {nigeriaStatesAndLGA} from "../../../constant/statesAndLGA";
import {BiCloudUpload} from "react-icons/bi";

function UpdateProfile({employerData}) {
    const navigate = useNavigate()
    const {user, storeUser} = useAppContext()
    const {data: industryTypes} = useGetEmployerIndustryTypes()
    const {isLoading: isUpdateLoading, data, mutate, isSuccess, isError, error} = useUpdateEmployerQuery()
    const [aboutCompany, setAboutCompany] = useState('')
    const [logoPreview, setLogoPreview] = useState(null);
    const [logoError, setLogoError] = useState(null);
    const [lgas, setLgas] = useState([]);

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            let updatedObject = {...user}
            updatedObject.data.logoUrl = logoPreview
            storeUser(updatedObject)
            navigate(`/employer/profile`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
        setAboutCompany(employerData?.data?.data?.aboutCompany)
    }, [data, isError, error, isSuccess])

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        watch,
        getValues
    } = useForm({
        resolver: yupResolver(employerProfileUpdateSchema),
        defaultValues: {
            ...employerData?.data?.data,
            phoneNumber: employerData?.data?.data?.phoneNumber?.replace('+234', '0'),
            contactPersonPhoneNumber: employerData?.data?.data?.contactPersonPhoneNumber?.replace('+234', '0'),
        },
    })

    const logoChange = (e) => {
        if (e?.target?.files) {
            if (e.target.files[0].size > 3101400) {
                setLogoError("Maximum image size is 3MB")
                return
            }

            setLogoPreview(URL?.createObjectURL(e.target.files[0]))
            setLogoError(null)
            setValue("logo", e.target.files[0]);
        }
    }

    useEffect(() => {
        const stateLGAS = nigeriaStatesAndLGA.filter((state) => state?.state === getValues('state'))[0]?.lgas
        setValue('localGovernment', employerData?.data?.data.localGovernment)
        setLgas(stateLGAS)
    }, [watch('state')])

    useEffect(() => {
        setValue('aboutCompany', aboutCompany)

    }, [aboutCompany])

    const handleUpdate = (data) => {
        data.aboutCompany = aboutCompany
        mutate(data)
    }

    //console.log(lgas)
    return (
        <>
            <CForm noValidate className="needs-validation w-100 update-profile-form"
                   onSubmit={handleSubmit(handleUpdate)}>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Phone Number</CFormLabel>
                        <CFormInput
                            type="text"
                            id="phoneNumber"
                            placeholder="Enter Your Phone Number"
                            {...register('phoneNumber')}
                            invalid={!!errors.phoneNumber}
                            className="form-control mb-2"
                        />
                        <CFormFeedback invalid={!!errors.phoneNumber}>
                            {errors.phoneNumber?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Industry Type</CFormLabel>
                        <CFormSelect
                            id="industryType"
                            className="form-control mb-2"
                            {...register('industryType')}
                            invalid={!!errors.industryType}
                        >
                            <option value="">--Select--</option>
                            {
                                industryTypes?.data?.data?.map(({id, name}) => (
                                    <option
                                        value={name}
                                        key={id}
                                    >
                                        {name}
                                    </option>
                                ))
                            }
                            <option value="Other">Other</option>
                        </CFormSelect>
                        <CFormFeedback invalid={!!errors.industryType}>
                            {errors.industryType?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Date Established</CFormLabel>
                        <input
                            id="dateEstablished"
                            className={`form-control mb-2 ${!!errors.dateEstablished ? 'is-invalid' : ''}`}
                            {...register('dateEstablished')}
                            type="date"
                        />
                        <CFormFeedback
                            invalid={!!errors.dateEstablished}>{errors.dateEstablished?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Company size</CFormLabel>
                        <CFormInput
                            type="number"
                            placeholder="Enter Company Size"
                            {...register('companySize')}
                            invalid={!!errors.companySize}
                            className="form-control mb-2"
                        />
                        <CFormFeedback invalid={!!errors.companySize}>
                            {errors.companySize?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>

                <CRow>

                    <CCol lg={6} className="my-1">
                        <CFormLabel className="m-1 label">About Company</CFormLabel>
                        <TextEditor setEditorState={setAboutCompany} editorState={aboutCompany}/>
                        <CFormFeedback invalid={!!errors.aboutCompany}>
                            {errors.aboutCompany?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol lg={6} className="my-1">
                        <CFormLabel className="m-1 label">Logo</CFormLabel>
                        <div className="d-flex flex-wrap">

                            <CCol md={6}>
                                <img src={logoPreview || employerData?.data?.data?.logoUrl} alt="Logo" className="w-50"
                                     onError={(e) => {
                                         e.target.src = avatar8
                                     }}/>
                            </CCol>
                            <CButton className="text-white m-1" style={{minHeight: "40px", minWidth: "200px"}}>
                                <label className="cursor-pointer" htmlFor="logo">
                                    <BiCloudUpload fill={"currentColor"} size={25}/>
                                    Upload / Update Logo
                                </label>
                            </CButton>
                            <CFormInput
                                type="file"
                                id="logo"
                                hidden={true}
                                accept=".png, .jpeg, .png"
                                invalid={!!logoError}
                                className="form-control mb-2"
                                onChange={logoChange}
                            />
                        </div>
                        <CFormFeedback invalid={!!logoError}>
                            {logoError}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <br/>
                <InfoTitle title={"LOCATION"} icon={careerObjective}/>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">State / Province</CFormLabel>
                        <CFormSelect
                            {...register('state')}
                            invalid={!!errors.state}
                        >
                            <option value="">--Select State--</option>
                            {
                                nigeriaStatesAndLGA.map((state, i) => (
                                    <option key={i} value={state.state}>
                                        {state.state}
                                    </option>
                                ))
                            }

                        </CFormSelect>
                        <CFormFeedback
                            invalid={!!errors.state}>{errors.state?.message}</CFormFeedback>
                    </CCol>
                    {
                        lgas?.length > 0 &&

                        <CCol md={6} className="my-1">
                            <CFormLabel className="m-1 label">Local Government</CFormLabel>
                            <CFormSelect
                                {...register('localGovernment')}
                                invalid={!!errors.localGovernment}
                            >
                                <option value="">--Select LGA--</option>
                                {
                                    lgas?.map((lga, i) => (
                                        <option key={i} value={lga}>
                                            {lga}
                                        </option>
                                    ))
                                }
                            </CFormSelect>
                            <CFormFeedback
                                invalid={!!errors.localGovernment}>{errors.localGovernment?.message}</CFormFeedback>
                        </CCol>
                    }

                </CRow>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Postal / Zip Code</CFormLabel>
                        <CFormInput
                            type="text"
                            id="zipCode"
                            placeholder="Enter zipCode"
                            {...register('zipCode')}
                            invalid={!!errors.zipCode}
                            className="form-control mb-2"
                        />
                        <CFormFeedback
                            invalid={!!errors.zipCode}>{errors.zipCode?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Address</CFormLabel>
                        <CFormTextarea
                            id="address"
                            placeholder="Enter Your Address"
                            {...register('address')}
                            invalid={!!errors.address}
                            className="form-control mb-2"
                        />
                        <CFormFeedback invalid={!!errors.address}>
                            {errors.address?.message}
                        </CFormFeedback>
                    </CCol>

                </CRow>
                <br/>
                <InfoTitle title={"CONTACT PERSON   "} icon={careerObjective}/>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">First Name</CFormLabel>
                        <CFormInput
                            type="text"
                            id="contactPersonFirstName"
                            placeholder="Enter Contact Person First Name"
                            {...register('contactPersonFirstName')}
                            invalid={!!errors.contactPersonFirstName}
                            className="form-control mb-2"
                        />
                        <CFormFeedback
                            invalid={!!errors.contactPersonFirstName}>
                            {errors.contactPersonFirstName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Last Name</CFormLabel>
                        <CFormInput
                            type="text"
                            id="contactPersonLastName"
                            placeholder="Enter Contact Person Last Name"
                            {...register('contactPersonLastName')}
                            invalid={!!errors.contactPersonLastName}
                            className="form-control mb-2"
                        />
                        <CFormFeedback invalid={!!errors.contactPersonLastName}>
                            {errors.contactPersonLastName?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Email</CFormLabel>
                        <CFormInput
                            type="email"
                            id="contactPersonEmail"
                            placeholder="Enter Contact Person Email"
                            {...register('contactPersonEmail')}
                            invalid={!!errors.contactPersonEmail}
                            className="form-control mb-2"
                        />
                        <CFormFeedback
                            invalid={!!errors.contactPersonEmail}>{errors.contactPersonEmail?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={6} className="my-1">
                        <CFormLabel className="m-1 label">Phone Number</CFormLabel>
                        <CFormInput
                            type="text"
                            id="city"
                            placeholder="Enter Contact Person Phone Number"
                            {...register('contactPersonPhoneNumber')}
                            invalid={!!errors.contactPersonPhoneNumber}
                            className="form-control mb-2"
                        />
                        <CFormFeedback invalid={!!errors.contactPersonPhoneNumber}>
                            {errors.contactPersonPhoneNumber?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={12} className="text-center my-1">
                        <CLoadingButton
                            type="submit"
                            className="text-white px-4 w-50 py-2 my-4"
                            loading={isUpdateLoading}
                            disabled={isUpdateLoading}
                        >
                            Update Profile
                        </CLoadingButton>
                    </CCol>
                </CRow>
            </CForm>
        </>
    )
}

export default UpdateProfile
