import React, {useEffect, useState} from "react";
import {fail_notify, success_notify} from "../../constant";
import {AiOutlineDelete} from "react-icons/ai";
import {CLoadingButton} from "@coreui/react-pro";
import ConfirmModal from "../modals/ConfirmModal";
import {useDeleteCV, useFetchUserCVs} from "../../hooks/user/cvBuilderHooks";

const UploadResume = () => {
    const {data: dataCV} = useFetchUserCVs()
    const [clickedDeleteCV, setClickedDeleteCV] = useState(null)
    const {isLoading, isSuccess, data, isError, error, mutate} = useDeleteCV(clickedDeleteCV)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (isSuccess) {

            success_notify('CV successfully deleted')

        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    return (
        <div className="table-responsive border border-2 rounded-3 border-dark mt-2">
            <table className="table">
                <thead>
                <tr style={{backgroundColor: "transparent", color: "gray"}}>
                    <th scope="col" style={{width: "300px"}}>CV Name</th>
                    <th scope="col" style={{width: "20px"}}>Action</th>
                </tr>
                </thead>
                <tbody>
                {dataCV?.data?.data?.map((item, index) => {
                    return <tr key={index}>
                        <td>
                            {item.originalName}
                        </td>
                        <td>
                      <span className="d-flex">
                          <CLoadingButton
                              className="cursor-pointer py-0 px-2 rounded border text-white bg-danger m-1"
                              loading={isLoading && clickedDeleteCV === item.id}>{<AiOutlineDelete
                              color={"white"} onClick={() => {
                              setClickedDeleteCV(item.id)
                              setVisible(true)
                          }}/>} </CLoadingButton>
                      </span>
                        </td>

                        <ConfirmModal visible={visible} setVisible={setVisible} title="DELETE CV" data={item.id}
                                      message="Are you sure you want to delete this CV" handler={mutate}/>
                    </tr>
                })}
                {dataCV?.data?.data?.length < 1 &&
                    <tr>
                        <td><h6 className="text-black-50 border-0">No available CV</h6></td>
                        <td></td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}

export default UploadResume
