import React, {useEffect, useState} from 'react'
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";

const OTNIDashboard = () => {
    const [isRedirect, setIsRedirect] = useState(false)
    const [visible, setVisible] = useState(true)
    const navigate = useNavigate()
    const url = "https://lookerstudio.google.com/u/0/reporting/d34c6abf-b4cc-49bf-b94b-6329e56696b1/page/IQf1D";

    useEffect(() => {
        if (isRedirect) {
            window.open(url)
            setVisible(false)
        }
    }, [isRedirect]);

    return (
        <>
            {!visible && <div className="d-flex flex-row justify-content-center align-items-center mt-5">
                <h3 className="mt-5">You have been redirected.</h3>
            </div>}
            {visible && <CModal visible={visible}>
                <CModalHeader>OTNI Dashboard Redirect</CModalHeader>
                <CModalBody className="py-5">
                    You are about to leave WorkNation to OTNI Dashboard.
                </CModalBody>
                <CModalFooter>
                    <CButton className="btn btn-primary text-white" onClick={() => setIsRedirect(true)}>Proceed</CButton>
                    <CButton className="btn btn-danger text-white" onClick={() => {
                        navigate(`/admin/dashboard`, {replace: true})
                    }}>Back</CButton>
                </CModalFooter>
            </CModal>}
        </>
    );
}

export default OTNIDashboard