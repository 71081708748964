/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { RiCloseLine, RiDeleteBinLine, RiReplyLine } from 'react-icons/ri'
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react-pro'
import { useAppContext } from 'src/context/user/UserAppProvider'
import { fail_notify, success_notify } from 'src/constant'
import appApiCalls from 'src/services/api'
import EmptyChat from './EmptyChat'
import ShowLoading from 'src/components/ShowLoading'
import { formattedDate } from 'src/components/Utility'
import RichTextForEmail from 'src/components/User/RichTextForEmail'
import 'react-quill/dist/quill.snow.css'
import DeleteEmailModal from 'src/components/modals/DeleteEmailModal'

const EmailLikeChat = ({ selectedUser, composeEmail, setComposeEmail }) => {
  const { user } = useAppContext()
  const [emailContent, setEmailContent] = useState('')
  const [emailList, setEmailList] = useState([])
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [replyMsgDetails, setReplyMsgDetails] = useState(null)
  const [visible, setVisible] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')

  const getAllUserEmails = useCallback(async () => {
    try {
      setLoadingEmail(true)
      const response = await appApiCalls.getAllUsersEmails(user?.data?.role)
      setEmailList(response?.data?.data?.items)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingEmail(false)
      setReplyMsgDetails(null)
    }
  }, [selectedUser?.email])

  const handleReplyAMessage = async () => {
    setLoadingEmail(true)
    const data = {
      ...replyMsgDetails,
      senderId: user?.data?.email,
      emailId: replyMsgDetails?.emailId,
      body: emailContent,
    }
    try {
      await appApiCalls.replyToAnEmail(data)
      success_notify('mail replied successfully')
      getAllUserEmails()
    } catch (error) {
      console.log({ error })
    } finally {
      setEmailContent('')
      setComposeEmail(false)
      setReplyMsgDetails(null)
      setLoadingEmail(false)
    }
  }

  const handleSendMail = async () => {
    if (!emailContent) {
      return fail_notify('You cant submit an empty email')
    }
    if (replyMsgDetails) {
      return handleReplyAMessage()
    }
    const data = {
      senderId: user?.data?.email,
      recipientId: selectedUser?.email,
      subject: 'Test',
      body: emailContent,
    }
    setLoadingEmail(true)
    try {
      await appApiCalls.sendEmail(data)
      getAllUserEmails()
      success_notify('mail sent successfully')
    } catch (error) {
      console.log(error)
    } finally {
      setEmailContent('')
      setComposeEmail(false)
      setLoadingEmail(false)
    }
  }

  const handleDeleteEmail = async (id) => {
    try {
      await appApiCalls.deleteAnEmail({ id })
      success_notify('Deleted successfully')
      getAllUserEmails()
    } catch (error) {
      fail_notify('Something went wrong')
      console.log({ error })
    }
  }

  useEffect(() => {
    if (selectedUser) {
      getAllUserEmails()
    }
  }, [selectedUser?.email])

  const isApplicant = user?.data?.role === 'User'

  const filterEmailForUser = emailList
    ?.filter((mail) =>
      isApplicant
        ? selectedUser.email === mail.senderEmail
        : selectedUser.email === mail.recipientEmail,
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  return (
    <>
      {selectedUser ? (
        <div
          className="bg-white overflow-y-auto"
          style={{ height: '71vh', overflowY: 'auto', paddingBottom: '2.5rem' }}
        >
          {loadingEmail && <ShowLoading />}
          {!loadingEmail && !filterEmailForUser?.length && (
            <p style={{ paddingBlock: '2rem' }} className="text-muted small">
              Click on Compose to send an email to this user...
            </p>
          )}

          {!loadingEmail &&
            filterEmailForUser?.map((mail, index) => (
              <CAccordion className="mt-3" key={selectedUser?.email + index}>
                <CAccordionItem itemKey={1} className="py-0">
                  <CAccordionHeader className="ps-0 py-0">
                    <div
                      className="py-4 d-flex justify-content-between align-items-center"
                      style={{ width: '100%' }}
                    >
                      <div className="">
                        <div className="d-flex align-items-center ">
                          <span className="fw-light smallText text-grey-500 pr-2 d-inline-block">
                            From:{' '}
                          </span>
                          <p
                            className="textPrimary smallText fw-bold ps-2"
                            style={{ fontSize: '16px' }}
                          >
                            {isApplicant ? selectedUser?.email : user?.data?.email}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <span className="fw-light smallText text-grey-500 d-inline-block">
                            To:{' '}
                          </span>{' '}
                          <span className="fw-light smallText text-grey-500 d-inline-block">
                            {isApplicant ? 'Me' : selectedUser?.email || selectedUser?.fullname}
                          </span>
                        </div>
                      </div>

                      {/* <div className="d-flex align-items-start justify-content-between gap-2 pe-2">
               
                <p className="fw-light d-flex gap-1 align-items-center smallText orangeColor">
                  <RiDeleteBinLine />
                  Delete
                </p>
                <p className="fw-light d-flex gap-1 align-items-center smallText text-warning ">
                  <ImDownload2 />
                  Archive
                </p>
                <button onClick={() => setComposeEmail(true)} className="border-0 bg-transparent">
                  <p className="fw-light d-flex gap-1 align-items-center smallText ">
                    <RiReplyLine />
                    Reply
                  </p>
                </button>
              </div> */}
                      <div className="d-flex gap-2 align-items-center">
                        <p
                          className="text-muted fst-normal pe-2"
                          style={{ textWrap: 'wrap', fontSize: '10px' }}
                        >
                          {formattedDate(mail?.createdAt || '')?.replace('about ', '')}
                        </p>
                      </div>
                    </div>
                  </CAccordionHeader>
                  <CAccordionBody>
                    <div className="d-flex gap-2 align-items-center justify-content-end mb-2">
                      {/* {user?.data?.email !== mail?.senderEmail ? ( */}
                      <p
                        onClick={() => {
                          setReplyMsgDetails({
                            replierId: selectedUser?.email,
                            emailId: mail?.id,
                            senderId: user?.email,
                          })
                          setComposeEmail(true)
                        }}
                        className="fw-light ps-2 d-flex gap-1 align-items-center smallText muted  cursor-pointer"
                      >
                        <RiReplyLine />
                        Reply
                      </p>
                      {/* ) : null} */}
                      <p
                        onClick={() => {
                          setIdToDelete(mail?.id)
                          setVisible(true)
                        }}
                        className="fw-light d-flex gap-1 align-items-center smallText orangeColor  cursor-pointer"
                      >
                        <RiDeleteBinLine />
                        Delete
                      </p>
                    </div>
                    <div className="smallText greyColor">
                      <div dangerouslySetInnerHTML={{ __html: mail?.content }} />

                      {mail?.replies.length ? (
                        <div className="border-top border-1 py-1 mt-2">
                          <p className="fw-light ps-2 d-flex gap-1 align-items-center smallText text-muted cursor-pointer">
                            Repl{mail?.replies.length > 1 ? 'ies' : 'y'}...
                          </p>
                        </div>
                      ) : null}
                      {mail?.replies.length
                        ? mail?.replies.map((reply, index) => (
                            <div
                              key={selectedUser?.email + '-' + index}
                              className="py-1 ps-4 mb-2 border-bottom border-1"
                            >
                              <span
                                className="fw-light text-muted d-inline-block"
                                style={{ fontSize: '10px' }}
                              >
                                {reply.userId === mail?.userId ? 'Me' : selectedUser?.email} :
                              </span>{' '}
                              <div dangerouslySetInnerHTML={{ __html: reply?.content }} />
                            </div>
                          ))
                        : null}
                    </div>
                    {/* <div className="d-flex flex-column align-items-start py-4 justify-content-center">
              <p className="fw-bolder smallText greyColor pb-2 "> Attachments</p>
              <Attachment />
            </div> */}
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            ))}

          <br />
          {composeEmail && (
            <div className="bg-white px-2 mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <p className="smallText fw-bold">Reply</p>

                <button onClick={() => setComposeEmail(false)} className="border-0 bg-transparent">
                  <RiCloseLine />
                </button>
              </div>
              <div className="d-flex mt-2 flex-column gap-1" style={{ minHeight: '' }}>
                <p className="d-flex align-items-center gap-2 border-bottom smallText py-1">
                  from:
                  <input
                    className="bg-transparent hideInputActiveState text-black rounded-0 border-white border-0 shadow-none"
                    placeholder="Me(recruitement@dangotegroup.com)"
                    style={{ width: '100%' }}
                    disabled
                    defaultValue={user?.data?.email}
                  />
                </p>
                <div className="d-flex justify-content-between smallText gap-3 align-items-center border-bottom">
                  <span>To:</span>
                  <div className="w-100">
                    <input
                      className="font-weight-light bg-transparent w-100 hideInputActiveState text-black rounded-0 border-white border-0 shadow-none"
                      placeholder="Namadi Sambo"
                      defaultValue={selectedUser?.email}
                      disabled
                      type="email"
                    />
                  </div>
                  {/* <span className="d-flex smallText fw-semibold">CC</span> */}
                </div>
              </div>

              <RichTextForEmail editorState={emailContent} setEditorState={setEmailContent} />

              <div className="mt-2 py-2 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3">
                  {/* <button className="fw-bold smallText border-0 bg-white darkGreenText">
                <BiLink />
                Attach
              </button>
              <button className="fw-bold smallText border-0 bg-white darkGreenText">
                <FaClock size="12" /> Schedule
              </button> */}
                </div>
                <div>
                  <button
                    className="border-0 text-white px-2 py-1 rounded-1 smallText"
                    style={{ backgroundColor: '#1FAB89' }}
                    onClick={() => handleSendMail()}
                    disabled={loadingEmail}
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyChat />
      )}
      <DeleteEmailModal
        visible={visible}
        setVisible={setVisible}
        handler={handleDeleteEmail}
        emailId={idToDelete}
        setIdToDelete={setIdToDelete}
      />
    </>
  )
}

export default EmailLikeChat
