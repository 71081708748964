import React, {useState} from 'react'
import {FaUsers} from "react-icons/fa";
import {BsThreeDots} from "react-icons/bs";
import {FaAngleRight} from "react-icons/fa6";
import {dateFormat} from "../../constant";

function MyProject({data, setMyProjectDetail, setMyProject}) {
    const [dropDown, setDropDown] = useState(false)
    //let updateStatus = useJobStatusUpdate(filter, jobId)
    const handleStatus = (status) => {
        setDropDown(false)
    }
    return (
        <>

            <div className="border border-2 rounded rounded-1 p-2 bg-white" style={{maxWidth: "300px"}}>
                <div className="d-flex justify-content-between align-items-start px-2 ">
                    <div className='d-flex flex-column'>
                        <small className="text-black"><strong>{data?.pipeline_job_title}</strong></small>
                        <small className="text-black-50" style={{fontSize: '13px', marginTop: "-5px"}}>Viewed 2 days
                            ago</small>


                    </div>
                    <div className="">
                        <BsThreeDots size={24} color="black"/>
                    </div>
                </div>

                <div className="">
                    <hr/>
                </div>
                <div className="d-flex justify-content-between align-items-start ">
                    <div className='d-flex flex-column text-green'>
                        <small className="text-black"><strong>Pipeline</strong></small>
                        <span>
                        <FaUsers size={20} color="green"/>&nbsp;
                            <small className="text-green"
                                   style={{fontSize: '13px', marginTop: "-3px", color:"green"}}>{data?.totaluser} Candidates</small>
                        </span>

                    </div>
                    <div className="">
                        <small className="text-black-50"
                               style={{fontSize: '13px', marginTop: "-3px"}}>Created {dateFormat(data?.pipeline_created_at)}</small>
                    </div>
                </div>
                <div onClick={()=>{
                    setMyProject(data)
                    setMyProjectDetail(true)
                }} className="text-primary cursor-pointer d-flex justify-content-end">
                    <small className=""><strong>View Project</strong></small>&nbsp;<FaAngleRight size={20} />
                </div>
            </div>
        </>
    );
}

export default MyProject;
