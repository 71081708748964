import React, { useEffect } from 'react'
import { CCol, CForm, CFormFeedback, CFormInput, CLoadingButton } from '@coreui/react-pro'
import { Link, useNavigate } from 'react-router-dom'
import { RiGovernmentLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from '../../form-schema/stake-holder-schema/loginSchema'
import { encryptText } from '../../components/Utility'
import { fail_notify, success_notify } from '../../constant'
import { useSignInQuery } from '../../hooks/user/userApiHooks'
import { useAppContext } from '../../context/user/UserAppProvider'
import appApiCalls from 'src/services/api'

const EmployerLogin = () => {
  const navigate = useNavigate()
  const { storeChatUser, storeUser } = useAppContext()
  const { isLoading, isSuccess, data, isError, error, mutate } = useSignInQuery()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(LoginSchema),
  })

  useEffect(() => {
    const authenticateChatUser = async () => {
      try {
        const chatData = await appApiCalls.getActiveChatUser(data?.data?.data?.email)
        // const chatData = await appApiCalls.getActiveChatUser('yakubuh56@gmail.com')
        const [firstName, lastName] = data?.data?.data?.name.split(' ')
        if (!chatData?.data) {
          //   initalize chat user
          const res = await appApiCalls.initializeChatUser({
            email: data?.data?.data?.email,
            firstName,
            lastName,
            role: 'User',
          })
          storeChatUser(res.data)
        } else {
          storeChatUser(chatData.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        navigate(`/employer/dashboard`, {
          replace: true,
        })
      }
    }
    const handleSuccess = async () => {
      const userRole = data?.data?.data.role
      if (userRole === 'Instructor') {
        success_notify('Successfully Logged In')
        storeUser(data?.data)
        navigate(`/trainer/dashboard`, {
          replace: true,
        })
        return
      }

      data.data.LMSID = encryptText(watch('password'), data?.data?.accessToken)
      storeUser(data?.data)
      success_notify('Successfully Logged In')
      const stakeholderRole = data?.data?.data?.roles
      if (
        stakeholderRole?.includes('Employer') ||
        stakeholderRole?.includes('Government') ||
        stakeholderRole?.includes('Academy')
      ) {
        authenticateChatUser()
        return
      }
    }

    if (isSuccess) {
      handleSuccess()
    }

    if (isError) {
      fail_notify(error?.response?.data?.message)
    }
  }, [data, isSuccess, isError, error, navigate, mutate, watch])

  const onSubmit = (data) => {
    mutate(data)
  }
  return (
    <>
      <RiGovernmentLine className="text-primary mt-5" size={42} />
      <h1 className="text-1 mb-4">Log In</h1>
      <CForm noValidate onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <div className="d-flex flex-column gap-3">
          <CCol md={8}>
            <CFormInput
              type="text"
              id="inputEmail4"
              placeholder="Enter Your Email Address"
              {...register('email')}
              invalid={!!errors.email}
            />
            <CFormFeedback invalid={!!errors.email}>{errors.email?.message}</CFormFeedback>
          </CCol>
          <CCol md={8}>
            <CFormInput
              type="password"
              id="inputPassword4"
              {...register('password')}
              placeholder="Enter Your Password"
              invalid={!!errors.password}
            />
            <CFormFeedback invalid={!!errors.password}>{errors.password?.message}</CFormFeedback>
          </CCol>
        </div>
        <CCol md={8}>
          <div className="d-flex justify-content-end m-0">
            <Link to="/forgot-password" className="text-decoration-none m-0 font-color">
              Forgot Password?
            </Link>
          </div>
          <CLoadingButton
            type="submit"
            className="reg-btn px-4 w-100 py-2 mt-5"
            disabled={isLoading}
            loading={isLoading}
          >
            Log in
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EmployerLogin
