import * as Yup from 'yup'

export const UploadBeneficiariesSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('File is required')
        .test(
            'fileFormat',
            'Unsupported file format',
            value => {
                const fileExtension = value?.[0]?.name.split('.').pop();
                return ['csv', 'xlsx'].includes(fileExtension?.toLowerCase())
            }
        )
        .test(
            'fileSize',
            'File size is too large',
            value => value && value?.[0]?.size <= 20000000 // 20MB limit
        ),
});


