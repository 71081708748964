import React, {useEffect} from 'react';
import {
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {UploadBeneficiariesSchema} from "../../form-schema/admin-form-schema/uploadBeneficiariesSchema";
import {useUploadBeneficiaries} from "../../hooks/admin/adminApiHooks";
import {fail_notify, success_notify} from "../../constant";

const UploadBeneficiariesModal = ({visible, setVisible, cohortId}) => {

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(UploadBeneficiariesSchema)  // Use the correct schema
    })

    const {mutate, isError, error, isSuccess, isLoading} = useUploadBeneficiaries()

    useEffect(() => {
        if (isSuccess) {
            success_notify('File Uploaded Successfully')
            reset()
            setVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isError])

    const handleFileUpload = (data) => {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        mutate({formData, cohortId})
    }

    return (
        <CModal
            backdrop="static"
            visible={visible}
            onClose={() => setVisible(false)}
        >
            <CModalHeader onClose={() => setVisible(false)}>
                <CModalTitle>Upload Beneficiaries</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CContainer>
                    <CRow>
                        <CCol md={12}>
                            <CForm onSubmit={handleSubmit(handleFileUpload)}>
                                <div className="my-3">
                                    <CFormLabel>Upload File</CFormLabel>
                                    <CFormInput
                                        className="form-control px-3 py-2"
                                        type="file"
                                        id="file"
                                        {...register('file')}
                                        invalid={!!errors.file}
                                        accept=".csv, .xlsx"
                                    />
                                    <CFormFeedback invalid={!!errors.file}>
                                        {errors.file?.message}
                                    </CFormFeedback>
                                </div>
                                <div className="d-flex flex-row justify-content-end my-5">
                                    <CLoadingButton
                                        type="submit"
                                        className="btn btn-primary px-5 py-2 text-small text-white"
                                        loading={isLoading}
                                    >UPLOAD</CLoadingButton>
                                </div>
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
            </CModalBody>
        </CModal>
    )
}

export default UploadBeneficiariesModal;
