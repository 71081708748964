import React, { useState } from 'react'
import { CCol } from '@coreui/react-pro'
import ChatSectionAppHeader from '../../components/ChatSectionAppHeader'
import { CHAT_TYPE } from 'src/constant/constants'
import ProfileInfoSection from '../../components/ProfileInfoSection'
import EmailLikeChat from '../../components/EmailLikeChat'
import WhatsappLikeChat from '../../components/WhatsappLikeChat'
import { MessageContextProvider } from 'src/context/messaging/MessageProvider'
import ChatContactList from '../../components/ChatContactList'
import { FaAngleRight } from 'react-icons/fa'
import { ChatCount } from '../../components/ChatContactCard'
import { useAppContext } from 'src/context/user/UserAppProvider'

const ChatBoard = () => {
  const [chatType, setChatType] = useState(CHAT_TYPE.chat)
  const [selectedUser, setSelectedUser] = useState(0)
  const [openEmail, setOpenEmail] = useState(1)
  const [composeEmail, setComposeEmail] = useState(false)
  const { user } = useAppContext()

  const activeBtn = (state) => (openEmail === state ? 'text-primary fw-bolder' : 'text-black-50')

  return (
    <MessageContextProvider>
      {/* {chatType === CHAT_TYPE.email && (
        <div className="w-full h-25 bg-white">
          <div className="d-flex flex-row justify-content-between align-items-center p-2">
            <div className="d-flex flex-row align-items-center ">
              <div
                onClick={() => setOpenEmail(1)}
                className={`${activeBtn(
                  1,
                )} cursor-pointer d-flex gap-1 justify-content-center align-items-center`}
              >
                Inbox Messages <ChatCount count={2} />
                <FaAngleRight className={`${activeBtn(1)} cursor-pointer`} size={22} />
                &nbsp;
              </div>
              <div className={`${activeBtn(2)} cursor-pointer`} onClick={() => setOpenEmail(2)}>
                Sent Messages
                <FaAngleRight className={`{activeBtn(2)} cursor-pointer`} size={22} />
                &nbsp;
              </div>
            </div>
            {user?.data?.role !== 'User' && (
              <button
                className="border-0 text-white px-2 py-1 rounded-1 smallText"
                style={{ backgroundColor: '#1FAB89' }}
                onClick={() => setComposeEmail(true)}
              >
                COMPOSE MESSAGE
              </button>
            )}
          </div>
        </div>
      )} */}
      <br />
      <div
        style={{ backgroundColor: '#fbfbfb', height: '85vh' }}
        className="d-flex gap-3 overflow-hidden"
      >
        {/* {chatType === CHAT_TYPE.chat && ( */}
        <CCol xs={3} md={3} className="d-none d-md-block position-relative">
          <ChatContactList
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setComposeEmail={setComposeEmail}
          />{' '}
        </CCol>
        {/* )} */}
        <CCol
          //   xs={chatType === CHAT_TYPE.chat ? 6 : 8}
          xs={6}
          className="px-2"
          style={{ backgroundColor: '#F9FFFD' }}
        >
          <ChatSectionAppHeader
            chatType={chatType}
            setChatType={setChatType}
            selectedUser={selectedUser}
            setComposeEmail={setComposeEmail}
          />
          {chatType === CHAT_TYPE.email && (
            <EmailLikeChat
              selectedUser={selectedUser}
              composeEmail={composeEmail}
              setComposeEmail={setComposeEmail}
            />
          )}
          {chatType === CHAT_TYPE.chat && <WhatsappLikeChat user={selectedUser} />}
        </CCol>
        <CCol className="d-none d-xl-block py-4 px-2 bg-white">
          {selectedUser ? <ProfileInfoSection user={selectedUser || 0} /> : null}
        </CCol>
      </div>
    </MessageContextProvider>
  )
}

export default ChatBoard
