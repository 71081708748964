import React from 'react';
import {CButton, CCol, CContainer} from "@coreui/react-pro";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {useGetKPITrainerCourse} from "../../../hooks/admin/adminApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import {LuSchool} from "react-icons/lu";

function TrainersCourses() {
    const {user} = useAppContext()
    const {data, isLoading} = useGetKPITrainerCourse(user?.data?.id)

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <>
            <div className="ms-4">
                <h5 className="text-[12px] fw-bolder ps-1">Welcome, {user?.data?.firstName} {user?.data?.lastName}</h5>
                <hr/>
            </div>
            <CContainer className="mb-5 d-flex flex-row gap-5 mt-5">
                {
                    data?.data?.data?.items?.map(({courses, cohort, instructor}, index) => {
                        return (
                            <CCol md={4} className="bg-white p-3" key={index + 1}>
                                <div className="shadow-none p-4">
                                    <div>
                                        <div className="d-flex flex-row align-items-center gap-2">
                                            <LuSchool size={48}/>
                                            <div>
                                                <h5>{courses?.title}</h5>
                                                <p>{cohort?.name}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between align-items-center mt-5">
                                            <p><strong>Center:</strong> {instructor?.center}</p>
                                            <CButton className="text-white">Start Course</CButton>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                        )
                    })
                }
            </CContainer>

        </>
    );
}

export default TrainersCourses;