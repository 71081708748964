import React, {useState} from 'react'
import {CImage, CLoadingButton, CRow} from "@coreui/react-pro";
import logo from "../../assets/brand/WN-logo.svg";
import JCardIcon from "../../assets/icons/JCardIcon.png";
import {FaAngleDown} from "react-icons/fa";
import {useGetJobApplicants} from "../../hooks/employer/employerApiHooks";
import {useNavigate} from "react-router-dom";

function JobCard({data, setRightDrawer, setJobApplicants}) {
    const [dropDown, setDropDown] = useState(false)
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [assessmentQuery, setAssessmentQuery] = useState("")
    const [fitLevel, setFitLevel] = useState('');

    const jobApplicantsData = useGetJobApplicants("JobApplicants", data.id, page, 10, fitLevel, assessmentQuery)
    //console.log(jobApplicantsData)
    //let updateStatus = useJobStatusUpdate(filter, jobId)
    const handleStatus = (status) => {
        setDropDown(false)
        //updateStatus?.mutate({"status": status})
    }
    const setDefaultSrc = (e) => {
        e.target.src = logo
    }
    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }
    return (
        <>
            <div className="border border-2 rounded rounded-2 bg-white w-100" style={{maxWidth: "270px"}}>
                <div className="d-flex justify-content-end align-items-center ps-2 ">

                    <div className="position-relative">
                        <CLoadingButton loading={false} onClick={() => {
                            //setJobId(item?.id)
                            setDropDown(!dropDown)
                        }}
                                        style={{borderRadius: "0px 6px 0px 0px"}}
                                        className={`${true ? "bg-blue-500" : "bg-danger"} px-3 btn-sm text-white`}>{true ? "Open" : "Close"}
                            &nbsp; {true && <FaAngleDown/>}
                        </CLoadingButton>
                        {dropDown && <div style={{minWidth: "90px", left: "-0px", zIndex: "1000"}}
                                          className="position-absolute shadow p-1 px-2 shadow-md mt-1 bg-white">
                            <div
                                className="text-blue-500 cursor-pointer"
                                onClick={() => {
                                    handleStatus('open')
                                }}>Open
                            </div>
                            <div
                                className="text-danger cursor-pointer"
                                onClick={() => {
                                    handleStatus('close')
                                }}>Close
                            </div>
                            <div
                                className="text-yellow cursor-pointer"
                                onClick={() => {
                                    handleStatus("archive")
                                }}>Archive
                            </div>
                            <div
                                className="text-black-50 cursor-pointer"
                                onClick={() => {
                                    handleStatus("delete")
                                }}>Delete
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="d-flex flex-column align-items-start ps-2" style={{marginTop: "-10px"}}>

                    <CImage onError={(e) => setDefaultSrc(e)}
                            src={data?.stakeholder?.logoUrl || logo} alt="Logo" style={{
                        width: "70px",
                        objectFit: 'contain',
                    }}/>

                    <div className='d-flex flex-column align-items-start mt-2'>
                        <div style={{width: "200px"}}>

                            <h6 className="text-black text-truncate"><b className="text-truncate">{data?.title}</b></h6>
                        </div>

                        <div className='d-flex align-items-center gap-1' style={{marginTop: "-10px"}}>
                            <small
                                   className=" smaller-text-12">{data?.location} </small>
                            <div className='bg-grey-200 rounded rounded-circle'
                                 style={{width: '8px', height: '8px'}}></div>
                            <small className=" smaller-text-12">{data?.jobType} </small>

                            <div className='bg-grey-200 rounded rounded-circle'
                                 style={{width: '8px', height: '8px'}}></div>
                            <small className=" smaller-text-12">{data?.levelOfExperience}</small>
                        </div>
                    </div>

                </div>

                <div className="d-flex justify-content-between px-2 mt-5">
                    <div className="mt-3">
                        <CImage onError={(e) => setDefaultSrc(e)}
                                src={JCardIcon} alt="Logo" style={{
                            width: "20px",
                            objectFit: 'contain',
                        }}/>
                        <small className=" smaller-text-12 text-black-50"> {data?.applyForJob?.length} Applicants applied </small>
                    </div>
                </div>
                <div className="d-flex align-items-center gap-1 px-2 mb-3">
                    <small className="text-black"><strong>Date Posted: </strong></small>
                    <small className=" smaller-text-12 text-black-50"> {dateFormat(data?.createdAt)} </small>
                </div>
                <div className="d-flex justify-content-between px-2 align-items-center rounded rounded-2 py-3 bg-deem-light">
                    <div
                        style={{width: "100px"}}
                        className="bg-transparent mx-1 border border-2 cursor-pointer border-dark text-black-50 d-flex justify-content-center align-item-center rounded rounded-2"
                        onClick={() => {
                            navigate(`/employer/job-listing/${data.id}`)
                        }}
                    >
                        <small className="py-1"><b>View Job</b></small>
                    </div>
                    <div style={{width: "120px"}}
                         onClick={() => {
                            // console.log(jobApplicantsData, '11222')
                             if(data?.applyForJob?.length <1){
                                 alert("There are no applicants")
                                 return
                             }
                             setJobApplicants(jobApplicantsData)
                             setRightDrawer(true)
                         }}
                         className="bg-primary border mx-1 border-2 cursor-pointer border-primary text-white d-flex justify-content-center align-item-center rounded rounded-2 text-truncate">
                        <small className="py-1 text-truncate"><b className="text-truncate">See Applicants</b></small>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobCard;
