import React from 'react'
import { CButton, CModal, CModalBody, CModalTitle } from '@coreui/react-pro'

const DeleteEmailModal = ({ visible, setVisible, handler, emailId, setIdToDelete }) => {
  return (
    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
      <CModalBody className="text-center py-3">
        <CModalTitle className="text-title mb-5">Delete Email</CModalTitle>
        <p className="pb-3">Are You sure you ant to delete this email</p>
        <div className="d-flex flex-row justify-content-center align-items-center py-3">
          <CButton
            onClick={() => {
              setVisible(false)
              setIdToDelete('')
            }}
            className="text-white btn-warning mx-1"
          >
            No, Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handler(emailId)
              setVisible(false)
            }}
            className="text-white px-5 mx-1"
          >
            Yes
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  )
}

export default DeleteEmailModal
