import React, {useEffect, useState} from 'react'
import {CCol, CForm, CFormFeedback, CFormInput, CFormSelect, CLoadingButton, CRow,} from '@coreui/react-pro'
import {Link, useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {usePanAfricanSignUpQuery} from "../../../hooks/user/userApiHooks";
import {success_notify} from "../../../constant";
import ErrorReports from "../../../components/ErrorReports";
import RegSuccess from './RegSuccess'
import {SignUpPanAfricanSchema} from "../../../form-schema/user-form-schema/signUpPanAfricanSchema";

const SignUpPanAfrican = () => {
    const [showRegSuccess, setShowRegSuccess] = useState(false)

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(SignUpPanAfricanSchema),
    })

    const {
        isLoading,
        isSuccess,
        isError,
        error,
        data,
        mutate
    } = usePanAfricanSignUpQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Account Successfully created')
            setShowRegSuccess(true)
            setTimeout(() => {
                setShowRegSuccess(false)
                navigate(`/login`, {
                    replace: true,
                    state: {
                        email: data?.data?.data?.email,
                    },
                })

            }, 5000)
        }
    }, [isLoading, isSuccess, isError, error, data, mutate])

    const handleSignUp = (data) => {
        mutate(data)
    }

    return (
        <>
            {showRegSuccess && <RegSuccess/>}
            <h1 className="text-1">Create Pan African Account</h1>
            <p className="mb-5">
                Already have an account?{' '}
                <span>
                    <Link to="/login" className="text-decoration-none font-color">
                        Log in
                    </Link>
                </span>
            </p>
            {
                isError &&
                <ErrorReports error={error}/>
            }
            <div className="d-flex flex-column gap-lg-4">

                    <CForm noValidate className="needs-validation w-100" onSubmit={handleSubmit(handleSignUp)}>
                        <CRow className="my-2">
                            <CCol md={4}>
                                <CFormInput
                                    type="text"
                                    id="firstName"
                                    placeholder="Enter Your First Name"
                                    {...register('firstName')}
                                    invalid={!!errors.firstName}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.firstName}>
                                    {errors.firstName?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={4}>
                                <CFormInput
                                    type="text"
                                    id="lastName"
                                    placeholder="Enter Your Last Name"
                                    {...register('lastName')}
                                    invalid={!!errors.lastName}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.lastName}>
                                    {errors.lastName?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={4}>
                                <CFormInput
                                    type="text"
                                    id="otherName"
                                    placeholder="Enter Other Names"
                                    {...register('otherName')}
                                    invalid={!!errors.otherName}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.otherName}>
                                    {errors.otherName?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow className="my-2">
                            <CCol md={6}>
                                <CFormInput
                                    type="email"
                                    id="inputEmail4"
                                    placeholder="Enter Your Email"
                                    {...register('email')}
                                    invalid={!!errors.email}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.email}>{errors.email?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="text"
                                    id="phone"
                                    className="mb-2"
                                    placeholder="Enter phone number"
                                    {...register('phone')}
                                    invalid={!!errors.phone}
                                />
                                <CFormFeedback invalid={!!errors.phone}>{errors.phone?.message}</CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow className="my-2">
                            <CCol md={6}>
                                <CFormSelect
                                    id="gender"
                                    className="mb-2"
                                    {...register('gender')}
                                    invalid={!!errors.gender}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </CFormSelect>
                                <CFormFeedback invalid={!!errors.gender}>
                                    {errors.gender?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="date"
                                    id="dateOfBirth"
                                    className="mb-2"
                                    placeholder="Enter Date of Birth"
                                    {...register('dateOfBirth')}
                                    invalid={!!errors.dateOfBirth}
                                />
                                <CFormFeedback invalid={!!errors.dateOfBirth}>
                                    {errors.dateOfBirth?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow className="my-2">
                            <CCol md={6}>
                                <CFormInput
                                    type="password"
                                    id="inputPassword4"
                                    {...register('password')}
                                    placeholder="Enter Your Password"
                                    invalid={!!errors.password}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.password}>
                                    {errors.password?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="password"
                                    id="confirmPassword"
                                    {...register('confirmPassword')}
                                    placeholder="Re-Enter Your Password"
                                    invalid={!!errors.confirmPassword}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.confirmPassword}>
                                    {errors.confirmPassword?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-100 py-2"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Sign Up
                        </CLoadingButton>

                    </CForm>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 font-color w-100">
                <p>
                    By signing up, you agree to our {' '}
                    <Link to="#" target="_blank">Terms of Use</Link> and{' '}
                    <Link to="#" target="_blank">Privacy Policy.</Link>
                </p>
            </div>
        </>
    )
}

export default SignUpPanAfrican
