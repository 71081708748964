import UntimedQuestionnaireLayout from '../../user/pages/questionnaires/UntimedQuestionnaireLayout'
import TimedQuestionnaireLayout from '../../user/pages/questionnaires/TimedQuestionnaireLayout'
import MainPage from '../../user/pages/dashboard/MainPage'
import Jobs from '../../user/pages/Jobs'
import JobDetail from '../../user/pages/JobDetail'
import TrainingDetail from '../../user/pages/TrainingDetail'
import UserTrainings from '../../user/pages/UserTrainings'
import SkillSet from '../../user/pages/SkillSet'
import TakeQuestionnaires from '../../user/pages/questionnaires/TakeQuestionnaires'
import Profile from '../../user/pages/dashboard/Profile'
import UserProfileUpdate from '../../components/User/UserProfileUpdate'
import ChangePassword from '../../components/ChangePassword'
import Messages from '../../user/pages/dashboard/Messages'
import Academy from '../../user/pages/Academy'
import ResumeTemplates from '../../components/User/ResumeTemplates'
import CreateCvOptions from '../../user/cv-builder/CreateCVOptions'
import SponsoredPrograms from '../../user/pages/SponsoredPrograms'
import SponsorshipApplication from '../../user/pages/SponsorshipApplication'
import AssessmentTips from '../../user/pages/questionnaires/test-monitoring/AssessmentTips'
import Wallet from '../../user/pages/Wallet'
import ApplicantBookings from '../../user/pages/ApplicantBookings'
import CVGenerator from '../../user/cv-builder/CVGenerator'
import ChatBoard from 'src/components/message/pages/dashboard/ChatBoard'

const PrivateUserRoutes = [
  { path: '/user/assessment-tips', exact: true, element: AssessmentTips },
  { path: '/user/questionnaire/:slug', exact: true, element: UntimedQuestionnaireLayout },
  { path: '/user/assessment/:slug', exact: true, element: TimedQuestionnaireLayout },
  { path: '/user/dashboard', exact: true, element: MainPage },
  { path: '/user/message', exact: true, element: ChatBoard },
  { path: '/ogtl-academy', exact: true, element: Academy },
  { path: '/user/jobs', exact: true, element: Jobs },
  { path: '/user/build-resume', exact: true, element: CVGenerator },
  { path: '/user/job-detail/:slug', exact: true, element: JobDetail },
  { path: '/user/training-detail/:slug', exact: true, element: TrainingDetail },
  { path: '/user/training-programs', exact: true, element: UserTrainings },
  { path: '/user/sponsored-programs', exact: true, element: SponsoredPrograms },
  {
    path: '/user/sponsored-programs-application/:sponsorshipId',
    exact: true,
    element: SponsorshipApplication,
  },
  { path: '/user/choose-skill-set', exact: true, element: SkillSet },
  { path: '/user/prequalifications', exact: true, element: TakeQuestionnaires },
  { path: '/user/profile', exact: true, element: Profile },
  { path: '/user/profile-update', exact: true, element: UserProfileUpdate },
  { path: '/user/change-password', exact: true, element: ChangePassword },
  { path: '/user/messages', exact: true, element: Messages },
  { path: '/user/resume-builder', exact: true, element: ResumeTemplates },
  { path: '/user/select-options', exact: true, element: CreateCvOptions },
  { path: '/user/wallet', exact: true, element: Wallet },
  { path: '/user/recruitment', exact: true, element: ApplicantBookings },
]
export default PrivateUserRoutes
