import { CButton, CModal, CModalBody } from '@coreui/react-pro'
import React from 'react'

const SuccessIcon = () => {
  return (
    <svg width="80" height="91" viewBox="0 0 80 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.1213 21.9482L54.0764 21.9258L43.3973 15.7794L43.3329 15.7486C38.3435 13.2272 31.6872 13.9864 24.5267 18.6144C10.1441 27.9153 -0.815199 49.324 0.047648 66.4324C0.47627 74.9572 3.76517 80.8039 8.73215 83.3756L8.77417 83.398L19.4561 89.5472L19.5065 89.5752C24.4959 92.0965 31.1578 91.3485 38.3239 86.7149C52.7065 77.4141 63.6658 56.0054 62.803 38.8969C62.3799 30.3665 59.091 24.5199 54.1213 21.9482Z"
        fill="#37474F"
      />
      <path
        d="M19.0891 89.3483L8.78815 83.3924L8.73772 83.3616C3.77075 80.7843 0.484647 74.9376 0.0532231 66.424C-0.330576 58.7928 1.63884 50.3129 5.20789 42.438L5.27793 42.2643L15.8898 48.5844C12.3152 56.4592 10.3514 64.9392 10.7352 72.5704C11.1414 80.9159 14.2986 86.6925 19.0891 89.3483Z"
        fill="#263238"
      />
      <path
        d="M38.3298 86.7093C52.7124 77.4085 63.6717 55.9998 62.8089 38.8913C61.946 21.7829 49.586 15.4572 35.2034 24.758C20.8207 34.0588 9.85862 55.4675 10.7215 72.5732C11.5843 89.6789 23.9471 96.0101 38.3298 86.7093Z"
        fill="#455A64"
      />
      <path
        d="M50.5099 20.6203L32.4826 57.7591L28.6026 53.5569C27.4456 52.3524 25.8625 51.6496 24.1931 51.5996C22.5237 51.5496 20.9013 52.1562 19.6743 53.2893C18.4473 54.4224 17.7136 55.9915 17.6309 57.6596C17.5481 59.3277 18.1229 60.9617 19.2317 62.2106L29.4542 73.2848C30.5783 74.506 32.1361 75.2381 33.7936 75.3242C33.9113 75.3242 34.029 75.3242 34.1466 75.3242C34.456 75.3239 34.7649 75.3015 35.0711 75.257C36.1001 75.1072 37.0768 74.7077 37.9158 74.0933C38.7547 73.479 39.4305 72.6685 39.884 71.7328L60.5587 29.1507C58.438 24.4218 54.9082 21.4915 50.5099 20.6203Z"
        fill="#37474F"
      />
      <path
        d="M65.1509 8.38913C65.0388 8.32469 64.9212 8.26306 64.8035 8.20703C63.4822 7.56677 61.9609 7.47677 60.5733 7.9568C59.1858 8.43683 58.0453 9.44768 57.4021 10.7676L34.4638 58.0169L33.1583 56.6161C32.7815 56.2044 32.343 55.8538 31.8585 55.5768C31.1749 55.1846 28.267 53.5009 27.7963 53.2292C26.6492 52.5655 25.3021 52.3352 23.9996 52.58C22.6971 52.8249 21.5256 53.5287 20.6978 54.5636C19.8699 55.5986 19.4407 56.8961 19.4879 58.2206C19.5351 59.545 20.0557 60.8086 20.9552 61.782L31.1777 72.8533C31.561 73.2692 32.006 73.6236 32.4972 73.9039C33.1639 74.2821 36.0606 75.9629 36.5369 76.2375C37.1956 76.6189 37.9259 76.8605 38.6822 76.9471C39.4384 77.0337 40.2044 76.9635 40.9324 76.7409C41.6603 76.5182 42.3345 76.1479 42.9129 75.653C43.4913 75.1581 43.9615 74.5494 44.2941 73.8647L71.4318 17.9673C72.0419 16.7075 72.1531 15.2635 71.7432 13.9251C71.3332 12.5867 70.4324 11.4528 69.2214 10.7507L65.1509 8.38913Z"
        fill="#1FC776"
      />
      <path
        opacity="0.15"
        d="M65.1509 8.38913C65.0388 8.32469 64.9212 8.26306 64.8035 8.20703C63.4822 7.56677 61.9609 7.47677 60.5733 7.9568C59.1858 8.43683 58.0453 9.44768 57.4021 10.7676L34.4638 58.0169L33.1583 56.6161C32.7815 56.2044 32.343 55.8538 31.8585 55.5768C31.1749 55.1846 28.267 53.5009 27.7963 53.2292C26.6492 52.5655 25.3021 52.3352 23.9996 52.58C22.6971 52.8249 21.5256 53.5287 20.6978 54.5636C19.8699 55.5986 19.4407 56.8961 19.4879 58.2206C19.5351 59.545 20.0557 60.8086 20.9552 61.782L31.1777 72.8533C31.561 73.2692 32.006 73.6236 32.4972 73.9039C33.1639 74.2821 36.0606 75.9629 36.5369 76.2375C37.1956 76.6189 37.9259 76.8605 38.6822 76.9471C39.4384 77.0337 40.2044 76.9635 40.9324 76.7409C41.6603 76.5182 42.3345 76.1479 42.9129 75.653C43.4913 75.1581 43.9615 74.5494 44.2941 73.8647L71.4318 17.9673C72.0419 16.7075 72.1531 15.2635 71.7432 13.9251C71.3332 12.5867 70.4324 11.4528 69.2214 10.7507L65.1509 8.38913Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M36.5227 76.2263C36.0521 75.9462 33.161 74.2653 32.4858 73.8927C31.9948 73.6122 31.5497 73.2579 31.1663 72.8421L20.9438 61.7708C20.4931 61.279 20.1353 60.7095 19.8877 60.0899L23.9526 62.4347C24.1991 63.0561 24.5591 63.6261 25.0144 64.1156L35.234 75.187C35.6101 75.595 36.0443 75.9452 36.5227 76.2263Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M62.7529 11.4119C62.2189 11.8911 61.7831 12.4696 61.4699 13.1152L37.876 61.7148L34.4639 58.0168L57.4022 10.7676C57.7148 10.1209 58.1517 9.54207 58.688 9.06427L62.7529 11.4119Z"
        fill="black"
      />
      <path
        d="M68.8704 10.5546C67.5489 9.91591 66.0281 9.82672 64.641 10.3066C63.2538 10.7865 62.1133 11.7963 61.469 13.1152L37.8751 61.7148L33.1603 56.6049C32.1513 55.5799 30.783 54.9879 29.3451 54.9542C27.9073 54.9205 26.5127 55.4477 25.4568 56.4243C24.4009 57.4008 23.7665 58.75 23.688 60.1861C23.6094 61.6222 24.0929 63.0326 25.0361 64.1184L35.2558 75.1925C35.8676 75.8544 36.6316 76.3569 37.4817 76.6566C38.3317 76.9562 39.2421 77.0438 40.1336 76.9119C41.0252 76.7799 41.8712 76.4324 42.598 75.8994C43.3248 75.3664 43.9106 74.6641 44.3045 73.8535L71.4421 17.9561C72.0816 16.6334 72.17 15.111 71.6878 13.7232C71.2056 12.3355 70.1923 11.1958 68.8704 10.5546Z"
        fill="#1FC776"
      />
      <path
        d="M48.1828 7.14248L46.6112 7.61593L45.4766 3.82556L47.0426 3.35492L48.1828 7.14248Z"
        fill="#E0E0E0"
      />
      <path
        d="M53.0882 3.77793C53.1013 3.93168 53.0652 4.08561 52.9852 4.21753C52.9051 4.34944 52.7852 4.45252 52.6428 4.51191C52.7448 4.62777 52.8204 4.76448 52.8641 4.91252C52.9538 5.19266 52.7156 5.59887 52.2422 5.75295L48.6479 6.83431L47.5918 3.31289C47.5918 3.31289 48.1997 1.75808 48.2305 1.52276C48.2613 1.28744 47.9028 0.424592 47.8523 0.262107C47.8019 0.0996232 48.3846 -0.113287 48.844 0.0716086C48.9509 0.119119 49.0464 0.188832 49.1242 0.276113C49.2894 0.452098 49.4282 0.651226 49.536 0.86722C49.6885 1.17507 49.7635 1.5155 49.7545 1.85893L51.9312 1.19499C52.0815 1.14064 52.246 1.14064 52.3963 1.19499C52.4946 1.23385 52.5813 1.29747 52.6478 1.37968C52.7143 1.46189 52.7585 1.5599 52.7759 1.66419C52.7934 1.76849 52.7836 1.87553 52.7475 1.97494C52.7114 2.07434 52.6503 2.16273 52.57 2.23153C52.6995 2.22214 52.8285 2.25651 52.9362 2.32915C53.0439 2.4018 53.1241 2.50849 53.1639 2.63214C53.1971 2.77588 53.1795 2.9267 53.1142 3.05897C53.0488 3.19123 52.9397 3.29679 52.8053 3.35771C52.9506 3.45575 53.052 3.60644 53.0882 3.77793Z"
        fill="#E0E0E0"
      />
      <path
        d="M70.7008 46.444L68.6865 45.9594L69.8603 41.1017L71.8746 41.5863L70.7008 46.444Z"
        fill="#E0E0E0"
      />
      <path
        d="M78.2002 45.8977C78.1165 46.0737 77.9793 46.2187 77.8082 46.3118C77.6371 46.4049 77.4408 46.4414 77.2477 46.416C77.284 46.6072 77.2792 46.8039 77.2337 46.9931C77.144 47.3713 76.6313 47.6487 76.0262 47.5002L71.415 46.3796L72.502 41.8665C72.502 41.8665 74.1577 40.5582 74.3398 40.3201C74.5218 40.0819 74.6787 38.9193 74.7292 38.7036C74.7796 38.4879 75.55 38.6336 75.9338 39.1294C76.0269 39.2499 76.092 39.3896 76.1243 39.5385C76.1923 39.8349 76.2169 40.1398 76.1971 40.4433C76.1667 40.877 76.0322 41.2969 75.8049 41.6676L78.5896 42.3399C78.7871 42.3818 78.9637 42.4919 79.0882 42.6509C79.1572 42.7416 79.2062 42.846 79.2318 42.9571C79.2574 43.0682 79.259 43.1834 79.2367 43.2952C79.1994 43.4425 79.1216 43.5764 79.0122 43.6818C78.9028 43.7873 78.7661 43.86 78.6176 43.8919C78.7649 43.9641 78.8838 44.0837 78.9549 44.2316C79.026 44.3795 79.0454 44.5469 79.0098 44.7071C78.953 44.8842 78.8372 45.0364 78.6817 45.1384C78.5262 45.2404 78.3404 45.2859 78.1553 45.2674C78.2517 45.4637 78.2678 45.6898 78.2002 45.8977Z"
        fill="#E0E0E0"
      />
      <path
        d="M12.2861 24.3934L14.4853 25.0545L16.0765 19.7486L13.8802 19.0874L12.2861 24.3934Z"
        fill="#E0E0E0"
      />
      <path
        d="M5.41402 19.6785C5.39568 19.894 5.44631 20.1097 5.55861 20.2945C5.6709 20.4794 5.83904 20.6237 6.03874 20.7067C5.89755 20.8695 5.79252 21.0605 5.73058 21.267C5.60451 21.6788 5.94069 22.2307 6.59903 22.4268L11.6416 23.9424L13.1152 19.0034C13.1152 19.0034 12.2608 16.8239 12.2187 16.4821C12.1767 16.1403 12.681 14.9469 12.7482 14.7172C12.8042 14.5323 12.003 14.1933 11.3475 14.4538C11.1916 14.5149 11.0517 14.6107 10.9385 14.734C10.7057 14.9841 10.5112 15.2673 10.3614 15.5744C10.1467 16.0095 10.041 16.4902 10.0532 16.9751L7.02765 16.0619C6.8143 15.9917 6.58295 15.9996 6.37491 16.0843C6.23703 16.1385 6.11557 16.2276 6.02238 16.3428C5.92918 16.458 5.86744 16.5954 5.84317 16.7416C5.8189 16.8877 5.83294 17.0377 5.88391 17.1768C5.93488 17.316 6.02104 17.4395 6.13399 17.5354C5.95114 17.5197 5.76838 17.5666 5.61568 17.6684C5.46298 17.7702 5.3494 17.9209 5.29355 18.0957C5.24699 18.2974 5.27145 18.509 5.3628 18.6948C5.45416 18.8806 5.60682 19.0292 5.79501 19.1154C5.60307 19.2499 5.46742 19.4504 5.41402 19.6785Z"
        fill="#E0E0E0"
      />
    </svg>
  )
}

const JobSuccessModal = ({ visible, setVisible, setActiveTap }) => {
  return (
    <CModal alignment="center" visible={visible} onClose={() => setVisible(false)} className="p-3">
      <CModalBody className="d-flex flex-column justify-content-center align-items-center">
        <SuccessIcon />
        <p className="fs-5 fw-bold text-success">Successful</p>
        <p>Your job ad has been published successfully.</p>
      </CModalBody>

      <CButton
        style={{ background: '#1FAB89', width: '300px', fontSize: '.8rem' }}
        size="lg"
        onClick={() => {
          setVisible(false)
          setActiveTap(1)
        }}
        className="text-white rounded-0 shadow-none text-capitalize mx-auto mb-3"
      >
        OK
      </CButton>
    </CModal>
  )
}

export default JobSuccessModal
