/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useAppContext } from 'src/context/user/UserAppProvider'
// import appApiCalls from 'src/services/api'
import { useNavigate } from 'react-router-dom'
import ShowLoading from 'src/components/ShowLoading'

const MessageDashboard = () => {
  const [error, setError] = useState()
  const navigate = useNavigate()

  const { chatUser, storeChatUser } = useAppContext()

  useEffect(() => {
    const getChatUser = async () => {
      try {
        // const chatData = await appApiCalls.getActiveChatUser('yakubuh56@gmail.com')
        if (!chatUser?.data) {
          return setError('Error! Unable to initialize chat!')
        }
        storeChatUser(chatUser.data)
      } catch (error) {
        console.log(error)
        setError('Error! Unable to initialize chat!')
      }
    }
    if (!chatUser) {
      getChatUser()
    } else {
      navigate(`/employer/message/chat`, {
        // navigate(`/ai/chat`, {
        replace: true,
      })
    }
  }, [chatUser])

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div>
      <ShowLoading />
    </div>
  )
}

export default MessageDashboard
