import React from 'react'
import { BsCheck2All } from 'react-icons/bs'
import { format } from 'date-fns'

const ReceiverText = ({ text, timeStamp }) => {
  return (
    <div
      className="ms-auto greyColor smallText py-1 px-2 rounded-1 my-2 rounded-2 flex flex-wrap justify-content-between"
      style={{ background: '#D5EFE3', maxWidth: '50%' }}
    >
      <p>{text}</p>
      <p className="d-flex justify-content-end gap-2 align-items-center">
        <BsCheck2All color="green" size="16" />{' '}
        <span style={{ fontSize: '10px' }} className="greyColor">
          {format(new Date(timeStamp), 'hh:mma')}
        </span>
      </p>
    </div>
  )
}

export default ReceiverText
