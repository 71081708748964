import React, { useEffect, useRef, useState } from "react";
import {
    CCol,
    CFormInput,
    CImage,
    CLoadingButton,
    CRow,
    CButton,
    CFormCheck,
} from "@coreui/react-pro";
import HRIcon from "../../assets/images/shared-profile-hr.png";
import CamIcon from "../../assets/icons/cam-icon.svg";
import { useProfileVideoIntro } from "../../hooks/user/userApiHooks";
import { fail_notify, success_notify } from "../../constant";

const UserProfileIntroVideo = ({ userProfileData, isEmployerView }) => {
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedVideoURL, setRecordedVideoURL] = useState(null);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [videoChunks, setVideoChunks] = useState([]);
    const [file, setFile] = useState(null);
    const [imgError, setImgError] = useState(null);
    const [option, setOption] = useState("upload");
    const { isLoading, isSuccess, isError, error, mutate } = useProfileVideoIntro();

    useEffect(() => {
        if (isSuccess) {
            success_notify("Intro Video Uploaded Successfully");
            setFile(null);
            setRecordedBlob(null);
            setRecordedVideoURL(null);
        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [isSuccess, isError, error, mutate]);

    const handleVideoChange = (e) => {
        const selectedFile = e?.target?.files[0];
        if (selectedFile) {
            // Check if the selected file is a video type
            if (!selectedFile.type.startsWith("video/")) {
                setImgError("Please select a valid video file");
                return;
            }
            if (selectedFile.size > 50 * 1024 * 1024) {
                setImgError("Maximum video size is 50MB");
                return;
            }
            setFile(selectedFile);
            setImgError(null);
        }
    };

    const startWebcam = async () => {
        try {
            videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia({ video: true });
        } catch (error) {
            console.error("Error accessing webcam: ", error);
        }
    };

    const startRecording = () => {
        setVideoChunks([]);
        const stream = videoRef.current.srcObject;
        if (!stream) {
            console.error("No video stream available for recording.");
            return;
        }
        const mediaRecorder = new MediaRecorder(stream, {
            mimeType: "video/webm", // Choose 'video/webm' or 'video/mp4' based on browser support
        });
        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                setVideoChunks((prevChunks) => [...prevChunks, event.data]);
            }
        };
        mediaRecorder.onstop = () => {
            const blob = new Blob(videoChunks, { type: "video/webm" });
            setRecordedBlob(blob);
            setRecordedVideoURL(URL.createObjectURL(blob));
        };
        mediaRecorder.start();
        setIsRecording(true);
        mediaRecorderRef.current = mediaRecorder;
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const onSubmit = () => {
        if (option === "upload" && file) {
            mutate({ file });
        } else if (option === "record" && recordedBlob) {
            // Convert blob to File object
            const recordedFile = new File([recordedBlob], "recorded_video.webm", {
                type: "video/webm",
            });
            mutate({ file: recordedFile });
        }
    };

    const width = isEmployerView ? `100%` : "400px";

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <h6 className="fw-bold">Intro Video</h6>
            </div>
            <CImage src={HRIcon} style={{ width: "100%" }} />
            <CRow className="my-4">
                <CCol md={12}>
                    {userProfileData?.introVideoUrl ? (
                        <video controls style={{ width: width }}>
                            <source src={userProfileData?.introVideoUrl} type="video/webm" />
                            <source src={userProfileData?.introVideoUrl} type="video/mp4" />
                            Download the
                            <a href={userProfileData?.introVideoUrl}>WEBM</a> or
                            <a href={userProfileData?.introVideoUrl}>MP4</a> video.
                        </video>
                    ) : (
                        <p>No Intro Video</p>
                    )}
                    {!isEmployerView && (
                        <div>
                            <h6 className="fw-bold mt-3">Choose an option for Intro Video</h6>
                            <div className="d-flex gap-3">
                                <CFormCheck
                                    type="radio"
                                    name="videoOption"
                                    label="Upload Video"
                                    value="upload"
                                    checked={option === "upload"}
                                    onChange={() => setOption("upload")}
                                />
                                <CFormCheck
                                    type="radio"
                                    name="videoOption"
                                    label="Record Video"
                                    value="record"
                                    checked={option === "record"}
                                    onChange={() => setOption("record")}
                                />
                            </div>
                            {option === "upload" ? (
                                <div>
                                    <h6 className="fw-bold mt-3">Upload Intro Video</h6>
                                    <label htmlFor="file" className="w-100 cursor-pointer profile-card-label mt-3">
                                        <div>
                                            <CImage src={CamIcon} style={{ width: "45px", height: "45px" }} />
                                            <p>Select Video File</p>
                                        </div>
                                        <div>
                                            <small className="text-danger text-truncate">{imgError}</small>
                                        </div>
                                    </label>
                                    <CFormInput
                                        type="file"
                                        hidden={true}
                                        name="file"
                                        id="file"
                                        accept="video/*"
                                        onChange={handleVideoChange}
                                    />
                                    <div className="mt-1">
                                        <p>{file?.name}</p>
                                        {file?.name && (
                                            <CLoadingButton
                                                className="w-50 text-white rounded-0"
                                                loading={isLoading}
                                                disabled={isLoading}
                                                onClick={onSubmit}
                                            >
                                                Upload Video Intro
                                            </CLoadingButton>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <h6 className="fw-bold mt-3">Record Intro Video</h6>
                                    <video ref={videoRef} autoPlay playsInline muted width="640" height="480" />
                                    <div className="mt-2">
                                        <CButton onClick={startWebcam} className="m-2 text-white" color="primary">
                                            Start Webcam
                                        </CButton>
                                        <CButton
                                            onClick={startRecording}
                                            className="m-2 text-white"
                                            color="success"
                                            disabled={isRecording}
                                        >
                                            Start Recording
                                        </CButton>
                                        <CButton
                                            onClick={stopRecording}
                                            className="m-2 text-white"
                                            color="danger"
                                            disabled={!isRecording}
                                        >
                                            Stop Recording
                                        </CButton>
                                    </div>
                                    {recordedVideoURL && (
                                        <div>
                                            <h6 className="mt-3">Recorded Video:</h6>
                                            <video src={recordedVideoURL} controls width="480" height="auto" />
                                            <div className="mt-2">
                                                <CLoadingButton
                                                    className="w-50 text-white rounded-0"
                                                    loading={isLoading}
                                                    disabled={isLoading}
                                                    onClick={onSubmit}
                                                >
                                                    Upload Recorded Video
                                                </CLoadingButton>
                                            </div>
                                            <a href={recordedVideoURL} download="recorded_video.webm" className="d-block mt-2">
                                                Download Video
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </CCol>
            </CRow>
        </>
    );
};

export default UserProfileIntroVideo;
