import React from 'react';

import EmployerJobList from "../../components/employer/Jobs/EmployerJobList";

function EmployerJobListing() {

    return (
        <>
            <div className="mt-5">
                <div className="">
                    <h5 className="text-2 ps-1">Posted Jobs</h5>
                    <hr/>
                </div>


                <br/>
                <EmployerJobList/>
            </div>
        </>
    );
}

export default EmployerJobListing;
