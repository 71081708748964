import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import appApiCalls from "../../services/api";
// import {data} from "autoprefixer";
import appKPICalls from "../../services/kpi-api";

export function useAdminSignInQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.adminSignIn(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['LoggedIn'])
            return data
        }
    });
}

export function useCreateAdminQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.createAdmin(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['CreateAdmin'])
            return data
        }
    })
}
export function useCreateEmployerRole() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.createEmployerRole(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['EmployerRoles'])
            return data
        }
    })
}

export function useCreateTeam() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.createEmployerTeam(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Employer Team'])
            return data
        }
    })
}

export function usePostQuestionQuery(uuid = null) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.postQuestion(uuid, arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['PostQuestion'])
            return data
        }
    })
}


export function useUpdateQuestionQuery(id) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateQuestion(id, arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['GetTestByUUid'])
            return data
        },
    })
}


export function useGetAdminRolesQuery(){
    return useQuery(
      ['Get Admin Roles'],
      () => appApiCalls.getAdminRoles(),
      {
          keepPreviousData: true,
          staleTime: Infinity,
      }
    )
}

export function useGetAdminPermissionsQuery(){
    return useQuery(
        ["Get Admin Permissions"],
        () => appApiCalls.getAdminPermissions(),
        {
          keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}
export function useGetEmployerPermissions(){
    return useQuery(
        ["Get Permissions"],
        () => appApiCalls.getEmployerPermissions(),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}
export function useGetEmployerTeam(){
    return useQuery(
        ['Employer Team'],
        () => appApiCalls.getEmployerTeam(),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}

export function useGetEmployerRoles() {
    return useQuery(
        ['GetRoles'],
        () => appApiCalls.getEmployerRoles(),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}
export function useGetAllAdmins(limit) {
  return useQuery(
    ['getGetAllAdmins'],
    () => appApiCalls.getAllAdmins(limit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )
}

export function useGetAttendance(search = '', limit = 10, page = 1, date = (new Date()).toISOString().slice(0, 10)) {
    return useQuery(
        ['getGetAttendance', search, limit, page, date],
        () => appApiCalls.getAttendance(search, limit, page, date),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}

export function useGetLMSReport(stateOfOrigin = '', limit = 10, page = 1,) {
    return useQuery(
        ['useGetLMSReport', stateOfOrigin, limit, page],
        () => appApiCalls.getLMSReport(stateOfOrigin, limit, page),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}

export function useGetLMSCourse(stateOfOrigin = '', limit = 10, page = 1) {
  return useQuery(
    ['useGetLMSReport', stateOfOrigin, limit, page],
    () => appApiCalls.getNewLMSCourse(stateOfOrigin, limit, page),
    {
      retryOnMount: true,
    },
  )
}

export function useGetLMSCourseModuleByID(id) {
  return useQuery(['useGetLMSCourseModuleByID', id], () => appApiCalls.getNewLMSCourseByID(id), {
    retryOnMount: true,
  })
}

export function useGetNewLMSCourseUserByID(id) {
  return useQuery(
    ['useGetNewLMSCourseUserByID', id],
    () => appApiCalls.getNewLMSCourseUserByID(id),
    {
      retryOnMount: true,
    },
  )
}

export function useAddGov() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.addGovernment(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['GetAllStakeholders'])
            return data
        },
    })
}

export function useAddNewTrainer() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appKPICalls.addNewTrainer(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['AddNewTrainer'])
            return data
        }
    })
}

export function useAddNewCohort() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appKPICalls.addNewCohort(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['AddNewCohort'])
            return data
        }
    })
}

export function useUploadBeneficiaries() {
    const queryClient = useQueryClient();
    return useMutation((args) => {
        return appKPICalls.uploadBeneficiaries(args)
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['UploadTrainers']);
            return data;
        }
    });
}


export function useAddTrainerToCohortCourse() {
    const queryclient = useQueryClient()
    return useMutation((arg) => appKPICalls.addTrainerToCohortCourse(arg), {
        onSuccess: (data) => {
            queryclient.invalidateQueries(['AddTrainerToCohortCourse'])
            return data
        }
    })
}

export function useAddTraineeToCohort() {
    const queryclient = useQueryClient()
    return useMutation((arg) => appKPICalls.addTraineeToCohort(arg), {
        onSuccess: (data) => {
            queryclient.invalidateQueries(['AddTraineeToCohort'])
            return data
        }
    })
}

export function useAddTraineeToTrainer() {
    const queryclient = useQueryClient()
    return useMutation((arg) => appKPICalls.addTraineeToTrainer(arg), {
        onSuccess: (data) => {
            queryclient.invalidateQueries(['AddTraineeToTrainer'])
            return data
        }
    })
}

export function useGetKPITrainers(page, limit) {
    return useQuery(
        ['GetKPITrainers'],
        () => appKPICalls.getKPITrainers(page, limit),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useGetKPICourses() {
    return useQuery(
        ['GetKPICourses'],
        () => appKPICalls.getKPICourses(),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useGetKPITrainerCourse(instructorId) {
    return useQuery(
        ['GetKPITrainerCourse'],
        () => appKPICalls.getTrainerCourses(instructorId),
        {
            keepPreviousData: false,
            staleTime: 1
        }
    )
}

export function useGetAllTrainers() {
    return useQuery(
        ['GetAllTrainers'],
        () => appKPICalls.getAllTrainers(),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useGetAllTrainees() {
    return useQuery(
        ['GetAllTrainees'],
        () => appKPICalls.getAllTrainees(),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useGetAllCohorts(page, limit, search) {
    return useQuery(
        ['GetAllCohorts'],
        () => appKPICalls.getAllCohorts(page, limit, search),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useGetCohortBeneficiaries(cohortId) {
    return useQuery(
        ['GetCohortBeneficiaries'],
        () => appKPICalls.getCohortBeneficiaries(cohortId),
        {
            keepPreviousData: true,
            staleTime: 1
        }
        )
}

export function useGetTrainerTrainees(id, page, limit) {
    return useQuery(
        ['GetTrainerTrainees'],
        () => appKPICalls.getTrainerTrainees(id, page, limit),
        {
            keepPreviousData: false,
            staleTime: 1,
        })
}

export function useGetAdminByUuid(uuid) {
  return useQuery(
    ['getAdminByUuid'],
    () => appApiCalls.getAdminByUuid(uuid),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )
}

export function useGetJobsReports(page, limit) {
  return useQuery(
    ['getJobReports', page, limit],
    () => appApiCalls.getJobReports(page, limit),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )
}

export function useGetSkillSetReports(page, limit, quizType, from, to, stateOfOrigin) {

    return useQuery(
        ['GetSkillSetReports', page, limit, quizType, from, to, stateOfOrigin],
        () => appApiCalls.getSkillSetReports(page, limit, quizType, from, to, stateOfOrigin),
        {
            keepPreviousData: true,
            enabled: !!quizType,
            staleTime: Infinity,
        }
    )
}

export function useGetAuditTrail(token, page, limit) {
    return useQuery(
        ['GetAuditTrail'],
        () => appApiCalls.getAuditTrail(token, page, limit),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    )
}

export function useUploadUsers() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.uploadUsers(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Get users'])
            return data
        },
    })
}
