import React, {useEffect, useState} from 'react'
import {FaMinus, FaPlus} from "react-icons/fa";
// import {FaRegEnvelope} from "react-icons/fa6";
import {CButton, CCol, CFormSelect, CImage, CLoadingButton, CRow} from "@coreui/react-pro";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png";
// import {AiOutlineDownload} from "react-icons/ai";
import {useGetAllJobTitles, useGetMovePipeline} from "../../hooks/employer/employerApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useQueryClient} from "@tanstack/react-query";
import {useGetAllStates, useGetInstitutions} from "../../hooks/user/userApiHooks";
import {useGetAllQuestionnairesQuery} from "../../hooks/user/assessmentApiHooks";
import appApiCalls from "../../services/api";
import {SOCKET_EVENTS, SOCKET_LISTENERS} from "../../constant/constants";
import {useNavigate} from "react-router-dom";
import useSocketEvent from "../../hooks/user/useSocketEvent";
import useSocket from "../../hooks/user/useSocket";

function TalentPool({data, projectId}) {
    const [jobTitles, setJobTitles] = useState([]);
    const [location, setLocation] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [skillAndAssessments, setSkillAndAssessments] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showInputValue, setShowInputValue] = useState(null);
    const [filter, setFilter] = useState('');
    const [clickSearch, setClickSearch] = useState(false);
    const {data: states} = useGetAllStates()
    const {data: allInstitutions} = useGetInstitutions()
    const {data: skills} = useGetAllQuestionnairesQuery()
    const { data: jobTitlesData } = useGetAllJobTitles()
    const [loadingMsg, setLoadingMsg] = useState(false)

    const { isLoading, isSuccess, isError, error } = useGetMovePipeline(filter)
    const queryClient = useQueryClient()
    useEffect(() => {
      if (isSuccess) {
        queryClient.invalidateQueries([projectId])
        success_notify('Users moved to pipeline successfully')
      }
      if (isError) {
        fail_notify(error?.response?.data?.message)
      }
      setClickSearch(false)
    }, [isSuccess, isError, error, isLoading])

    const navigate = useNavigate()
    const { socket } = useSocket()

    useSocketEvent([
      {
        name: SOCKET_LISTENERS.CHAT_CREATED,
        cb: (data) => {},
      },
    ])

    const chatWithACandidate = async (user) => {
      setLoadingMsg(true)
      try {
        const { firstName, lastName, email } = user
        const chatData = await appApiCalls.getActiveChatUser(email)
        if (!chatData?.data) {
          //   initalize chat user
          const res = await appApiCalls.initializeChatUser({
            email,
            firstName,
            lastName,
            role: 'User',
          })
          socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: res?.data?.id })
          navigate(`/employer/message/chat?activeUserId=${res?.data?.id}`, {
            replace: true,
          })
          success_notify('chat created')
        } else {
          socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: chatData?.data?.id })
          navigate(`/employer/message/chat?activeUserId=${chatData?.data?.id}`, {
            replace: true,
          })

          success_notify('chat created')
        }
      } catch (error) {
        console.log(error)
      } finally {
        // navigate(`/employer/message/chat`, {
        //     replace: true,
        // })
        setLoadingMsg(false)
      }
    }

    const handleAddItem = (setArray, array) => {
      if (inputValue) {
        setArray([...array, inputValue])
        setInputValue('') // Clear the input field
        setShowInputValue(null)
      }
    }

    function searchInput(setArray, array, options) {
      return (
        <div className="d-flex mt-2">
          {/*<CFormInput*/}
          {/*    type="text"*/}
          {/*    className=" rounded rounded-2 rounded-end-0"*/}
          {/*    value={inputValue}*/}
          {/*    onChange={(e) => setInputValue(e.target.value)}*/}
          {/*/>*/}
          <CFormSelect
            className="rounded rounded-2 rounded-end-0"
            onChange={(e) => setInputValue(e.target.value)}
          >
            <option value="">Select</option>
            {options?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              )
            })}
          </CFormSelect>
          <div>
            <CButton
              className="text-white rounded rounded-2 rounded-start-0"
              onClick={() => handleAddItem(setArray, array)}
            >
              Add
            </CButton>
          </div>
        </div>
      )
    }

    const removeTag = (index, setArray, array) => {
      setArray(array.filter((_, i) => i !== index))
    }

    return (
      <>
        <CRow className="py-2">
          <CCol md={4} className="bg-white px-0">
            <div className="rounded pt-3 rounded-bottom-5 shadow-b w-100">
              <center className="w-100">
                <small className="text-black-50 fw-bolder">
                  <b className="text-black">{data?.items.length}</b> Total Candidates
                </small>
                <div>
                  <CLoadingButton className="bg-transparent border-grey text-black-50 fw-bolder m-3 px-4">
                    Cancel
                  </CLoadingButton>
                  <CLoadingButton
                    onClick={() => {
                      if (jobTitles?.length < 1) {
                        return
                      }
                      setFilter(
                        `?projectId=${projectId}${
                          jobTitles?.length > 0 ? '&jobTitle=' + jobTitles[0] : ''
                        }`,
                      )
                      setClickSearch(true)
                    }}
                    loading={clickSearch}
                    disabled={clickSearch}
                    className="border-primary text-white fw-bolder px-4 m-3"
                  >
                    Search
                  </CLoadingButton>
                </div>
              </center>
            </div>

            <div className="p-2">
              <br />
              <div className="flex-fill px-3">
                <div className="d-flex justify-content-between text-primary small">
                  <h6 className="text-black-50 fw-bolder">
                    <b>Showing results for</b>
                  </h6>
                  <h6
                    className="text-decoration-underline text-black-50 cursor-pointer"
                    onClick={() => {
                      setJobTitles([])
                      setSkillAndAssessments([])
                      setInstitutions([])
                      setLocation([])
                      setShowInputValue(null)
                    }}
                  >
                    Clear all
                  </h6>
                </div>
              </div>
              <hr />

              <div className="flex-fill px-3">
                <div className="d-flex justify-content-between text-primary small">
                  <h6 className="text-black fw-bolder">
                    <b>Job Titles</b>
                  </h6>
                  <h6
                    className="text-black-50 cursor-pointer"
                    onClick={() => {
                      setJobTitles([])
                    }}
                  >
                    Clear
                  </h6>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-2">
                  {jobTitles.map((item, index) => {
                    return (
                      <small key={index} className="rounded rounded-4 bg-grey-200 text-small p-2">
                        {item}
                        <svg
                          onClick={() => removeTag(index, setJobTitles, jobTitles)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x cursor-pointer text-red"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </small>
                    )
                  })}
                  {showInputValue !== 'jobTitles' && (
                    <FaPlus
                      onClick={() => {
                        setShowInputValue('jobTitles')
                      }}
                      className="cursor-pointer"
                    />
                  )}
                  {showInputValue === 'jobTitles' && (
                    <FaMinus
                      onClick={() => {
                        setShowInputValue(null)
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {showInputValue === 'jobTitles' &&
                  searchInput(setJobTitles, jobTitles, jobTitlesData?.data?.data)}
              </div>
              <hr />

              <div className="flex-fill px-3">
                <div className="d-flex justify-content-between text-primary small">
                  <h6 className="text-black fw-bolder">
                    <b>Locations</b>
                  </h6>
                  <h6
                    className="text-black-50 cursor-pointer"
                    onClick={() => {
                      setLocation([])
                    }}
                  >
                    Clear
                  </h6>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-2">
                  {location.map((item, index) => {
                    return (
                      <small key={index} className="rounded rounded-4 bg-grey-200 text-small p-2">
                        {item}
                        <svg
                          onClick={() => removeTag(index, setLocation, location)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x cursor-pointer text-red"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </small>
                    )
                  })}
                  {showInputValue !== 'location' && (
                    <FaPlus
                      onClick={() => {
                        setShowInputValue('location')
                      }}
                      className="cursor-pointer"
                    />
                  )}
                  {showInputValue === 'location' && (
                    <FaMinus
                      onClick={() => {
                        setShowInputValue(null)
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {/*{showInputValue === "location" && searchInput(setLocation, location)}*/}
                {showInputValue === 'location' &&
                  searchInput(
                    setLocation,
                    location,
                    states?.data?.data?.map(({ stateName }) => stateName),
                  )}
              </div>
              <hr />

              <div className="flex-fill px-3">
                <div className="d-flex justify-content-between text-primary small">
                  <h6 className="text-black fw-bolder">
                    <b>Institutions</b>
                  </h6>
                  <h6
                    className="text-black-50 cursor-pointer"
                    onClick={() => {
                      setInstitutions([])
                    }}
                  >
                    Clear
                  </h6>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-2">
                  {institutions.map((item, index) => {
                    return (
                      <small key={index} className="rounded rounded-4 bg-grey-200 text-small p-2">
                        {item}
                        <svg
                          onClick={() => removeTag(index, setInstitutions, institutions)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x cursor-pointer text-red"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </small>
                    )
                  })}
                  {showInputValue !== 'institutions' && (
                    <FaPlus
                      onClick={() => {
                        setShowInputValue('institutions')
                      }}
                      className="cursor-pointer"
                    />
                  )}
                  {showInputValue === 'institutions' && (
                    <FaMinus
                      onClick={() => {
                        setShowInputValue(null)
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {showInputValue === 'institutions' &&
                  searchInput(
                    setInstitutions,
                    institutions,
                    allInstitutions?.data?.data?.map(({ name }) => name),
                  )}
              </div>
              <hr />

              <div className="flex-fill px-3">
                <div className="d-flex justify-content-between text-primary small">
                  <h6 className="text-black fw-bolder">
                    <b>Skills and Assessment</b>
                  </h6>
                  <h6
                    className="text-black-50 cursor-pointer"
                    onClick={() => {
                      setSkillAndAssessments([])
                    }}
                  >
                    Clear
                  </h6>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-2">
                  {skillAndAssessments.map((item, index) => {
                    return (
                      <small key={index} className="rounded rounded-4 bg-grey-200 text-small p-2">
                        {item}
                        <svg
                          onClick={() =>
                            removeTag(index, setSkillAndAssessments, skillAndAssessments)
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x cursor-pointer text-red"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </small>
                    )
                  })}
                  {showInputValue !== 'skillAndAssessments' && (
                    <FaPlus
                      onClick={() => {
                        setShowInputValue('skillAndAssessments')
                      }}
                      className="cursor-pointer"
                    />
                  )}
                  {showInputValue === 'skillAndAssessments' && (
                    <FaMinus
                      onClick={() => {
                        setShowInputValue(null)
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {showInputValue === 'skillAndAssessments' &&
                  searchInput(
                    setSkillAndAssessments,
                    skillAndAssessments,
                    skills?.data?.data?.map(({ title }) => title),
                  )}
              </div>
              <hr />
            </div>
          </CCol>
          <CCol md={8}>
            <div className="bg-white p-2 px-3 py-3">
              <h6 className="text-black fw-bolder">
                <b>Candidate Results</b>
              </h6>
              {data?.items?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between flex-column flex-lg-row align-items-center">
                      <div className="d-flex align-items-start mt-3">
                        <CImage
                          src={item?.profile?.passportUrl || DummyProfilePic}
                          onError={(e) => {
                            e.target.src = DummyProfilePic
                          }}
                          alt="Profile"
                          className="pipeline-candidate-pic"
                        />
                        <div className="d-flex flex-column text-black">
                          <small className="text-primary fw-bolder">
                            <strong>
                              {item?.firstName} {item?.lastName}
                            </strong>
                          </small>
                          <small className="small-text-gray fw-medium pipeline-candidate-skills">
                            {item?.profile?.professionalSkills?.map((item) => {
                              return item + ' | '
                            })}
                          </small>
                          <small className="text-black-50 pipeline-candidate-state">
                            {' '}
                            {item?.profile?.stateOfResidence}
                          </small>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex justify-content-end flex-wrap align-items-center gap-2">
                          <CButton
                            className="text-small btn-sm text-white rounded-0"
                            onClick={() => {
                              chatWithACandidate(item)
                            }}
                            disabled={loadingMsg}
                          >
                            Send Message
                          </CButton>
                          {/*<CButton*/}
                          {/*    className="text-small bg-transparent yellow-border btn-sm text-yellow rounded-0">*/}
                          {/*    <AiOutlineDownload color="#F7B100"/> Archive*/}
                          {/*</CButton>*/}
                          {/*<CButton*/}
                          {/*    className='bg-transparent border border-0 btn-sm text-black rounded-0'*/}
                          {/*    style={{fontSize: '12px'}}*/}
                          {/*> <FaRegEnvelope className="text-black-50"/> Send Message</CButton>*/}
                        </div>
                        <div className="d-flex justify-content-end mt-1">
                          <div className="d-flex flex-column align-items-end">
                            <small
                              className="small-text-gray"
                              style={{ fontSize: '12px', marginTop: '-2px' }}
                            >
                              <b className="text-black">Replied</b> on June 28, 2024
                            </small>
                            <small
                              className="small text-primary"
                              style={{ fontSize: '12px', marginTop: '-2px' }}
                            >
                              <b>Accepted</b>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                )
              })}
            </div>
          </CCol>
        </CRow>
      </>
    )
}

export default TalentPool;
