import React, { useEffect, useRef } from 'react'
import ReceiverText from './ReceiverText'
import SenderText from './SenderText'

const ChatView = ({ messages, user }) => {
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    const element = messagesEndRef.current
    const scrollHeight = element?.scrollHeight

    element?.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sortedMessages = messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  return (
    <div
      ref={messagesEndRef}
      style={{ height: '71vh', overflowY: 'auto', paddingBottom: '2.5rem' }}
    >
      {user ? (
        sortedMessages.map(({ createdAt, id, message, senderId }, index) => {
          return (
            <div key={`${id}=${createdAt}-{${index}}`}>
              {senderId === user.memberId ? (
                <SenderText text={message} timeStamp={createdAt} />
              ) : (
                <ReceiverText text={message} timeStamp={createdAt} />
              )}
            </div>
          )
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center text-muted">
          Select a user to chat with...
        </div>
      )}
    </div>
  )
}

export default ChatView
