import { CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import React from 'react'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
]

const CustomInput = ({ label, name, type, placeholder, value, handleFieldChange, options }) => {
  return (
    <>
      <CFormLabel htmlFor={name} className="fw-medium mb-0 labelColor">
        {label}
      </CFormLabel>
      {type === 'text' && (
        <CFormInput
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={(e) => handleFieldChange({ name: e.target.name, value: e.target.value })}
          placeholder={placeholder}
          aria-describedby={name}
          className="py-2 inputBorder"
        />
      )}
      {type === 'select' && (
        <CFormSelect
          aria-label="Default select example"
          options={options}
          id={name}
          onChange={(e) => handleFieldChange({ name: e.target.name, value: e.target.value })}
          name={name}
          className="py-2 inputBorder"
        />
      )}
    </>
  )
}

export default CustomInput
