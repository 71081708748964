import {useMutation, useQueries, useQuery, useQueryClient} from '@tanstack/react-query'
import appApiCalls from '../../services/api'

export function useSignUpQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.signUp(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['signUp'])
            return data
        },
    })
}

export function usePanAfricanSignUpQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.panAfricanSignUp(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['PanAfricanSignUp'])
            return data
        },
    })
}

export function useGetNasimUser(urlParams) {
    return useQuery(['Get NASIM User'], () => appApiCalls.getNasimUser(urlParams), {
        enabled: !!urlParams,
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useNasimSignUpQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.nasimsSignUp(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['nasimSignUp'])
            return data
        },
    })
}

export function useProfileFileUpload() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.profileFileUpload(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['User Profile'])
            queryClient.invalidateQueries(['ProfileFileUpload'])
            queryClient.invalidateQueries(['Get User By UUID'])
            return data
        },
    })
}

export function useProfileVideoIntro() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.profileVideoIntro(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['User Profile'])
            queryClient.invalidateQueries(['ProfileVideoIntro'])
            queryClient.invalidateQueries(['Get User By UUID'])
            return data
        },
    })
}

export function useGetLevelOneQuestionsQuery() {
    return useQuery(['LevelOneQuestionaire'], () => appApiCalls.getLevelOneQuestions(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetQuestionnaires(url) {
    return useQuery(['Get Test', url], () => appApiCalls.getQuestionnaires(url), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetQuestionnairesByUUId(UUid) {
    return useQuery(['GetTestByUUid', UUid], () => appApiCalls.getQuestionnairesByUUId(UUid), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useLevelOneAnswerQuizQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.levelOneQuizAnswers(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['User Profile'])
            queryClient.invalidateQueries(['checkQuizStatus'])
            queryClient.invalidateQueries(['SkillSets'])
            return data
        },
    })
}

export function useSignInQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.employerSignin(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['LoggedIn'])
            queryClient.invalidateQueries(['useGetCVBuilderQuery'])
            queryClient.invalidateQueries(['LevelOneQuestionaire'])
            queryClient.invalidateQueries(['Get All questionnaires'])
            queryClient.invalidateQueries(['SkillSets'])
            return data
        },
    })
}

export function useSignIn() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.signIn(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['LoggedIn'])
            queryClient.invalidateQueries(['useGetCVBuilderQuery'])
            queryClient.invalidateQueries(['LevelOneQuestionaire'])
            queryClient.invalidateQueries(['Get All questionnaires'])
            queryClient.invalidateQueries(['SkillSets'])
            return data
        },
    })
}
export function useForgottenPasswordQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.forgottenPassword(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['SentForgottenPassword'])
            return data
        },
    })
}

export function useResetPasswordQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.resetPassword(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['PasswordReset'])
            return data
        },
    })
}

export function useChangePasswordQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.changePassword(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['PasswordReset'])
            return data
        },
    })
}

export function useEmailReverificationQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.resendEmailVerification(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['ResendVerification'])
            return data
        },
    })
}

export function useRegistrationConfirmationQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.registrationConfirmation(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['RegistrationConfirmation'])
            return data
        },
    })
}

export function useResendOTPQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.resendOTP(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['OTP Sent'])
            return data
        },
    })
}

export function useGetJobsQuery(page, limit, type, filter = '', search = '', userLogin) {
    return useQuery(
        ['GetjobsQuery', page, type, filter, limit, search, userLogin],
        () => appApiCalls.getJobs(page, limit, filter, search, userLogin),
        {
            keepPreviousData: true,
            staleTime: 1,
        },
    )
}

export function useGetAppliedJobsQuery(page, limit, filter, search, enable) {
    return useQuery(
        ['useGetAppliedJobsQuery', page, limit, filter, search],
        () => appApiCalls.getAppliedJobs(page, limit, filter, search),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            enabled: enable,
        },
    )
}

export function useGetSaveJobsQuery(page, limit, filter, search, enable) {
    return useQuery(
        ['useGetSaveJobsQuery', page, limit, filter, search],
        () => appApiCalls.getSavedJobs(page, limit, filter, search),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            enabled: enable,
        },
    )
}

export function useGetjobDetailQuery(slug, userId) {
    return useQuery(['GetjobDetailQuery', slug, userId], () => appApiCalls.getJobDetail(slug, userId), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

//Getting a training detail
export function useGetTrainingDetailQuery(slug) {
    return useQuery(['useGetTrainingDetailQuery', slug], () => appApiCalls.getTrainingDetail(slug), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

// Getting available trainings
export function useGetTrainingProgramQuery(page, limit, type, filter = '', userLogin) {
    return useQuery(
        ['GetTrainingProgram', page, type, filter, limit, userLogin],
        () => appApiCalls.getTrainingProgram(page, limit, filter, userLogin),
        {
            keepPreviousData: true,
            staleTime: 1,
        },
    )
}

// Getting user Enrolled trainings
export function useGetEnrolledProgramQuery(page, limit, filter, enable) {
    return useQuery(
        ['useGetEnrolledProgramQuery', page, filter, limit],
        () => appApiCalls.getEnrolledTrainingProgram(page, limit, filter),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            enabled: enable,
        },
    )
}

export function useGetEnrolledPrograms(username) {
    return useQuery(
        ['getEnrolledPrograms'],
        () => appApiCalls.getApplicantEnrolledCourses(username),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

export function useReviewTrainingCourse(){
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.reviewTrainingCourse(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['ReviewTrainingCourse'])
            return data
        },
    })
}

export function useGetTrainingCourseRating(id) {
    return useQuery(
        ['getTrainingCourseRating'],
        () => appApiCalls.getTrainingCourseRating(id),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

// Getting Sponsored trainings
export function useGetSponsoredProgramQuery(page, enable) {
    return useQuery(
        ['useGetSponsoredProgramQuery', page],
        () => appApiCalls.getSponsoredTrainingProgram(page),
        {
            keepPreviousData: true,
            staleTime: Infinity,
            enabled: enable,
        },
    )
}

// Getting Recommended trainings
export function useGetRecommendedJobsQuery(enable) {
    return useQuery(['useGetRecommendedJobs Query'], () => appApiCalls.getRecommendedJobs(), {
        keepPreviousData: true,
        staleTime: Infinity,
        enabled: enable,
    })
}

export function useTrainingEnrollmentQuery(slug) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.trainingEnrollment(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useGetTrainingDetailQuery', slug])
            return data
        },
    })
}

// this query takes care of saving jobs and applying for jobs
export function useSaveOrApplyJobQuery(slug) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.applyJob(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['GetjobDetailQuery', slug])
            return data
        },
    })
} // this query takes care of saving jobs and applying for jobs
export function useSaveJobQuery(page, type, limit, filter = '', search = '') {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.saveJob(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Dashboard', 'recommendations'])
            queryClient.invalidateQueries(["Dashboard", "jobSaved"])
            queryClient.invalidateQueries(['GetjobsQuery', page, type, filter, limit, search])
            return data
        },
    })
}

export function useCheckQuestionnaireStatusQuery(id) {
    return useQuery(['checkQuizStatus'], () => appApiCalls.getQuestionnaireStatus(id), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useCheckSkillSetStatusQuery(id) {
    return useQuery(['checkQuizStatus'], () => appApiCalls.getQuestionnaireStatus(id), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetQuestionnaireAnswerQuery(id, title) {
    return useQuery([title, id], () => appApiCalls.getQuestionnaireAnswer(id, title), {
        enabled: !!title && !!id,
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetQuestionnaireAnswerByIdQuery(id) {

    return useQuery(["question answer by ID", id], () => appApiCalls.getUseQuestionnaireAnswerById(id), {
        enabled: !!id,
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetUserAssessmentByIdQuery(userId) {
    return useQuery([userId], () => appApiCalls.getUserAssessmentById(userId), {
        enabled: !!userId,
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetAllQuestionnairesQuery(page, limit) {
    return useQuery(['Get All questionnaires'], () => appApiCalls.getAllQuestionnaires(page, limit), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useSetSkillQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.skillSet(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['SkillSets'])
            return data
        },
    })
}

export function useGetAllSkillSetQuery(id) {
    return useQuery(
        ['SkillSets'],
        () => appApiCalls.getUserSkillSet(id), {
            keepPreviousData: true,
            staleTime: Infinity,
        })
}

export function useGetPersonalityTestQuery(from, to, category) {
    return useQuery(
        ['personalityTest'],
        () => appApiCalls.getPersonalityResult(from, to, category), {
            keepPreviousData: true,
            staleTime: Infinity
        })
}

export function useVerifyParkwayWallet() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.verifyParkwayWallet(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['VerifyParkwayWallet'])
            return data
        },
    })
}

export function useResendVerificationLink() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.resendVerificationLink(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['resendVerificationLink'])
            return data
        },
    })
}

export function useGetCultureFitTestQuery(from, to, category) {
    return useQuery(
        ['cultureFitTest'],
        () => appApiCalls.getCultureFitResult(from, to, category), {
            keepPreviousData: true,
            staleTime: Infinity
        })
}

export function useGetSoftSkillTestQuery(from, to, category) {
    return useQuery(['softSkillTest'], () => appApiCalls.getSoftSkillResult(from, to, category), {
        keepPreviousData: true,
        staleTime: Infinity
    })
}

export function useGetFAQS() {
    return useQuery(['Get FAQS '], () => appApiCalls.getFaqs(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetUserByUUID(UUID) {
    return useQuery(['User Profile'], () => appApiCalls.getUserByUUID(UUID), {
        enabled: !!UUID,
        keepPreviousData: false,
        staleTime: 0,
    })
}

export function useGetEmployerByUUID(UUID) {
    return useQuery(['Get Employer By UUID', UUID], () => appApiCalls.getEmployerByUUID(UUID), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetEmployerIndustryTypes() {
    return useQuery(['Get Employer Industry Types'], () => appApiCalls.getEmployerIndustryTypes(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetUserTier(userId) {
    return useQuery(['user tier'], () => appApiCalls.getUserTier(userId), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useUpdateUserQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateUser(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['User Profile'])
            return data
        },
    })
}

export function useUpdateEmployerQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateEmployer(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['updateEmployer'])
            return data
        },
    })
}

export function useUpdateEmployerDocQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateEmployerDoc(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['UpdateEmployerDoc'])
            return data
        },
    })
}

export function useGetAllStates() {
    return useQuery(['States'], () => appApiCalls.getStates(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetUserAssessments(userId) {
    return useQuery(['GetUserAssessments'], () => appApiCalls.getUserAssessments(userId), {
        keepPreviousData: false,
        staleTime: 1,
    })
}

// Transiding user to LMS
export function useSignUpUserLMS() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.signUpUserLMS(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['signUpUserLMS'])
            return data
        },
    })
}

export function useGetLGAById(id) {
    return useQuery(['LGA'], () => appApiCalls.getLGA(id), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetDisabilities() {
    return useQuery(['getDisabilities'], () => appApiCalls.getDisabilites(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetAllUsers(page, searchTerm, role, from, to) {
    return useQuery(
        ['getGetAllUsers'],
        () => appApiCalls.getAllUsers(page, searchTerm, role, from, to),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    )
}

export function useGetAllEmployers() {
    return useQuery(['getGetAllEmployers'], () => appApiCalls.getAllEmployers(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetUserMessages() {
    return useQuery(['getUserMessages'], () => appApiCalls.getUserMessages(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useGetInstitutions() {
    return useQuery(['Get Institutions'], () => appApiCalls.getInstitutions(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useTestUrls() {
    return useQuery(['Get Test Url'], () => appApiCalls.getTestUrls(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}

export function useUpdateMessageStatus() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateMessageStatus(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Update Message'])
            return data
        },
    })
}

export function useUpdateWalletNotification() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateWalletNotification(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['UpdateWalletNotification'])
            return data
        },
    })
}

export function useGetAppStats() {
    return useQuery(['Get App Stats'], () => appApiCalls.getAppStats(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })

}

export function useVerifyUserQuery(isUseNIN) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.verifyUser(isUseNIN, arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['verifyUser', isUseNIN])
            return data
        },
    })
}



export function useVerifyNinStatus() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.verifyNinStatus(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['VerifyNinStatus'])
            return data
        },
    })
}

export function useGetMatchingAndProfilingQuestions() {
    return useQuery(
        ['Get Matching and Profiling'],
        () => appApiCalls.matchingAndProfilingQuestions(),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    )
}

export function useGetTestimonials(page = 1, limit = 10) {
    return useQuery(
        ['Get Testimonials'],
        () => appApiCalls.getTestimonials(page, limit),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    )
}

export function useCreateTestimonial() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.uploadTestimonial(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['uploadTestimonial'])
            return data
        },
    })
}

export function useUpdateTestimonial(id) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateTestimonial(id, arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['uploadTestimonial'])
            return data
        },
    })
}

export function useDeleteTestimonial() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.deleteTestimonial(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['deleteTestimonial'])
            return data
        }
    })
}

export function useGetUserSharedProfile(profileSlug) {
    return useQuery(
        ['Get User Shared Profile'],
        () => appApiCalls.getUserSharedProfile(profileSlug),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    )
}

export function useGetApplicantBookings(uuid) {
    return useQuery(
        ['getApplicantBookings'],
        () => appApiCalls.getApplicantBooking(uuid),
        {
            keepPreviousData: true,
            staleTime: 1
        })
}

export function useGetNigerianCities() {
    return useQuery(
        ['getNigerianCities'],
        () => appApiCalls.getNigerianCities(),
        {
            keepPreviousData: true,
            staleTime: 1
        }
    )
}

//AdminDashboard Hook
// export function useGetDashQueries(asideNav) {
//
//     return useQueries(
//         asideNav.map((dashEndPoint) => {
//             return {
//                 queryKey: ["AdminDashboard", dashEndPoint.name],
//                 queryFn: () => appApiCalls.getDashStat( dashEndPoint.endPoint)
//             }
//         }, {
//             enabled: !!asideNav,
//             keepPreviousData: true,
//             staleTime: Infinity
//         }));
// }
export function useGetDashQueries(dashEndPoints) {
    return useQueries(
        {
            queries: dashEndPoints.map(
                (dashEndPoint) => {
                    return {
                        queryKey: ['Dashboard', dashEndPoint.name],
                        queryFn: () => appApiCalls.getDashStat(dashEndPoint.endPoint),
                    }
                },
                {
                    enabled: !!dashEndPoints,
                    keepPreviousData: true,
                    staleTime: Infinity,
                },
            ),
        })
}
