import { apiClient } from './applicant'
import axios from 'axios'

const signUp = async (formData) => {
  return await apiClient.post('users/signup', formData)
}
// This endpoint is allows the user to build a cv
const CVBuilder = async (formData) => {
  let headers = {
    'Content-Type': 'application/json',
  }
  if (formData.isFinal) {
    headers = {
      'Content-Type': 'multipart/form-data',
    }
  }
  return await apiClient.post('cv-builder', formData, { headers })
}
const generatePDF = async (formData) => {
  return await apiClient.post('cv-builder/pdf', formData, { responseType: 'blob' })
}

// this endpoint gets the user cv_builder data
const getCVBuilder = async () => {
  return await apiClient.get(`cv-builder/user`)
}

const getNasimUser = async (urlParams) => {
  const { token, id } = urlParams
  return await apiClient.get(`users/nassims-api?token=${token}&id=${id}`)
}

const nasimsSignUp = async (formData) => {
  return await apiClient.de('users/npower-signup', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const getLevelOneQuestions = async () => {
  return await apiClient.get(`/quiz/quiz-questions?quizType=Level One Questionnaire`)
}

const getQuestionnaires = async (url) => {
  return await apiClient.get(`/quiz/quiz-questions?quizType=${url}`)
}

const getQuestionnairesByUUId = async (uuid) => {
  return await apiClient.get(`/quiz/question/${uuid}`)
}

const levelOneQuizAnswers = async (data) => {
  const { answers, quizTitle } = data
  const formData = new FormData()
  formData.append('quizTitle', quizTitle)
  answers.forEach((answer, index) => {
    formData.append(`answers[${index}]`, answer)
  })
  //console.log(formData)
  return await apiClient.post('quiz-answers', formData)
}

const adminSignIn = async (formData) => {
  return await apiClient.post('administrators/signin', formData)
}

const profileFileUpload = async (formData) => {
  return await apiClient.post('users/profile/file-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const uploadUsers = async (data) => {

  return await apiClient.post('users/applicant/upload', data, )
}

const profileVideoIntro = async (formData) => {
  return await apiClient.post('users/intro-video/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//employer section start
const createStakeholder = async (formData) => {
  return await apiClient.post('stakeholders/signup', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//Sing up employer into LMS
const signUpEmployerLMS = async (formData) => {
  return await apiClient.post(`moodle/lms/course-creator`, formData)
}
//Sing up User into LMS
const signUpUserLMS = async (formData) => {
  return await apiClient.post(`moodle/lms/applicant`, formData)
}

const getStakeholderType = async () => {
  return await apiClient.get(`stakeholder-types`)
}

const stakeholderEmailVerification = async ({ token, id }) => {
  return await apiClient.put(`stakeholders/verify-email?token=${token}&id=${id}`)
}
const getStakeholder = async (uuid) => {
  return await apiClient.get(`stakeholders/${uuid}`)
}
const updateStakeholder = async (formData) => {
  return await apiClient.put(`stakeholders/${formData.id}`, formData)
}

const getTotalTestTakers = async () => {
  return await apiClient.get(`quiz-answers/all/test-takers?page=1&limit=10`)
}

const getTotalTestsPassed = async () => {
  return await apiClient.get(`users/all/total-pass`)
}

const getTotalTestsFailed = async () => {
  return await apiClient.get(`users/all/total-failure`)
}

const postJobStakeholder = async (data) => {
  return await apiClient.post(`stakeholder/jobs`, data)
}

const addRole = async (data) => {
  return await apiClient.patch(`stakeholders/add-role`, data)
}
const addSponsorRole = async (data) => {
  return await apiClient.patch(`stakeholders/become-sponsor`, data)
}

const createTrainingStakeholder = async (args) => {
  return await apiClient.post(`stakeholder/trainings`, args, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const sponsorTraining = async (args) => {
  return await apiClient.post(`sponsorships`, args, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//employer section end
const createAdmin = async (formData) => {
  const { data, header } = formData
  return await apiClient.post('administrators', data, header?.header)
}
const addGovernment = async (data) => {
  return await apiClient.post('stakeholders/gov/signup', data)
}

const postQuestion = async (uuid, data) => {
  if (!uuid) {
    return await apiClient.post('quiz/create-quiz-with-questions', data)
  }
  return await apiClient.post(`question/${uuid}/questions`, data)
}

const updateQuestion = async (id, data) => {
  return await apiClient.put(`question/${id}`, data)
}

const employerSignin = async (formData) => {
  return await apiClient.post('stakeholders/signin', formData)
}
const signIn = async (formData) => {
  return await apiClient.post('users/applicant-and-employer-signin', formData)
}

const resendEmailVerification = async (formData) => {
  return await apiClient.post('users/resend-email-verification', formData)
}

// This endpoint is responsible for training enrollment by a user
const trainingEnrollment = async (courseId) => {
  return await apiClient.post('users/training-enrollment', courseId)
}

// This endpoint is responsible for applying a job by a user
const applyJob = async (data) => {
  return await apiClient.post('apply-jobs/', data)
}

// This endpoint is responsible for saving or applying a job by a user
const saveJob = async (data) => {
  return await apiClient.post('apply-jobs/save-job', data)
}

const registrationConfirmation = async ({ token, id }) => {
  return await apiClient.put(`users/verify-email-otp?token=${token}&id=${id}`)
}

const resendOTP = async (formData) => {
  return await apiClient.post(`/users/resend-otp`, { uuid: formData })
}

const getQuestionnaireStatus = async (id) => {
  return await apiClient.get(`/users/quiz-taken/${id}`)
}

const getQuestionnaireAnswer = async (userId, title) => {
  return await apiClient.get(`/quiz-answers/?quizType=${title}`)
}
const getUseQuestionnaireAnswerById = async (userId) => {
  return await apiClient.get(`/quiz-results/${userId}`)
}

const getUserAssessmentById = async (userId) => {
  return await apiClient.get(`/quiz-answers/candidate-metrics/${userId}`)
}

const forgottenPassword = async (formData) => {
  return await apiClient.put(`/shared-api/forgot-password`, formData)
}

const resetPassword = async ({ data, token, id, role }) => {
  return await apiClient.put(
    `/shared-api/reset-password?token=${token}&id=${id}&role=${role}`,
    data,
  )
}

const changePassword = async ({ data, uuid, role }) => {
  return await apiClient.put(`shared-api/change-password/${uuid}/${role}`, data)
}

const getAllQuestionnaires = async (page = 1, limit = 10) => {
  return await apiClient.get(`quiz/quiz-title?page=${page}&limit=${limit}`)
}

const skillSet = async (formData) => {
  const { skills, header } = formData
  return await apiClient.post('skill-sets', { skills: skills }, header?.header)
}

const getUserSkillSet = async (id) => {
  return await apiClient.get(`skill-sets/${id}`)
}

const getAdminPermissions = async () => {
  return await apiClient.get('permissions')
}

const getEmployerPermissions = async () => {
  return await apiClient.get('stakeholder-roles/all/permissions')
}
const getAdminRoles = async () => {
  return await apiClient.get('admin-roles')
}

const getEmployerTeam = async () => {
  return await apiClient.get('stakeholder-teams/teams')
}
const createEmployerTeam = async (formData) => {
  return await apiClient.post('stakeholder-teams', formData)
}

const getAllAdmins = async (search = '', roles = [], permissions = [], limit = 10) => {
  let url = `administrators?limit=${limit}`
  url += !!search ? `&search=${search}` : ''
  url += roles?.length > 0 ? `&roles=${JSON.stringify(roles)}` : ''
  url += permissions?.length > 0 ? `&permissions=${JSON.stringify(permissions)}` : ''

  return await apiClient.get(url)
}

const getAttendance = async (search = '', limit = 10, page = 1, date = new Date()) => {
  let url = `attendances?limit=${limit}&page=${page}`
  url += !!date ? `&date=${date}` : `&date=${new Date().toISOString().slice(0, 10)}`
  url += !!search ? `&search=${search}` : ''
  return await apiClient.get(url)
}

const getLMSReport = async (stateOfOrigin = '', limit = 10, page = 1) => {
  let url = `https://api.worknation.ng/api/v1/moodle/lms/courses/reports?limit=${limit}&page=${page}`
  url += !!stateOfOrigin ? `&stateOfOrigin=${stateOfOrigin}` : ''
  return await axios.get(url)
}

const getApplicantEnrolledCourses = async (username) => {
  return await apiClient.post(`/moodle/lms/enrolled-courses`, { username })
}

const getNewLMSCourse = async () => {
  return await apiClient('/moodle/lms/courses')
}
const getNewLMSCourseByID = async (id = 16) => {
  return await apiClient(`/moodle/lms/${id}/modules`)
}

const getNewLMSCourseUserByID = async (id = 16) => {
  return await apiClient(`/moodle/lms/${id}/modules`)
}

const getEmployerRoles = async () => {
  return await apiClient.get('stakeholder-roles')
}

const getAdminByUuid = async (uuid) => {
  return await apiClient.get(`administrators/${uuid}`)
}

const getFaqs = async () => {
  return await apiClient.get('faqs')
}

const getUserByUUID = async (UUID) => {
  return await apiClient.get(`users/user/${UUID}`)
}

const getEmployerByUUID = async (UUID) => {
  return await apiClient.get(`stakeholders/${UUID}`)
}

const getUserTier = async (usrId) => {
  return await apiClient.get(`quiz-answers/user/tier/${usrId}`)
}

const getStates = async () => {
  return await apiClient.get('states-and-locals/states')
}

const getLGA = async (id) => {
  return await apiClient.get(`states-and-locals/states/${id}`)
}

const getDisabilites = async () => {
  return await apiClient.get('disabilities')
}

const getJobPosted = async () => {
  return await apiClient.get('stakeholder/jobs/user-created-jobs?limit=5&page=1')
}

const getJobStat = async () => {
  return await apiClient.get('shared-api/employer/dashboard-stats')
}

const getUsers = async () => {
  return await apiClient.get('users/all?page=1&limit=10')
}

const updateUser = async (formData) => {
  return await apiClient.patch('users/update-profile', formData)
}

const updateEmployer = async (formData) => {
  return await apiClient.patch('stakeholders/update-account', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
const updateEmployerDoc = async (formData) => {
  return await apiClient.patch('stakeholders/documents-uploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//getting user Enrolled Trainings
const getEnrolledTrainingProgram = async (page, limit, filter) => {
  return await apiClient.get(`users/training-enrollment?page=${page}&limit=${limit}${filter}`)
}

// getting Sponsored Trainings
const getSponsoredTrainingProgram = async (page) => {
  return await apiClient.get(`stakeholder/trainings?page=${page}&limit=10`)
}

// Getting all Trainings
const getTrainingProgram = async (page = 1, limit = 10, filter = '', userLogin) => {
  return await apiClient.get(
    `moodle/lms/courses?page=${page}&limit=${limit}`,
    //`moodle/lms/courses`,
    //`stakeholder/trainings?page=${page}&limit=${limit}${filter}${userLogin ? userLogin : ''}`,
  )
}

// Getting recommended Trainings
const getRecommendedJobs = async () => {
  return await apiClient.get(`stakeholder/jobs/user/recommendation-jobs`)
}

const getJobs = async (page = 1, limit = 10, filter = '', search = '', userLogin) => {
  return await apiClient.get(
    `stakeholder/jobs?page=${page}${search}&limit=${limit}${filter}${userLogin ? userLogin : ''}`,
  )
}

const getAppliedJobs = async (page, limit, filter, search) => {
  return await apiClient.get(
    `apply-jobs/jobs/applied-and-saved?page=${page}&limit=${limit}${search}&isSave=false${filter}`,
  )
}

const getSavedJobs = async (page, limit, filter, search) => {
  return await apiClient.get(
    `apply-jobs/jobs/applied-and-saved?page=${page}&limit=${limit}${search}&isSave=true${filter}`,
  )
}

const getJobDetail = async (slug, userId) => {
  if (userId) {
    return await apiClient.get(`stakeholder/jobs/slug/${slug}?userId=${userId}`)
  }

  return await apiClient.get(`stakeholder/jobs/slug/${slug}`)
}

const getTrainingDetail = async (slug) => {
  //return await apiClient.get(`stakeholder/trainings/slug/${slug}`)
  return await apiClient.get(`moodle/lms/course/${slug}`)
}

const getStakeHolderTrainingProgram = async (page) => {
  return await apiClient.get(`stakeholder/trainings/stakeholder-trainings?page=${page}&limit=10`)
}

const getAllUsers = async (
  page = 1,
  limit = 10,
  searchTerm,
  role,
  from,
  to,
  stateOfOrigin,
  stateOfResidence,
  careerLevel,
  jobPreference,
  employmentType = [],
  highestLevelOfEducation,
  schoolAttended,
) => {
  let url = `users/all?page=${page}&limit=${limit}`
  url += !!searchTerm ? `&search=${searchTerm}` : ''
  url += !!role ? `&role=${role}` : ''
  url += !!stateOfOrigin ? `&stateOfOrigin=${stateOfOrigin}` : ''
  url += !!stateOfResidence ? `&stateOfResidence=${stateOfResidence}` : ''
  url += !!careerLevel ? `&careerLevel=${careerLevel}` : ''
  url += !!jobPreference ? `&jobPreference=${jobPreference}` : ''
  url += !!schoolAttended ? `&schoolAttended=${schoolAttended}` : ''
  url += employmentType?.length > 0 ? `&employmentType=${JSON.stringify(employmentType)}` : ''
  url += !!highestLevelOfEducation ? `&highestLevelOfEducation=${highestLevelOfEducation}` : ''
  url += !!from ? `&from=${from}` : ''
  url += !!to ? `&to=${to}` : ''

  return await apiClient.get(url)
}

const getAllEmployers = async (
  limit = '',
  page = '',
  roles = '',
  email = '',
  name = '',
  organizationStatus = '',
  institutionsType = '',
  tin_number = '',
) => {
  let url = `stakeholders?limit=${!!limit ? limit : 10}`
  url += !!page ? `&page=${page}` : `&page=1`
  url += !!email ? `&email=${email}` : ''
  url += !!roles ? `&roles=${roles}` : ''
  url += !!name ? `&name=${name}` : ''
  url += !!organizationStatus ? `&organizationStatus=${organizationStatus}` : ''
  url += !!institutionsType ? `&institutionsType=${institutionsType}` : ''
  url += !!tin_number ? `&tin_number=${tin_number}` : ''

  return await apiClient.get(url)
}

const getJobReports = async (page, limit) => {
  return await apiClient.get(`recruitment/applications/jobs/reporting?page=${page}&limit=${limit}`)
}

const getSkillSetReports = async (
  page,
  limit,
  quizType,
  from = '0',
  to = '100',
  stateOfOrigin = '',
) => {
  let qType = quizType !== ' ' ? '&quizType=' + quizType : ''
  return await apiClient.get(
    `quiz-results?page=${page}&limit=${limit}${qType}&fromPercentage=${from}&toPercentage=${to}&stateOfOrigin=${stateOfOrigin}`,
  )
}

const getPersonalityResult = async (from = 0, to = 100, category) => {
  let url = `quiz-results/personality-results?from=${from}&to=${to}`
  if (category?.length > 0) {
    url += `&category=${category}`
  }
  return await apiClient.get(url)
}
const verifyParkwayWallet = async (data) => {
  return await apiClient.post(`users/wallet/account-number`, data)
}

const resendVerificationLink = async (data) => {
  return await apiClient.post(`users/resend-email-verification`, data)
}

const getCultureFitResult = async (from = 0, to = 100, category) => {
  let url = `quiz-results/culture-fit-results?from=${from}&to=${to}`
  if (category?.length > 0) {
    url += `&category=${category}`
  }
  return await apiClient.get(url)
}

const getSoftSkillResult = async (from = 0, to = 100, category) => {
  let url = `quiz-results/soft-skills-results?from=${from}&to=${to}`
  if (category?.length > 0) {
    url += `&category=${category}`
  }
  return await apiClient.get(url)
}

//AdminDashboard Endpoint Calls
const getDashStat = async (dashEndPoint) => {
  return await apiClient.get(`${dashEndPoint}`)
}

//Verify Tin
/*
 *
 * */
const getTin = async (tin) => {
  return await apiClient.get(`stakeholders/tin/verify-tin?tin_number=${tin}`)
}

// getting programs posted by an employer
const getProgramsByEmployer = async (page) => {
  return await apiClient.get(`stakeholder/trainings/stakeholder-trainings?page=${page}&limit=10`)
}

// getting jobs posted by an employer
const getJobsPostedByEmployer = async (filter) => {
  return await apiClient.get(`stakeholder/jobs/user-created-jobs?${filter}`)
}
const getJobPipeline = async (filter) => {
  return await apiClient.get(`job-project-pipe-lines?${filter}`)
}

const getAllUsersInPipeline = async (pipelineId) => {
  return await apiClient.get(`users/pipeline/${pipelineId}`)
}

// getting program trainees for a single job
const getProgramTrainees = async (programId) => {
  return await apiClient.get(`users/course/${programId}`)
}

// getting jobs Applicants for a single job
const getJobApplicants = async (
  jobId,
  page,
  limit,
  fitLevel = '',
  assessment = '',
  interviewStatus = '',
) => {
  return await apiClient.get(
    `stakeholder/jobs/job/${jobId}?limit=${limit}&page=${page}${fitLevel}${assessment}${
      interviewStatus && '&status=' + interviewStatus
    }`,
  )
}

const updateApplicationStatus = async (data) => {
  return await apiClient.patch(`push-notifications/messages/`, data)
}

const updateJobStatus = async (jobId, data) => {
  return await apiClient.patch(`stakeholder/jobs/status/${jobId}`, data)
}

const getEmployerIndustryTypes = async () => {
  return await apiClient.get('stakeholder-types/employer/industries')
}

const getUserMessages = async () => {
  return await apiClient.get('push-notifications/messages')
}

const getInstitutions = async () => {
  return await apiClient.get('stakeholder-types/user/universities')
}

const getTestUrls = async () => {
  return await apiClient.get('/quiz/quiz-title')
}

const updateMessageStatus = async (messageId) => {
  return await apiClient.patch(`push-notifications/messages/${messageId}`)
}

const updateWalletNotification = async (data) => {
  return await apiClient.post(`users/parkway-terms/`, data)
}

const getAppStats = async () => {
  return await apiClient.get('shared-api/app-stats')
}

const verifyUser = async (isUseNIN, data) => {
  let url = 'users/verify-nin'
  if (!isUseNIN) {
    url = 'users/verify-bvn'
  }
  return await apiClient.post(url, data)
}

const verifyNinStatus = async (data) => {
  return await apiClient.post(`users/verify-nin-status`, data)
}
const matchingAndProfilingQuestions = async () => {
  return await apiClient.get(`role-fit-analyzer/questions`)
}

const fetchUserCv = async () => {
  return await apiClient.get('job-cv/uploads')
}
const uploadUserCV = async (formData) => {
  return await apiClient.post('job-cv/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const deleteUserCV = async (id) => {
  return await apiClient.delete(`job-cv/uploads/${id}`)
}
const getTestimonials = async (page = 1, limit = 10) => {
  return await apiClient.get(`testimonials?page=${page}&limit=${limit}`)
}

const uploadTestimonial = async (args) => {
  return await apiClient.post(`testimonials`, args, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const updateTestimonial = async (id, args) => {
  return await apiClient.patch(`testimonials/${id}`, args, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const scheduleInterview = async (args) => {
  return await apiClient.post(`recruitment/applications/shortlist`, args)
}
const updateJobApplicationStatus = async (data) => {
  const { jobId, userId, jobStatus, applicationId } = data
  return await apiClient.patch(
    `apply-jobs/toggle-application-status/${jobId}/${userId}/${applicationId}?status=${jobStatus}`,
  )
}

const deleteTestimonial = async (id) => {
  return await apiClient.delete(`testimonials/${id}`)
}

const applyForScholarship = async (arg) => {
  return await apiClient.post(`sponsorships/apply`, arg, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const getAllScholarships = async (page = 1, limit = 10, search = '') => {
  return await apiClient.get(`sponsorships?page=${page}&limit=${limit}&search=${search}`)
}
const getAllSponsoredCourse = async (page = 1, limit = 10, search = '') => {
  return await apiClient.get(
    `sponsorships/applicant-dashboard?page=${page}&limit=${limit}&search=${search}`,
  )
}
const getBeneficiariesBySponsorship = async (sponsorshipId, page = 1) => {
  return await apiClient.get(`sponsorships/applicants/${sponsorshipId}?page=${page}`)
}
const getUserSharedProfile = async (profileSlug) => {
  return await apiClient.get(`users/profile/shared-profile/${profileSlug}`)
}
const getSponsorshipGraphData = async (sponsorId) => {
  return await apiClient.get(`sponsorships/graph-data/${sponsorId}`)
}

const getEmployerCBSRecord = async () => {
  return await apiClient.get(`cbs/sponsor/cbs`)
}

const getEmployerInvoice = async (email, phoneNumber, page, limit) => {
  return await apiClient.get(
    `cbs/invoices?email=${email}&phoneNumber=${phoneNumber}&page=${page}&limit=${limit}`,
  )
}

const generateCBSInvoice = async (data) => {
  return await apiClient.post(`cbs/create-invoice`, data)
}

const getAuditTrail = async (token, page = 1, limit = 10) => {
  return await apiClient.get(`audit?token=${token}&page=${page}&limit=${limit}`)
}

const getInterviewShortlist = async (limit = 10) => {
  return await apiClient.get(`recruitment/applications/shortlist?limit=${limit}`)
}

const getApplicantBooking = async () => {
  return await apiClient.get(`recruitment/applications/applicant/shortlisted/`)
}
const createEmployerRole = async (formData) => {
  const { data, header } = formData
  return await apiClient.post('stakeholder-roles', data, header?.header)
}

const getUserAssessments = async (userId) => {
  return await apiClient.get(`quiz-results/applicant/${userId}/score`)
}

const approveSponsorshipForSponsors = async (data) => {
  const sponsorshipId = data.sponsorshipId
  delete data.sponsorshipId
  return await apiClient.put(`sponsorships/approve/${sponsorshipId}`, data)
}

const beneficiariesSponsorshipApproval = async (data) => {
  const sponsorshipId = data.sponsorshipId
  const userId = data.userId
  delete data.sponsorshipId
  delete data.userId
  return await apiClient.patch(`sponsorships/${userId}/${sponsorshipId}/stakeholders`, data)
}

const adminSponsorshipApproval = async (data) => {
  const sponsorshipId = data.sponsorshipId
  const userId = data.userId
  delete data.sponsorshipId
  delete data.userId
  return await apiClient.patch(`sponsorships/${userId}/${sponsorshipId}/administrators`, data)
}

const getCandidatePipeline = async () => {
  return await apiClient.get(`stakeholder/jobs/projects/candidate-search`)
}

const getMoveToPipeline = async (filter) => {
  return await apiClient.get(`stakeholder/jobs/projects/candidate-search${filter}`)
}

const getNigerianCities = async () => {
  return await apiClient.get(`shared-api/nigeria/cities`)
}

const postAJob = async (data) => {
  return await apiClient.post('/stakeholder/jobs', data)
}

const getJobScreeningQuestion = async () => {
  return await apiClient('/job-screening-questions')
}

const createJobProject = async (args) => {
  return await apiClient.post(`job-project-pipe-lines`, args)
}

const getSponsoredCourseApplicants = async (sponsorId) => {
  return await apiClient.get(`sponsorships/applicants/${sponsorId}`) // ?isApprove=true
}

const getActiveChatUser = async (email) => {
  try {
    return await apiClient(`users?email=${email}`, {
      baseURL: 'https://worknation-chat-development.ogtlprojects.com',
    })
  } catch (error) {
    console.log(error)
  }
}

const getAllJobTitles = async () => {
  return await apiClient.get(`skill-sets/applicants/career-profile`)
}

const initializeChatUser = async (data) => {
  try {
    return await apiClient.post(
      `https://worknation-chat-development.ogtlprojects.com/users/initialize`,
      data,
    )
  } catch (error) {
    console.log(error)
  }
}

const reviewTrainingCourse = async (data) => {
  return await apiClient.post(`course-reviews`, data)
}

const sendEmail = async (data) => {
  try {
    return await apiClient.post(`email-interactions/sent`, data)
  } catch (error) {
    console.log(error)
  }
}

const getAllUsersEmails = async (userType) => {
  try {
    return await apiClient.get(
      userType === 'User'
        ? `email-interactions/user/mailbox`
        : `email-interactions/employer/mailbox`,
    )
  } catch (error) {
    console.log(error)
  }
}
const getAnEmail = async (data) => {
  console.log(data, 'sent')
  try {
    return await apiClient.get(`email-interactions/${data.id}`)
  } catch (error) {
    console.log(error)
  }
}

const replyToAnEmail = async (data) => {
  try {
    return await apiClient.post(`email-interactions/reply`, data)
  } catch (error) {
    console.log(error)
  }
}
const deleteAnEmail = async (data) => {
  try {
    return await apiClient.delete(`email-interactions/${data?.id}`)
  } catch (error) {
    console.log(error)
  }
}

const getTrainingCourseRating = async (id) => {
  return await apiClient.get(`course-reviews`)
}

const panAfricanSignUp = async (data) => {
  return await apiClient.post(`users/pan-african/signup`, data)
}

const getStakeholderEmailStats = async () => {
  return await apiClient.get(`email-interactions/statistics/stakeholder`)
}

const appApiCalls = {
  getBeneficiariesBySponsorship,
  getSponsorshipGraphData,
  beneficiariesSponsorshipApproval,
  adminSponsorshipApproval,
  approveSponsorshipForSponsors,
  employerSignin,
  createEmployerTeam,
  getEmployerTeam,
  getEmployerRoles,
  getEmployerPermissions,
  createEmployerRole,
  generateCBSInvoice,
  getEmployerCBSRecord,
  getEmployerInvoice,
  generatePDF,
  trainingEnrollment,
  profileFileUpload,
  applyJob,
  saveJob,
  getTotalTestTakers,
  getTrainingProgram,
  getRecommendedJobs,
  getEnrolledTrainingProgram,
  getSponsoredTrainingProgram,
  getJobs,
  getAppliedJobs,
  getSavedJobs,
  getJobDetail,
  getTrainingDetail,
  adminSignIn,
  createAdmin,
  postQuestion,
  updateQuestion,
  getAdminPermissions,
  getAdminRoles,
  updateApplicationStatus,
  updateJobStatus,
  getAllAdmins,
  getAttendance,
  addGovernment,
  getAdminByUuid,
  getEmployerByUUID,
  getFaqs,
  signIn,
  signUp,
  CVBuilder,
  getCVBuilder,
  getNasimUser,
  nasimsSignUp,
  getLevelOneQuestions,
  getQuestionnaires,
  getQuestionnairesByUUId,
  levelOneQuizAnswers,
  resendEmailVerification,
  registrationConfirmation,
  stakeholderEmailVerification,
  resendOTP,
  getQuestionnaireStatus,
  getUserSkillSet,
  getQuestionnaireAnswer,
  getUseQuestionnaireAnswerById,
  getUserAssessmentById,
  forgottenPassword,
  resetPassword,
  changePassword,
  getAllQuestionnaires,
  skillSet,
  getUserByUUID,
  getUserTier,
  updateUser,
  updateEmployer,
  updateEmployerDoc,
  getStates,
  getLGA,
  getDisabilites,
  createStakeholder,
  signUpEmployerLMS,
  signUpUserLMS,
  getStakeholderType,
  updateStakeholder,
  getStakeholder,
  getJobPosted,
  getJobStat,
  getUsers,
  postJobStakeholder,
  sponsorTraining,
  getJobsPostedByEmployer,
  getProgramsByEmployer,
  getJobApplicants,
  getProgramTrainees,
  getTotalTestsPassed,
  getTotalTestsFailed,
  getAllUsers,
  createTrainingStakeholder,
  getAllEmployers,
  getEmployerIndustryTypes,
  getJobReports,
  getSkillSetReports,
  getPersonalityResult,
  verifyParkwayWallet,
  resendVerificationLink,
  getCultureFitResult,
  getSoftSkillResult,
  getDashStat,
  getTin,
  addRole,
  addSponsorRole,
  getUserMessages,
  getInstitutions,
  getStakeHolderTrainingProgram,
  getTestUrls,
  updateMessageStatus,
  updateWalletNotification,
  getAppStats,
  verifyUser,
  verifyNinStatus,
  matchingAndProfilingQuestions,
  uploadUserCV,
  fetchUserCv,
  deleteUserCV,
  getTestimonials,
  uploadTestimonial,
  deleteTestimonial,
  updateTestimonial,
  scheduleInterview,
  applyForScholarship,
  getAllScholarships,
  getUserSharedProfile,
  getAuditTrail,
  getInterviewShortlist,
  getApplicantBooking,
  updateJobApplicationStatus,
  getUserAssessments,
  getActiveChatUser,
  initializeChatUser,
  getNigerianCities,
  getCandidatePipeline,
  getMoveToPipeline,
  createJobProject,
  getSponsoredCourseApplicants,
  getLMSReport,
  getAllUsersInPipeline,
  getJobPipeline,
  getAllSponsoredCourse,
  postAJob,
  getJobScreeningQuestion,
  getNewLMSCourse,
  getNewLMSCourseByID,
  getNewLMSCourseUserByID,
  getAllJobTitles,
  getApplicantEnrolledCourses,
  reviewTrainingCourse,
  sendEmail,
  getAllUsersEmails,
  getAnEmail,
  replyToAnEmail,
  deleteAnEmail,
  getTrainingCourseRating,
  panAfricanSignUp,
  getStakeholderEmailStats,
  profileVideoIntro,
  uploadUsers
}

export default appApiCalls
