import React, {useEffect, useState} from 'react'
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import Sparkles from '../../../assets/icons/Sparkles.svg'
import TrophyIcon from '../../../assets/icons/noto_trophy.svg'
import UserStatCard from "../../../components/User/UserStatCard";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {useGetAllSkillSetQuery, useGetDashQueries} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import ErrorReports from "../../../components/ErrorReports";
import Recommendations from "../../../components/User/dashboard/Recommendations";
import ProfileProgress from "../../../components/User/ProfileProgress";
import ProfilePic from "../../../components/User/ProfilePic";
import NinVerificationModal from "../../../components/modals/NinVerificationModal";
import CVBuilderAnnouncement from "../../../components/User/dashboard/CVBuilderAnnouncement";
import MandatorySkills from "../../../components/User/dashboard/MandatorySkills";
import SkillSets from "../../../components/User/dashboard/SkillSets";
import ParkwayWalletAd from "../../../components/User/dashboard/ParkwayWalletAd";
import CareerBox from "../../../components/User/dashboard/CareerBox";

function areAllMandatoryTestTaken(array) {
    for (const item of array) {
        if (!item.status) {
            return false;
        }
    }
    return true;
}

const MainPage = () => {
    const [visible, setVisible] = useState(true)
    const {userId: id, user} = useAppContext()
    const [isMandatoryTestTaken, setIsMandatoryTestTaken] = useState(false)
    const {data: userSkillSetsData, error: userSkillSetsError, isError: isUserSkillSetsError} = useGetAllSkillSetQuery(id)

    const dashEndPoints = [
        {
            name: "jobsApplied",
            endPoint: `apply-jobs/jobs/applied-and-saved?page=1&limit=10&isSave=false`,
        },
        {
            name: "jobSaved",
            endPoint: `apply-jobs/jobs/applied-and-saved?page=1&limit=10&isSave=true`,
        },
        {
            name: "testTaken",
            endPoint: `quiz-answers/quiz/counts`,
        },
        {
            name: "recommendations",
            endPoint: `stakeholder/jobs/user/recommendation-jobs`
        },

        {
            name: "UserProfileData",
            endPoint: `users/user/${user?.data?.userUuid}`
        },

        {
            name: "userTier",
            endPoint: `quiz-answers/user/tier/${id}`
        },
        {
            name: 'mandatorySkills',
            endPoint: 'quiz-results/compulsory/test-status'
        }

    ]

    const dashData = useGetDashQueries(dashEndPoints)
    const isLoading = dashData.some(query => query.isLoading)
    const isSuccess = dashData.some(query => query.isSuccess)
    const isError = dashData.some(query => query.isError)

    const jobsApplied = dashData[0]?.data?.data?.data?.meta?.totalItems
    const jobsSaved = dashData[1]?.data?.data?.data?.meta?.totalItems
    const testTaken = dashData[2]?.data?.data?.data?.count
    const recommendations = dashData[3]?.data?.data?.data
    const userProfileData = dashData[4]?.data?.data?.data
    const userTier = dashData[5]?.data?.data?.data?.tier
    const mandatorySkills = dashData[6]?.data?.data?.data
    const userCard = [testTaken, jobsApplied, jobsSaved]
    const titles = ["Test Taken", "Jobs Applied", "Jobs Saved"]
    const [careerBoxVisible, setCareerBoxVisible] = useState(true)

    useEffect(() => {
        if (isSuccess) {
            setIsMandatoryTestTaken(areAllMandatoryTestTaken(mandatorySkills))
        }
    }, [mandatorySkills]);


    if (isLoading) {
        return <ShowLoading/>
    }

    if (isError) {
        return <ErrorReports error={dashData[dashData.length - 1]?.error}/>
    }

    return <>

        {
            (user?.data?.role !== 'NASIMS' && !user?.data?.nin) &&
            <NinVerificationModal visible={visible} setVisible={setVisible}/>
        }
        <CContainer className="d-flex flex-column mb-5">
            {isUserSkillSetsError && <ErrorReports error={userSkillSetsError} />}
            <CRow className="user-dashboard-banner text-white">
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center p-3">
                    <div className="d-flex w-100 flex-row align-items-center">
                        <div className="pe-2">
                            <ProfilePic passportUrl={userProfileData?.user?.profile}/>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <h5 className="m-0 fw-bold">{`Welcome, ${user?.data?.firstName} ${user?.data?.lastName}`}</h5>
                            <small className="m-0">Hope you are doing great today?</small>
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end">
                        <div className="w-50">
                            <ProfileProgress text={"Profile Strength"} progress={userProfileData?.profileCompleted}/>
                        </div>
                        <div className="position-relative">

                            <div
                                className="d-flex flex-row justify-content-center align-items-center ms-4 px-3 py-2 tier">
                                <img src={TrophyIcon} alt="Trophy"/>
                                <p className="m-0 ps-2">{userTier}</p>
                            </div>
                            <CImage className="position-absolute sparkles" src={Sparkles} alt="Sparkles"/>
                        </div>
                    </div>
                </div>
            </CRow>

            <CRow className="d-flex flex-row flex-wrap mt-3">
                <CCol className="d-flex flex-column flex-wrap gap-3 mt-2 " sm={12} lg={8}>
                    <CCol sm={12}>
                        <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 3}} className="g-4">
                            {
                                userCard?.map((item, id) => {
                                    return <CCol key={id}>
                                        <UserStatCard data={item} title={titles[id]} index={id + 1}/>
                                    </CCol>
                                })
                            }
                        </CRow>
                    </CCol>
                    <CCol sm={12} className="mt-3">
                        <Recommendations recommendations={recommendations}/>
                    </CCol>
                </CCol>
                <CCol className="mt-2 d-flex flex-column flex-wrap bg-white" sm={12} lg={4}>
                    <CVBuilderAnnouncement/>
                    <ParkwayWalletAd />
                    <div className={'px-4'}>
                        <h5>Mandatory Skills Test</h5>
                        <p className="text-wn-10 mb-3">
                            Note that it is mandatory for you to take the below soft skill assessment and upload your profile picture before
                            you can proceed to your skill set tests
                        </p>
                    </div>

                    <MandatorySkills mandatorySkills={mandatorySkills}/>

                    <CareerBox visible={careerBoxVisible} setVisible={setCareerBoxVisible} />

                    <h5>Skill Set Test</h5>
                    <div className="mt-1 mb-5">
                        {!careerBoxVisible && <SkillSets
                            isMandatoryTestTaken={isMandatoryTestTaken}
                            skillSets={userSkillSetsData?.data?.data?.skills}
                        />}
                    </div>
                </CCol>
            </CRow>
        </CContainer>

    </>
}

export default MainPage
