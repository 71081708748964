import * as yup from 'yup';

export const CourseReviewSchema = yup.object().shape({
    rating: yup.number()
        .typeError('Rating must be a valid number between 1 and 5')
        .required('Rating is required')
        .min(1, 'Rating must be at least 1')
        .max(5, 'Rating cannot be more than 5'),
    comment: yup.string(),
});
