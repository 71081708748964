import { useContext, useEffect, useState } from 'react'
import { MessageContext } from 'src/context/messaging/MessageProvider'

const useSocket = () => {
  const context = useContext(MessageContext)
  const socket = context?.socket

  const [isConnected, setIsConnected] = useState(!!socket?.connected)

  useEffect(() => {
    function onConnect() {
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    if (socket) {
      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)

      return () => {
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
      }
    }
  }, [socket])

  return { isConnected, socket }
}

export default useSocket
