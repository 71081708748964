import React, {useEffect, useState} from 'react'
import training_bg from "../../assets/images/training_bg.png";
import ShowLoading from "../ShowLoading";
import {fail_notify, success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useNavigate, useParams} from "react-router-dom";
import {useGetTrainingCourseRating, useTrainingEnrollmentQuery} from "../../hooks/user/userApiHooks";
import DOMPurify from "dompurify";
import {CLoadingButton} from "@coreui/react-pro";
import {userEnrolled} from "./TrainingErollmentUtility";
import {FaStar} from "react-icons/fa";

function TrainingDetailSection({trainingDetail}) {

    const {isLoading, error, isError, data} = trainingDetail
    const training = data?.data?.data

    const [visible, setVisible] = useState(false)
    const [courseID, setCourseID] = useState({})
    const [rating, setRating] = useState(null)
    const {slug} = useParams();
    const {userId, storeUser, user,} = useAppContext()
    const navigate = useNavigate()

    const {
        isError: TEIsError,
        isSuccess: TEIsSuccess,
        isLoading: TEIsLoading,
        error: TEerror,
        data: TEdata,
        // mutate
    } = useTrainingEnrollmentQuery(slug)
    const {data: courseRating} = useGetTrainingCourseRating(slug)

    useEffect(() => {
        const averageRating = courseRating?.data?.data
            ?.filter(({courseId}) => courseId.toString() === slug)
            ?.reduce((acc, obj, index, array) => {
                acc += obj.rating;
                if (index === array.length - 1) {
                    return acc / array.length;
                }
                return acc;
        }, 0);
        setRating(averageRating)
    }, [courseRating, slug])

    useEffect(() => {
        if (TEIsSuccess) {
            success_notify('Enrolled successfully')
        }
        if (TEIsError) {
            fail_notify(TEerror?.response?.data?.message)
        }
        storeUser(user)
    }, [TEIsError, TEIsSuccess, TEIsLoading, TEerror, TEdata])

    function removeWord(sentence, wordToRemove) {
        const newWord = wordToRemove + ': '
        const regex = new RegExp(`\\b${newWord}\\b`, 'gi');
        if (sentence === wordToRemove) {
            return
        }
        return sentence.replace(regex, '');
    }

    const onSubmit = (trainingId) => {
        if (!user?.accessToken) {
            localStorage.setItem("redirectUrl", "/training-detail/" + slug);
            navigate(`/login`, {
                replace: true,
            })
            return
        }
        navigate(`/transit/lms`, {
            replace: true,
        })
        setCourseID({
            courseId: trainingId
        })
        setVisible(true)
    }

    if (isError) {
        fail_notify(error?.response?.data?.message)
    }

    return (
        <>
            {
                isLoading ? <ShowLoading/> :
                <div className="position-relative">
                    <div className=''>
                        <img src={training_bg} className=" img-h-25 position-absolute" alt="Training"/>
                        <div className="px-3 px-md-5 mt-15 position-relative" style={{top: "0", zIndex: '3'}}>
                            <div className="job-detail-div bg-light-green h-100 py-3">
                                <div className="px-2 text-center">
                                    <div className="px-2 pt-3">
                                        <h4 className="fw-bold">{training?.shortname}</h4>
                                        {/*<p className="sub-body py-2 text-black">{training?.stakeholder?.name}</p>*/}
                                        <p className="sub-body py-2 text-black">{removeWord(training?.fullname, training?.shortname)}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    {(!(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) ? <>
                                            {!userEnrolled(training, userId) ?
                                                <CLoadingButton
                                                    className="text-white fw-bold my-1"
                                                    disabled={TEIsLoading}
                                                    loading={TEIsLoading}
                                                    onClick={() => onSubmit(training?.id)}

                                                >
                                                    <small>ENROLL FOR TRAINING</small></CLoadingButton> :
                                                <small className="text-primary">You are enrolled</small>}
                                        </> :
                                        <>
                                            {user?.data?.isSponsor &&
                                                <CLoadingButton
                                                    className="text-white fw-bold my-1" disabled={TEIsLoading}
                                                    loading={TEIsLoading}
                                                    onClick={() => {
                                                        navigate(`/employer/sponsor-course/${training?.id}`, {replace: false})

                                                    }}
                                                >
                                                    <small>SPONSOR TRAINING</small>
                                                </CLoadingButton>}
                                        </>
                                    }
                                </div>
                                <br/>
                                <div className="mb-4">
                                    <h5 className="fw-bold text-primary">Training Description</h5>
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(training?.summary)}}></div>
                                </div>
                                <div>
                                    <h5 className="fw-bold text-primary mt-5">Rating (Avg. {rating})</h5>
                                    {courseRating?.data?.data?.filter(({courseId}) => courseId.toString() === slug)?.map(({comment, rating}, index) => {
                                        return <div key={index}>
                                            <p className="mt-3 mb-1">
                                                {[1, 2, 3, 4, 5].map((star, index) => (
                                                    <FaStar
                                                        size={18}
                                                        color={star <= rating ? 'gold' : 'gray'}
                                                        style={{ cursor: 'pointer' }}
                                                        key={index}
                                                    />
                                                ))}
                                            </p>
                                            <p className="mb-3">
                                                {`"${comment || 'No comment provided'}"`}
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
            }
        </>
    )
}

export default TrainingDetailSection
