import React, {useEffect, useState} from 'react'
import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CLoadingButton,
    CModal, CModalBody, CModalFooter,
    CModalHeader, CModalTitle,
    CRow,
} from '@coreui/react-pro'
import {Link, useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {SignUpSchema} from '../../../form-schema/user-form-schema/signUpSchema'
import {useSignUpQuery, useVerifyUserQuery} from "../../../hooks/user/userApiHooks";
import {success_notify} from "../../../constant";
import ErrorReports from "../../../components/ErrorReports";
import RegSuccess from './RegSuccess'
import {BiBadgeCheck} from "react-icons/bi";

const SignUp = () => {
    const [isVerified, setIsVerified] = useState(false)
    const [visible, setVisible] = useState(false)
    const [signupData, setSignupData] = useState(null)
    const [showRegSuccess, setShowRegSuccess] = useState(false)
    const [isUseNIN, setIsUseNIN] = useState(true)

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        setError,
        watch,
        clearErrors
    } = useForm({
        resolver: yupResolver(SignUpSchema),
    })

    const {
        data: VData,
        error: VError,
        isError: isVError,
        mutate: VMutate,
        isSuccess: isVSucess,
        isLoading: isVLoading
    } = useVerifyUserQuery(isUseNIN)


    const {
        isLoading,
        isSuccess,
        isError,
        error,
        data,
        mutate
    } = useSignUpQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Account Successfully created')
            setShowRegSuccess(true)
            setTimeout(() => {
                setShowRegSuccess(false)
                navigate(`/login`, {
                    replace: true,
                    state: {
                        email: data?.data?.data?.email,
                    },
                })

            }, 5000)
        }
    }, [isLoading, isSuccess, isError, error, data, mutate])

    useEffect(() => {
        if (isVSucess) {
            setValue('firstName', VData?.data?.data?.firstname || VData?.data?.data?.first_name)
            setValue('lastName', VData?.data?.data?.surname || VData?.data?.data?.last_name)
            setValue('email', null)
            setValue('phone', null)
            setIsVerified(true)
        }
    }, [isVSucess, VData, isVLoading])

    const handleSubmitNIN = () => {
        const nin = getValues('applicantNin')
        const regex = /^\d{11}$/;

        if (!regex.test(nin)) {
            setError('applicantNin', {message: 'NIN must be 11 digits!'})
        } else {
            clearErrors('applicantNin')
            VMutate({nin})
        }
    }

    const handlePaste = (event) => {
        event.preventDefault();
        alert('Pasting is not allowed!');
    };

    const handleSubmitBVN = () => {
        const bvn = getValues('applicantBvn')
        const regex = /^\d{11}$/;

        if (!isUseNIN && !regex.test(bvn)) {
            setError('applicantBvn', {message: 'BVN must be 11 digits!'})
        } else {
            clearErrors('applicantBvn')
            VMutate({bvn})
        }
    }

    const handleSignUp = (data) => {
        setVisible(false)

        delete data.applicantNin
        if(VData?.data?.data?.nin){
            data.nin = VData?.data?.data?.nin
        } else {
            delete data.nin
        }
        if(VData?.data?.data?.bvn){
            data.nin = VData?.data?.data?.bvn
        }
        else {
            delete data.bvn
        }

        const FName = VData?.data?.data?.firstname || VData?.data?.data?.first_name
        const LName = VData?.data?.data?.surname || VData?.data?.data?.last_name
        const MName = VData?.data.data?.middlename || VData?.data.data?.middle_name || ""

        data.bvn = VData?.data?.data?.bvn
        data.firstName = FName.replace(/^\s+|\s+$/gm,'');
        data.lastName = LName.replace(/^\s+|\s+$/gm,'');
        data.otherName = MName.replace(/^\s+|\s+$/gm,'');
        data.gender = VData?.data?.data?.gender
        data.dateOfBirth = VData?.data?.data?.birthdate || VData?.data?.data?.date_of_birth
        data.stateOfOrigin = VData?.data?.data?.self_origin_state
        data.localGovernmentOfOrigin = VData?.data?.data?.self_origin_lga
        mutate(data)
    }

    const confirmData = (data)=>{
        setVisible(true)
        setSignupData(data)
    }

    return (
        <>
            {showRegSuccess && <RegSuccess/>}
            {isVError && <ErrorReports error={VError}/>}
            <h1 className="text-1">Create An Account</h1>
            <p className="mb-5">
                Already have an account?{' '}
                <span>
                    <Link to="/login" className="text-decoration-none font-color">
                        Log in
                    </Link>
                </span>
            </p>
            {
                isError &&
                <ErrorReports error={error}/>
            }
            <div className="d-flex flex-row justify-content-center align-items-center mb-5">
                <div className={`sign-up-options ${isUseNIN ? 'sign-up-options-active' : ''}`}
                     onClick={() => setIsUseNIN(true)}>
                    <h4>Use NIN {isUseNIN && <BiBadgeCheck className="mb-2"/>}</h4>
                    <p>Enter your NIN to begin the sign up.</p>
                </div>
                <div className={`sign-up-options ${!isUseNIN ? 'sign-up-options-active' : ''}`}
                     onClick={() => setIsUseNIN(false)}>
                    <h4>Use BVN {!isUseNIN && <BiBadgeCheck className="mb-2"/>}</h4>
                    <p>Provide BVN to start the sign up.</p>
                </div>
            </div>
            <div className="d-flex flex-column gap-lg-4">
                {!isVerified && <>
                    <CRow>
                        {isUseNIN &&
                            <>
                                <CCol md={12}>
                                    <CFormInput
                                        type="text"
                                        id="applicantNin"
                                        placeholder="Enter Your NIN"
                                        {...register('applicantNin')}
                                        invalid={!!errors.applicantNin}
                                        className="mb-2"
                                    />
                                    <CFormFeedback invalid={!!errors.applicantNin}>
                                        {errors.applicantNin?.message}
                                    </CFormFeedback>
                                </CCol>
                                <CCol md={12}>
                                    <CLoadingButton
                                        className="reg-btn px-4 w-100 py-2"
                                        onClick={handleSubmitNIN}
                                        disabled={isVLoading}
                                        loading={isVLoading}
                                    >
                                        Proceed
                                    </CLoadingButton>
                                </CCol>
                            </>
                        }
                        {
                            !isUseNIN &&
                            <>
                                <CCol md={12}>
                                    <CFormInput
                                        type="text"
                                        id="applicantBvn"
                                        placeholder="Enter Your BVN"
                                        {...register('applicantBvn')}
                                        invalid={!!errors.applicantBvn}
                                        className="mb-2"
                                    />
                                    <CFormFeedback invalid={!!errors.applicantBvn}>
                                        {errors.applicantBvn?.message}
                                    </CFormFeedback>
                                </CCol>
                                <CCol md={12}>
                                    <CLoadingButton
                                        className="reg-btn px-4 w-100 py-2"
                                        onClick={handleSubmitBVN}
                                        disabled={isVLoading}
                                        loading={isVLoading}
                                    >
                                        Proceed
                                    </CLoadingButton>
                                </CCol>
                            </>
                        }
                    </CRow>
                </>}
                {isVerified && <>
                    <CForm noValidate className="needs-validation w-100" onSubmit={handleSubmit(confirmData)}>
                        <CRow>
                            <CCol md={6}>
                                <CFormInput
                                    type="text"
                                    id="firstName"
                                    placeholder="Enter Your First Name"
                                    {...register('firstName')}
                                    invalid={!!errors.firstName}
                                    className="mb-2"
                                    readOnly={true}
                                />
                                <CFormFeedback invalid={!!errors.firstName}>
                                    {errors.firstName?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="text"
                                    id="lastName"
                                    placeholder="Enter Your Last Name"
                                    {...register('lastName')}
                                    invalid={!!errors.lastName}
                                    className="mb-2"
                                    readOnly={true}
                                />
                                <CFormFeedback invalid={!!errors.lastName}>
                                    {errors.lastName?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={6}>
                                <CFormInput
                                    type="email"
                                    id="inputEmail4"
                                    placeholder="Enter Your Email"
                                    {...register('email')}
                                    invalid={!!errors.email}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.email}>{errors.email?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="email"
                                    id="confirmEmail"
                                    onPaste={handlePaste}
                                    placeholder="Re-Enter Your Email"
                                    {...register('confirmEmail')}
                                    invalid={!!errors.confirmEmail}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.confirmEmail}>{errors.confirmEmail?.message}</CFormFeedback>
                            </CCol>
                            <CCol>
                                <CFormInput
                                    type="text"
                                    id="phone"
                                    className="mb-2"
                                    placeholder="Enter phone number"
                                    {...register('phone')}
                                    invalid={!!errors.phone}
                                />
                                <CFormFeedback invalid={!!errors.phone}>{errors.phone?.message}</CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={6}>
                                <CFormInput
                                    type="password"
                                    id="inputPassword4"
                                    {...register('password')}
                                    placeholder="Enter Your Password"
                                    invalid={!!errors.password}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.password}>
                                    {errors.password?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormInput
                                    type="password"
                                    id="confirmPassword"
                                    onPaste={handlePaste}
                                    {...register('confirmPassword')}
                                    placeholder="Re-Enter Your Password"
                                    invalid={!!errors.confirmPassword}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.confirmPassword}>
                                    {errors.confirmPassword?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <div className="d-flex mb-5">
                            <CFormCheck
                                label="Send me unique offers, personalised recommendations, career tips"
                                className="login-form-check-label"
                                {...register('marketingEmails')}
                            />
                        </div>
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-100 py-2"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Sign Up
                        </CLoadingButton>
                    </CForm>
                    <CModal
                        alignment="center"
                        backdrop="static"
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="StaticBackdropExampleLabel"
                    >
                        <CModalHeader>
                            <CModalTitle id="StaticBackdropExampleLabel">Confirmation</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <h6>Confirm that the below details are correct and you have access to both email and phone number.</h6>
                            <div>Email: <strong>{watch("email")}</strong></div>
                            <div>Phone Number:  <strong>{watch("phone")}</strong></div>
                            <br/>
                            <div>
                                <strong className="text-danger">Note that incorrect email will prevent you from accessing your account and even creating another account</strong>
                            </div>
                        </CModalBody>
                        <CModalFooter>
                            <CButton className="text-white bg-danger" onClick={() => setVisible(false)}>
                                Close
                            </CButton>
                            <CButton className="text-white bg-primary" onClick={()=>handleSignUp(signupData)}>Confirm</CButton>
                        </CModalFooter>
                    </CModal>
                </>
                }
                {/*<div className="d-flex flex-column justify-content-center align-items-center">*/}
                {/*    <p className="text-small pb-2">OR</p>*/}
                {/*    <Link to="/user/signup/pan-african">*/}
                {/*        <button className="btn btn-sm btn-primary text-white py-2 px-3">Sign Up for Pan-African Users</button>*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 font-color w-100">
                <p>
                    By signing up, you agree to our {' '}
                    <Link to="#" target="_blank">Terms of Use</Link> and{' '}
                    <Link to="#" target="_blank">Privacy Policy.</Link>
                </p>
            </div>
        </>
    )
}

export default SignUp
