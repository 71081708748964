const filterPreloadedUserData = (Data) => {
  const userData = Data?.user?.profile
  return {
    userId: Data?.user?.id,
    firstName: Data?.user?.firstName,
    lastName: Data?.user?.lastName,
    otherName:Data?.user?.otherName,
    gender: (userData?.gender?.charAt(0).toLowerCase() === "m") ? "Male" : "Female",
    dateOfBirth: userData?.dateOfBirth,
    email: Data?.user?.email,
    phoneNumber: Data?.user?.phoneNumber,
    disability: userData?.disability,
    stateOfOrigin: userData?.stateOfOrigin,
    localGovernmentOfOrigin: userData?.localGovernmentOfOrigin,
    stateOfResidence: userData?.stateOfResidence,
    localGovernmentOfResidence: userData?.localGovernmentOfResidence,
    address: userData?.address,
    educationLevel: userData?.highestLevelOfEducation,
    employmentStatus: userData?.employmentStatus,
    occupation: userData?.occupation,
    city: userData?.city,
    schoolAttended: userData?.schoolAttended,
    userCV: userData?.cvUrl,
    userDP: userData?.passportUrl,
    careerLevel: userData?.careerLevel,
    employmentType: userData?.employmentType,
    jobPreference: userData?.jobPreference,
    professionalSkills: userData?.professionalSkills,
    availability: userData?.availability,
    profileCompleted: Data?.profileCompleted,
    cultureFitCategory: Data?.cultureFitCategory,
    workExperience: userData?.workExperiences || [],
    educations: userData?.educations || [],
    projects: userData?.projects || [],
    certificates: userData?.certificates || [],
    summary: userData?.summary,
    introVideoKey: userData?.introVideoKey,
    introVideoUrl: userData?.introVideoUrl,
  }
}

export default filterPreloadedUserData
