import React, {useEffect, useState} from "react";
import {
    CButton, CCol, CForm, CFormFeedback,
    CFormLabel, CFormTextarea, CImage, CLoadingButton, CModal, CModalBody,
    CModalFooter, CModalHeader, CModalTitle
} from "@coreui/react-pro";
import DummyImage from "../../assets/images/dummy-profile-pic.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CourseReviewSchema } from "../../form-schema/user-form-schema/courseReviewSchema";
import { FaStar } from "react-icons/fa";
import {useReviewTrainingCourse} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";

const CourseReviewModal = ({ visible, setVisible, data }) => {
    const [rating, setRating] = useState(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CourseReviewSchema),
    });
    const {isLoading, isError, error, isSuccess, mutate} = useReviewTrainingCourse()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Submitted. Thank you for your feedback!')
            setVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isSuccess, isError, error])

    const handleSubmitReview = (formData) => {
        formData.courseId = data?.id
        mutate(formData)
    };

    const handleStarClick = (value) => {
        setRating(value);
    };

    return (
        <CModal
            scrollable
            fullscreen
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="ScrollingContent"
            alignment="center"
        >
            <CModalHeader>
                <CModalTitle id="ScrollingContent" className="px-5">Course: {data?.fullname}</CModalTitle>
            </CModalHeader>
            <CModalBody className="p-5">
                <CImage
                    src={data?.courseimage || DummyImage}
                    alt={data?.fullname}
                    className="w-25 mb-3"
                />
                <p dangerouslySetInnerHTML={{ __html: data?.summary }}></p>
                <hr />
                <h3>Review</h3>
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(handleSubmitReview)}>
                    <CCol md={4} className="mb-3">
                        <CFormLabel className="fs-6">Rating</CFormLabel>
                        <div className="rating d-flex flex-row justify-content-start">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <label key={star}>
                                    <input
                                        type="radio"
                                        id={`rating-${star}`}
                                        className="me-3"
                                        value={star}
                                        name="rating"
                                        {...register('rating')}
                                        style={{ display: 'none' }}
                                        onClick={() => handleStarClick(star)}
                                    />
                                    <FaStar
                                        size={24}
                                        color={star <= rating ? 'gold' : 'gray'}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </label>
                            ))}
                        </div>
                        {errors.rating && (
                            <CFormFeedback className="d-block" invalid>
                                {errors.rating.message}
                            </CFormFeedback>
                        )}
                    </CCol>
                    <CCol md={4}>
                        <CFormLabel className="fs-6">Comment</CFormLabel>
                        <CFormTextarea
                            id="comment"
                            {...register('comment')}
                            placeholder="Enter comment"
                            invalid={!!errors?.comment}
                        />
                        {errors.comment && (
                            <CFormFeedback invalid>
                                {errors.comment.message}
                            </CFormFeedback>
                        )}
                    </CCol>
                    <CCol md={4}>
                        <CLoadingButton
                            type="submit"
                            className="px-4 w-100 py-2 mt-3 text-white"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Submit Rating
                        </CLoadingButton>
                    </CCol>
                </CForm>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" className="text-white" onClick={() => setVisible(false)}>
                    Close
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default CourseReviewModal;
