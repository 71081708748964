import React, {useEffect} from 'react'
import {CSSTransition} from "react-transition-group";


const RightSideDrawer = ({children, visible, onClose}) => {

    return (
        <>
            <CSSTransition
                in={visible}
                timeout={600}
                classNames="slide"
                unmountOnExit
            >
                <div

                    className="position-fixed top-0 left-0 h-100 w-100 highest-z-index">

                    <div className="h-100 w-100 position-relative d-flex justify-content-end" style={{height: "100vh"}}>
                        <div
                            onClick={onClose}
                            className="h-100 flex-grow-1 top-0 left-0 bg-black opacity-50"

                             style={{zIndex: "100000000000000000"}}></div>

                        {children}

                    </div>

                </div>
            </CSSTransition>
        </>
    )
}

export default RightSideDrawer
