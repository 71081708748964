import React, {useEffect, useState} from 'react'
import {
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormSelect,
    CRow
} from "@coreui/react-pro";
import {
    // useGetAppliedJobsQuery,
    // useGetJobsQuery,
    useGetRecommendedJobsQuery,
    // useGetSaveJobsQuery,
} from "../../hooks/user/userApiHooks";
import AuthRoute from "../../components/User/AuthRoute";
import {useAppContext} from "../../context/user/UserAppProvider";
import ErrorReports from "../../components/ErrorReports";
import ShowLoading from "../../components/ShowLoading";
import DataNotFound from "../../components/DataNotFound";
import JobCard from "../../components/job/JobCard";
import MyProject from "../../components/job/MyProject";
import {FaAngleRight, FaArrowLeftLong} from "react-icons/fa6";
import RightSideDrawer from "../../components/RightSideDrawer";
import ProjectDetails from "../../components/job/ProjectDetails";
import PostJob from "../../components/job/PostJob";
import SeeJobApplicants from "../../components/job/SeeJobApplicants";
import {useGetEmployersJobs, useGetJobPipeline, useGetAllUsersInPipeline} from "../../hooks/employer/employerApiHooks";
import CreateProjectModal from "../../components/modals/CreateProjectModal";
import TableNavigation from "../../components/TableNavigation";

function JobsNew() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [myProjectDetail, setMyProjectDetail] = useState(false);
    const [myProject, setMyProject] = useState(null);
    const [rightDrawer, setRightDrawer] = useState(false);
    const [filter, setFilter] = useState('');
    // const [search, setSearch] = useState("");
    const [_, setUserLogin] = useState("");
    const {user} = useAppContext()
    const jobs = useGetEmployersJobs(filter)
    const {data: jobPipeline, refetch: refetchJobPipeline} = useGetJobPipeline(filter)
    // const [enable, setEnable] = useState(false)
    const [searchEnable, setSearchEnable] = useState(false)
    const searches = useGetRecommendedJobsQuery(searchEnable)
    const [activeTap, setActiveTap] = useState(1)
    const [jobApplicants, setJobApplicants] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const pipelineUsers = useGetAllUsersInPipeline(myProject?.pipeline_id)
    const noJobFound = ((activeTap === 1 && jobs?.data?.data?.data?.meta?.totalItems === 0))
    const noJobPipelineFound = ((activeTap === 1 && jobPipeline?.data?.data?.meta?.totalItems === 0))

    useEffect(() => {
        if ((user?.accessToken && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) || !user?.accessToken) {
            setSearchEnable(false)
        }

        if (user?.accessToken && user?.data?.role === "Admin") {
            setUserLogin("&isAdmin=true")
        } else if (user?.accessToken && ((user?.data?.role === "NASIMS") || (user?.data?.role === "NYSC") || (user?.data?.role === "User") || (user?.data?.role === "OG Academy"))) {
            setUserLogin()
        } else if (user?.accessToken && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) {
            setUserLogin("")
        } else {
            setUserLogin("")
        }
    }, [])

    if (jobs.isError || searches.isError) {
        return <ErrorReports error={"Oops Something went wrong"}/>
    }

    if (jobs.isLoading || searches.isError) {
        return <ShowLoading/>
    }

    return (
      <div onFocus={() => refetchJobPipeline()}>
        {
          <RightSideDrawer
            visible={rightDrawer}
            onClose={() => {
              setRightDrawer(false)
            }}
          >
            <SeeJobApplicants data={jobApplicants} />
          </RightSideDrawer>
        }

        <div className="mb-5">
          <AuthRoute protectedRoute={'/user/jobs'} openRoute={'/jobs'} />

          <CContainer>
            <div className="d-flex justify-content-between py-4 flex-wrap gap-3 align-items-center">
              <div>
                <div className="d-flex flex-column align-items-center">
                  <div style={{ width: '200px' }}>
                    <h5 className="text-black text-truncate">
                      <b className="text-truncate">Jobs</b>
                    </h5>
                    <p style={{ marginTop: '-7px' }} className="text-black-50">
                      Everything about your job
                    </p>
                  </div>
                </div>
              </div>
              <CForm>
                <div
                  className="border-2 border-black border ps-2 rounded d-flex align-items-center"
                  style={{ minWidth: '280px' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>

                  <CFormInput
                    type="search"
                    id="companyName"
                    placeholder="Search job title, status, salary, experience"
                    autoComplete="off"
                    className="bg-transparent border rounded-0 border-white border-0 shadow-none text-small"
                    /*onChange={debouncedSearchChangeHandler}*/
                  />
                </div>
              </CForm>
            </div>
            <div className="d-flex bg-white w-100 p-3 mb-5 align-items-center justify-content-between flex-wrap gap-3">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row">
                  {!!myProjectDetail && (
                    <div
                      className={`text-black cursor-pointer`}
                      onClick={() => {
                        setMyProjectDetail(false)
                      }}
                    >
                      <FaArrowLeftLong className={`text-black cursor-pointer`} size={20} /> Go Back
                      &nbsp; &nbsp;
                    </div>
                  )}
                  <div
                    className={`${
                      activeTap === 1 ? 'text-primary fw-bolder' : 'text-black-50'
                    } cursor-pointer`}
                    onClick={() => {
                      setMyProjectDetail(false)
                      setActiveTap(1)
                    }}
                  >
                    Job Listing&nbsp;
                    <FaAngleRight
                      className={`${
                        activeTap === 1 ? 'text-primary fw-bolder' : 'text-black-50'
                      } cursor-pointer`}
                      size={22}
                    />
                    &nbsp;
                  </div>
                  <div
                    className={`${
                      activeTap === 2 ? 'text-primary fw-bolder' : 'text-black-50'
                    } cursor-pointer`}
                    onClick={() => {
                      setActiveTap(2)
                    }}
                  >
                    My Projects&nbsp;
                    <FaAngleRight
                      className={`${
                        activeTap === 2 ? 'text-primary fw-bolder' : 'text-black-50'
                      } cursor-pointer`}
                      size={22}
                    />
                    &nbsp;
                  </div>
                  <div
                    className={`${
                      activeTap === 3 ? 'text-primary fw-bolder' : 'text-black-50'
                    } cursor-pointer`}
                    onClick={() => {
                      setMyProjectDetail(false)
                      setActiveTap(3)
                    }}
                  >
                    Post a Job&nbsp;
                    <FaAngleRight
                      className={`${
                        activeTap === 3 ? 'text-primary fw-bolder' : 'text-black-50'
                      } cursor-pointer`}
                      size={22}
                    />
                  </div>
                </div>
              </div>
              {activeTap === 1 && (
                <div className="d-flex gap-3 flex-wrap">
                  <div className="d-flex text-black-50 align-items-center gap-1">
                    <small>Projects:</small>
                    <div
                      className="border border-1 border-dark d-flex align-items-center"
                      style={{ width: '190px', height: '32px' }}
                    >
                      <CFormSelect
                        name="isActive"
                        className="bg-transparent text-black border rounded-0 border-white border-0 shadow-none text-small"
                      >
                        <option value="" selected>
                          All Projects
                        </option>
                        <option value="true">Frontend Developer</option>
                        <option value="false">Backend Developer</option>
                      </CFormSelect>
                    </div>
                  </div>
                  <div className="d-flex text-black-50 align-items-center gap-1">
                    <small>Status:</small>
                    <div
                      className="border border-1 border-dark d-flex align-items-center"
                      style={{ width: '100px', height: '32px' }}
                    >
                      <CFormSelect
                        name="isActive"
                        className="bg-transparent text-black border rounded-0 border-white border-0 shadow-none text-small"
                      >
                        <option value="" selected>
                          Open
                        </option>
                        <option value="true">Close</option>
                        <option value="false">Archive</option>
                      </CFormSelect>
                    </div>
                  </div>
                </div>
              )}
              {activeTap === 2 && (
                <div>
                  <button
                    className="btn btn-lg btn-primary px-4 py-1 fs-6 text-white"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Create New Project
                  </button>
                </div>
              )}
            </div>

            {activeTap === 1 && (
              <>
                <CRow
                  xs={{ cols: 1 }}
                  sm={{ cols: 2 }}
                  md={{ cols: 2 }}
                  lg={{ cols: 3 }}
                  xl={{ cols: 4 }}
                >
                  {noJobFound ? (
                    <DataNotFound />
                  ) : (
                    jobs?.data?.data?.data?.items?.map((item, index) => {
                      let job
                      if (item?.job) {
                        job = item.job
                      } else {
                        job = item
                      }
                      return (
                        <CCol className="p-3" key={index}>
                          <JobCard
                            data={job}
                            setRightDrawer={setRightDrawer}
                            setJobApplicants={setJobApplicants}
                          />
                        </CCol>
                      )
                    })
                  )}
                </CRow>
                <TableNavigation
                  page={page}
                  setPage={setPage}
                  setLimit={setLimit}
                  totalPages={jobs?.data?.data?.data?.items?.length / limit}
                />
              </>
            )}

            {activeTap === 2 && (
              <CRow
                xs={{ cols: 1 }}
                sm={{ cols: 2 }}
                md={{ cols: 2 }}
                lg={{ cols: 3 }}
                xl={{ cols: 4 }}
              >
                <>
                  {noJobPipelineFound ? (
                    <DataNotFound />
                  ) : (
                    <>
                      {myProjectDetail && (
                        <>
                          <CCol className="p-3 pt-0 w-100" style={{ marginTop: '-3rem' }}>
                            <ProjectDetails
                              project={myProject}
                              data={pipelineUsers?.data?.data?.data}
                            />
                          </CCol>
                        </>
                      )}
                      {!myProjectDetail &&
                        jobPipeline?.data?.data?.items
                          ?.filter(({ pipeline_job_title }) => {
                            return pipeline_job_title !== null
                          })
                          ?.map((item, index) => {
                            return (
                              <CCol className="p-3 pt-0" key={index}>
                                <MyProject
                                  data={item}
                                  setMyProjectDetail={setMyProjectDetail}
                                  setMyProject={setMyProject}
                                />
                              </CCol>
                            )
                          })}
                    </>
                  )}
                </>
              </CRow>
            )}

            {activeTap === 3 && (
              <>
                <PostJob setActiveTap={setActiveTap} />
              </>
            )}
          </CContainer>
          {isModalVisible && (
            <CreateProjectModal setVisible={setIsModalVisible} visible={isModalVisible} />
          )}
        </div>
      </div>
    )
}

export default JobsNew
