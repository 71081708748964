import React, {useEffect, useState} from 'react'
import Table from "../../components/tables/table";
import {useGetSponsoredCourseApplicants} from "../../hooks/employer/employerApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {CButton, CFormSelect} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import ShowLoading from "../../components/ShowLoading";
import {useParams} from "react-router-dom";
import ScholarshipStatementModal from "../../components/modals/Employer/ScholarshipStatementModal";
import {useApproveForBeneficiaries} from "../../hooks/user/jobApiHooks";
import DataNotFound from "../../components/DataNotFound";
import GoBack from "../../components/GoBack";

const EmployerSponsoredCourseApplicants = () => {
    const {sponsorshipId, title} = useParams();
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [visible, setVisible] = useState(false)
    const [statement, setStatement] = useState('')
    const {
        isSuccess,
        isLoading,
        error,
        isError,
        data
    } = useGetSponsoredCourseApplicants(sponsorshipId);

    const {
        mutate,
        isSuccess: isApproveSuccess,
        data: approvalData,
        isError: isApproveError,
        error: approveError
    } = useApproveForBeneficiaries()

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, data, error, isError, isLoading])

    useEffect(() => {
        if (isApproveSuccess) {
            success_notify('Talent Approval Completed')
        }
        if (isApproveError) {
            fail_notify(approveError?.response?.data?.message)
        }
    }, [isApproveSuccess, mutate, approvalData, isApproveError, approveError]);

    const handleSponsorshipApproval = (userId, status) => {
        const formData = {
            userId,
            sponsorshipId,
            isApprove: status
        }
        mutate(formData)
    }

    const headers = [
        {name: "No"},
        {name: "Applicant", sort: false},
        {name: "Phone", sort: false},
        {name: "Other Sponsorship", sort: false},
        {name: "Status", sort: true},
        {name: "Action"}
    ]

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <>
            <div className="mt-4">
                <div className="">
                    <GoBack/><br/>
                    <h5 className="text-2 mb-3 ps-1">{title} - Beneficiaries</h5>
                    <hr/>
                </div>
                {data?.data?.data?.items?.length === 0 ? <DataNotFound/>  :
                    <>
                        <Table headers={headers}>
                            {data?.data?.data?.items?.map(({
                                                               userId,
                                                               user,
                                                               isApprove,
                                                               anyScholarship,
                                                               personalStatement
                                                           }, index) => (
                                <tr className="bg-white border" key={index}>
                                    <td className="fw-medium">{index + 1}</td>
                                    <td className="fw-medium">{user?.firstName} {user?.lastName} {user?.otherName}</td>
                                    <td className="fw-medium text-capitalize">{user?.phoneNumber}</td>
                                    <td className="fw-medium">{anyScholarship}</td>
                                    <td className={`fw-medium ${isApprove ? "text-green" : "text-danger"}`}>
                                        {isApprove === null ? 'Pending' : isApprove === true ? 'Approved' : 'Rejected'}
                                    </td>
                                    <td className="fw-medium td-single-line">
                                        <button
                                            className="btn btn-sm btn-info text-white"
                                            onClick={() => {
                                                setStatement(personalStatement)
                                                setVisible(true)
                                            }}
                                        >View Statement</button>
                                        {isApprove === null && <>
                                            <CButton
                                                className="btn btn-sm btn-primary text-white mx-2"
                                                onClick={() => {
                                                    handleSponsorshipApproval(userId, true)
                                                }}
                                            >Approve</CButton>
                                            <CButton
                                                className="btn btn-sm btn-danger text-white"
                                                onClick={() => {
                                                    handleSponsorshipApproval(userId, false)
                                                }}
                                            >Reject</CButton>
                                        </>}
                                    </td>
                                </tr>))
                            }
                        </Table>
                        <div className={"d-flex justify-content-between pb-3"}>
                            <div className="d-flex align-items-center gap-1">
                                <small className={""}>Items per page:</small>

                                <div><CFormSelect
                                    aria-label="Default select example"
                                    value={limit}
                                    options={[
                                        {label: '5', value: '5'},
                                        {label: '10', value: '10'},
                                        {label: '20', value: '20'},
                                        {label: '50', value: '50'},
                                        {label: '100', value: '100'}
                                    ]}
                                    onChange={(e) => setLimit(e.target.value)}
                                /></div>
                            </div>
                            <div className="d-flex  flex-row justify-content-center align-items-center">
                                <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                         className={`mx-3 bg-white text-primary py-1 pb-2 px-3`}>
                                    <BiLeftArrow/>
                                </CButton>

                                <CButton
                                    disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                                    onClick={() => setPage(page + 1)}
                                    className="mx-3 bg-white text-primary py-1 pb-2 px-3">
                                    <BiRightArrow/>
                                </CButton>
                            </div>
                        </div>
                    </>
                }
                {visible &&
                    <ScholarshipStatementModal visible={visible} setVisible={setVisible} statement={statement}/>}
            </div>
        </>
    )
}

export default EmployerSponsoredCourseApplicants
