import React, { createContext, useContext, useMemo } from 'react'
import io from 'socket.io-client'

import { SOCKET_BASE_URL } from 'src/constant/constants'
import { useAppContext } from '../user/UserAppProvider'

export const MessageContext = createContext(null)

export const MessageContextProvider = ({ children }) => {
  const { chatUser } = useAppContext()

  const socketContextValue = useMemo(() => {
    if (chatUser) {
      const url = `${SOCKET_BASE_URL}?userId=${chatUser.id}`
      const socket = io(url)

      return { socket }
    }
  }, [chatUser])

  return <MessageContext.Provider value={socketContextValue}>{children}</MessageContext.Provider>
}

export const useMessageContext = () => useContext(MessageContext)
