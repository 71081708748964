import React, {useState} from 'react'
import {FaRegEnvelope} from "react-icons/fa6";
import {CButton, CImage} from "@coreui/react-pro";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png";
import {AiOutlineDownload} from "react-icons/ai";
import appApiCalls from "../../services/api";
import {SOCKET_EVENTS} from "../../constant/constants";
import {success_notify} from "../../constant";
import useSocket from "../../hooks/user/useSocket";
import {useNavigate} from "react-router-dom";

function ProjectPipeline({data}) {

    const [loadingMsg, setLoadingMsg] = useState(false)
    const {socket} = useSocket()
    const navigate = useNavigate()

    const chatWithACandidate = async (user) => {
        setLoadingMsg(true)
        try {
            const { firstName, lastName, email } = user
            const chatData = await appApiCalls.getActiveChatUser(email)
            if (!chatData?.data) {
                //   initalize chat user
                const res = await appApiCalls.initializeChatUser({
                    email,
                    firstName,
                    lastName,
                    role: 'User',
                })
                socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: res?.data?.id })
                navigate(`/employer/message/chat?activeUserId=${res?.data?.id}`, {
                    replace: true,
                })
                success_notify('chat created')
            } else {
                socket?.emit(SOCKET_EVENTS.CREATE_CHAT, { receiver_id: chatData?.data?.id })
                navigate(`/employer/message/chat?activeUserId=${chatData?.data?.id}`, {
                    replace: true,
                })

                success_notify('chat created')
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingMsg(false)
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between gap-3 py-2">
                <div className="bg-white p-2 pt-3" style={{width: "400px"}}>

                        <div
                            className="border flex-fill border-start-0 border-top-0 border-end-0 border-bottom-2 px-3">
                            <div className="d-flex justify-content-between text-primary small">
                                <small><strong>All Candidates</strong></small>
                                <small><strong>{data?.items?.length}</strong></small>
                            </div>
                            <br/>
                            <div className="d-flex justify-content-between text-black-50 small">
                                <small><strong>Archived Candidates</strong></small>
                                <small><strong>0</strong></small>
                            </div>
                            <br/>
                        </div>
                    <br/>
                    {/*<div className="px-3">*/}
                    {/*    <h6 className="text-black fw-bolder"><b>Recruitment Stages</b></h6>*/}

                    {/*    <div className="d-flex justify-content-between text-black-50 small">*/}
                    {/*        <small><strong>Contacted Candidates</strong></small>*/}
                    {/*        <small><strong>11</strong></small>*/}
                    {/*    </div>*/}
                    {/*    <br/>*/}
                    {/*    <div className="d-flex justify-content-between text-black-50 small">*/}
                    {/*        <small><strong>Uncontacted Candidates</strong></small>*/}
                    {/*        <small><strong>2</strong></small>*/}
                    {/*    </div>*/}
                    {/*    <br/>*/}
                    {/*    <div className="d-flex justify-content-between text-black-50 small">*/}
                    {/*        <small><strong>Candidates Relied</strong></small>*/}
                    {/*        <small><strong>23</strong></small>*/}
                    {/*    </div>*/}
                    {/*    <br/>*/}
                    {/*</div>*/}

                </div>
                <div style={{maxWidth: "900px"}} className=" ps-2 d-flex flex-column gap-3 flex-grow-2 flex-fill">
                    <div className="bg-white p-2 px-3 py-3">
                        <h6 className="text-black fw-bolder"><b>Candidate Results</b></h6>
                        {
                            data?.items?.map((item, index)=>{
                                return (
                                    <div key={index}>
                                        <div className="d-flex justify-content-between ">
                                            <div className="d-flex align-items-start mt-3">
                                                <CImage src={item?.profile?.passportUrl||DummyProfilePic} onError={(e) => {
                                                    e.target.src = DummyProfilePic
                                                }
                                                } alt="Profile" style={{
                                                    width: '32px',
                                                    height: '32px',
                                                    marginRight: '10px',
                                                    borderRadius: '50%',
                                                }}/>
                                                <div className='d-flex flex-column text-black'>
                                                    <small className="text-primary fw-bolder"><strong>{item?.firstName} {item?.lastName}</strong></small>
                                                    <small className="small-text-gray fw-medium"
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        {item?.profile?.professionalSkills?.map((item)=>{
                                                            return item + " | "
                                                        })}

                                                    </small>
                                                    <small className="text-black-50"
                                                           style={{fontSize: '11px', marginTop: "-2px"}}> {item?.profile?.stateOfResidence}</small>

                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center gap-3" style={{width:'180px'}}>

                                                <CButton
                                                    className="text-small btn-sm text-white rounded-0"
                                                    onClick={() => {
                                                        chatWithACandidate(item)
                                                    }}
                                                    disabled={loadingMsg}
                                                >
                                                    Send Message
                                                </CButton>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex gap-3 mt-2 align-items-start">
                                                <small className="text-black fw-bolder">Experience:</small>
                                                <div className='d-flex mt-1 flex-column'>

                                                    {item?.profile?.workExperiences?.length > 0 ?
                                                        <>
                                                            {item?.profile?.workExperiences?.map((exp, index) => {
                                                                return (
                                                                    <small key={index} className="small-text-gray"
                                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                        {exp?.jobTitle} at {exp?.companyName} - <i>{exp?.startDate} to {exp?.endDate || "Present"}</i>
                                                                    </small>
                                                                )
                                                            })}
                                                        </> : <small className="small-text-gray"
                                                                     style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Nil
                                                        </small>
                                                    }

                                                    {item?.profile?.workExperiences?.length > 3 && <small
                                                        style={{fontSize: '12px', marginTop: "-2px"}}
                                                        className="cursor-pointer small-text-gray
                                                        text-decoration-underline text-italic">Show more</small>}

                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-2 align-items-start">
                                                <small className="text-black fw-bolder">Education:</small>
                                                <div className='d-flex mt-1 flex-column'>
                                                    {item?.profile?.educations?.length > 0 ?
                                                        <>
                                                            {item?.profile?.educations?.map((edu, index) => {
                                                                return (
                                                                    <small key={index} className="small-text-gray"
                                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                                        {edu?.schoolName} ({edu?.degree}, {edu?.fieldOfStudy}) - <i>{edu?.startDate} to {edu?.endDate || "Present"}</i>
                                                                    </small>
                                                                )
                                                            })}
                                                        </> : <small className="small-text-gray"
                                                                     style={{fontSize: '12px', marginTop: "-2px"}}>
                                                            Nil
                                                        </small>
                                                    }

                                                    {item?.user?.educations?.length > 3 && <small
                                                        style={{fontSize: '12px', marginTop: "-2px"}}
                                                        className="cursor-pointer small-text-gray
                                                        text-decoration-underline text-italic">Show more</small>}

                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-2 align-items-start">
                                                <small className="text-black fw-bolder">Skill Tests:</small>
                                                <div className='d-flex mt-1 flex-column'>
                                                    <small className="small-text-gray"
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Personality Assessment - <b
                                                        className='fw-bolder text-black'></b>
                                                    </small>
                                                    <small className="small-text-gray"
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Culture Fitness Assessment - <b
                                                        className='fw-bolder text-black'></b>
                                                    </small>
                                                    <small className="small-text-gray "
                                                           style={{fontSize: '12px', marginTop: "-2px"}}>
                                                        Soft Skill Assessment - <b
                                                        className='fw-bolder text-black'></b>

                                                    </small>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="d-flex flex-column">
                                                {/*<small className="small-text-gray"*/}
                                                {/*       style={{fontSize: '12px', marginTop: "-2px"}}>*/}
                                                {/*    <b className="text-black">Replied</b> on June 28, 2024*/}
                                                {/*</small>*/}
                                                {/*<small className="small text-primary"*/}
                                                {/*       style={{fontSize: '12px', marginTop: "-2px"}}>*/}
                                                {/*    <b>Accepted</b>*/}
                                                {/*</small>*/}
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })
                        }
                        <hr/>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectPipeline;
