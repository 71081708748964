import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro'
import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import ShowLoading from 'src/components/ShowLoading'
import { useGetNewLMSCourseUserByID } from 'src/hooks/admin/adminApiHooks'

const users = [
  {
    id: '4',
    username: 'gaiyaobed94@gmail.com',
    firstname: 'Obededom',
    lastname: 'Mathias',
    email: 'gaiyaobed94@gmail.com',
  },
  {
    id: '4',
    username: 'admin',
    firstname: 'Admin',
    lastname: 'User',
    email: 'admin@worknation.com',
  },
]

const LMSCourseUsers = () => {
  const navigate = useNavigate()
  const { isLoading } = useGetNewLMSCourseUserByID('2')

  return (
    <CContainer className="mt-4">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h5 className="text-2 mb-3 ps-1">LMS Course Users</h5>{' '}
        <CButton
          onClick={() => {
            navigate(-1)
          }}
          variant="ghost"
        >
          <BiArrowBack /> Go Back
        </CButton>
      </div>
      <CRow className="mt-3 mb-5">
        {' '}
        {isLoading && <ShowLoading />}
        {!isLoading && (
          <CCol md={12}>
            <table className="table border-0" id="reports-table">
              <thead className="thead-inverse">
                <tr className="bg-transparent text-primary">
                  <th>User Name</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th className="text-center">Email</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <tr key={index}>
                    <th>{item.username}</th>
                    <th>{item.firstname}</th>
                    <th>{item.lastname}</th>
                    <th className="text-center">{item.email}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </CCol>
        )}
      </CRow>
    </CContainer>
  )
}

export default LMSCourseUsers
