import React, { useEffect, useState } from 'react'
import { MdGroup, MdOutlineManageSearch } from 'react-icons/md'
import { Link } from 'react-router-dom'
// import {
//   PieChart,
//   Pie,
//   Cell,
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts'
import appApiCalls from 'src/services/api'

// const data1 = [
//   { name: 'Male', value: 400 },
//   { name: 'Female', value: 300 },
//   { name: 'Undisclosed', value: 300 },
// ]
//
// const COLORS = ['#FFBB28', '#00C49F', '#0088FE']
//
// const data = [
//   { name: '1 week ago', openRate: 40, responseRate: 20 },
//   { name: '3 weeks ago', openRate: 20, responseRate: 60 },
//   { name: '6 weeks ago', openRate: 50, responseRate: 30 },
//   { name: '9 weeks ago', openRate: 60, responseRate: 10 },
// ]

function ProjectOverview() {
  // const [period, setPeriod] = useState('')
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({
    totalEmailsSent: 0,
    totalRepliesReceived: 0,
    responseRate: '0.00',
  })

  const getStats = async () => {
    try {
      setLoading(true)
      const stats = await appApiCalls.getStakeholderEmailStats()
      setStats(stats?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStats()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between gap-3 py-2">
        <div className="h-100 d-flex flex-column">
          <div className="px-2">
            <small className="smaller-text-12">PINNED DATA</small>
          </div>
          <div className="p-2 pt-0 d-flex flex-column gap-4" style={{ width: '400px' }}>
            <div className="bg-white rounded rounded-2 d-flex justify-content-between align-items-center px-4 py-3 w-100">
              <small className="text-small text-black-50">
                <MdOutlineManageSearch size={40} className="text-black-50" /> Search Results
              </small>
              <h6 className="text-black fw-bolder rounded rounded-2 bg-grey py-2 px-3">
                <b>...</b>
              </h6>
            </div>
            <div className="bg-white rounded rounded-2 d-flex justify-content-between align-items-center px-4 py-4 w-100">
              <small className="text-small text-black-50">
                <MdGroup size={40} className="text-black-50" /> Active Candidates
              </small>
              <h6 className="text-black fw-bolder rounded rounded-2 bg-grey py-2 px-3">
                <b>...</b>
              </h6>
            </div>
          </div>
        </div>

        <div style={{ maxWidth: '900px' }} className="ps-2 flex-grow-2 flex-fill">
          <div>
            <small className="smaller-text-12">SUGGESTED ACTIONS</small>
          </div>
          <div className="bg-white px-5 py-5 h-100 rounded rounded-2 d-flex justify-content-between gab-2 flex-wrap">
            <div className="d-flex flex-column align-items-center justify-content-center pe-5 border border-end-1 border-top-0 border-start-0 border-bottom-0">
              <h6 className="text-black fw-bolder">
                <b>{loading ? '...' : stats?.totalEmailsSent} Total Email Sent</b>
              </h6>
              <small
                className="text-blue-500 text-decoration-underline"
                style={{ fontSize: '13px', marginTop: '-2px' }}
              >
                <Link to="/employer/message">Read messages</Link>
              </small>
              {/* <small className="text-black-50 mt-1" style={{ fontSize: '12px' }}>
                Form: Tomiwa Johnson +9 more
              </small> */}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center pe-5 border border-end-1 border-top-0 border-start-0 border-bottom-0">
              <h6 className="text-black fw-bolder">
                <b>{loading ? '...' : stats?.responseRate} Response Rate</b>
              </h6>
              {/* <small
                className="text-blue-500 text-decoration-underline"
                style={{ fontSize: '13px', marginTop: '-2px' }}
              >
                Read messages
              </small>
              <small className="text-black-50 mt-1" style={{ fontSize: '12px' }}>
                Form: Tomiwa Johnson +9 more
              </small> */}
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h6 className="text-black fw-bolder">
                <b>{loading ? '...' : stats?.totalRepliesReceived} Total replies received</b>
              </h6>
              <small
                className="text-blue-500 text-decoration-underline"
                style={{ fontSize: '13px', marginTop: '-2px' }}
              >
                <Link to="/employer/message">Read messages</Link>
              </small>
              {/* <small className="text-black-50 mt-1" style={{ fontSize: '12px' }}>
                Form: Tomiwa Johnson +9 more
              </small> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 d-flex gap-3">
        {/* <div className=" flex-fill">
          <div className="bg-white p-3 w-100 rounded rounded-2">
            <div
              style={{ marginBottom: '-9px' }}
              className="d-flex justify-content-between align-items-center pe-2"
            >
              <h6>
                <b className="text-black fw-bolder">Recent Activities</b>
              </h6>
              <div className="d-flex gap-3">
                <select
                  name="isActive"
                  style={{ height: '32px', outline: 'none' }}
                  defaultValue={'year'}
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  className="bg-transparent text-black-50 fw-bold ps-1 border rounded-0 border-0 shadow-none "
                >
                  <option value="year" selected>
                    year
                  </option>
                  <option value="monthly" selected>
                    Monthly
                  </option>
                  <option value="weekly" selected>
                    Weekly
                  </option>
                </select>
              </div>
            </div>
            <hr />
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 2,
                  left: -20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="openRate"
                  stroke="#0088FE"
                  label={{ position: 'top', fill: '#0088FE' }}
                />
                <Line
                  type="monotone"
                  dataKey="responseRate"
                  stroke="#FFBB28"
                  label={{ position: 'top', fill: '#FFBB28' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="bg-white p-3 w-100 mt-4 rounded rounded-2">
            <div
              style={{ marginBottom: '-9px' }}
              className="d-flex justify-content-between align-items-center pe-2"
            >
              <h6>
                <b className="text-black fw-bolder">Mail Trends</b>
              </h6>
              <div className="d-flex gap-3">
                <select
                  name="isActive"
                  style={{ height: '32px', outline: 'none' }}
                  className="bg-transparent text-black-50 fw-bold ps-1 border rounded-0 border-0 shadow-none "
                >
                  <option value="" selected>
                    All Activity
                  </option>
                </select>
                <select
                  name="isActive"
                  style={{ height: '32px', outline: 'none' }}
                  className="bg-transparent text-black-50 fw-bold ps-1 border rounded-0 border-0 shadow-none "
                >
                  <option value="" selected>
                    Last 2 weeks
                  </option>
                </select>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between gap-2 align-items-center py-3 px-0 rounded rounded-2 w-100 ">
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">23</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  Mails Sent
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">78.92%</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  Open Rate
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">67.90%</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  Average Responds Rate
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">9</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  Accepted
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">6</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  Declined
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <h4>
                  <b className="fw-bolder">8</b>
                </h4>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-12px' }}>
                  No Response
                </small>
              </div>
            </div>

            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 2,
                  left: -20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="openRate"
                  stroke="#0088FE"
                  label={{ position: 'top', fill: '#0088FE' }}
                />
                <Line
                  type="monotone"
                  dataKey="responseRate"
                  stroke="#FFBB28"
                  label={{ position: 'top', fill: '#FFBB28' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-white p-3 w-100 mt-4 rounded rounded-2">
            <div
              style={{ marginTop: '-9px' }}
              className="d-flex justify-content-between align-items-center pe-2"
            >
              <h6>
                <b className="text-black fw-bolder">Recent Activities</b>
              </h6>
              <div className="d-flex gap-3">
                <select
                  name="isActive"
                  style={{ height: '32px', outline: 'none' }}
                  className="bg-transparent text-black-50 fw-bold ps-1 border rounded-0 border-0 shadow-none "
                >
                  <option value="" selected>
                    All Activity
                  </option>
                </select>
                <select
                  name="isActive"
                  style={{ height: '32px', outline: 'none' }}
                  className="bg-transparent text-black-50 fw-bold ps-1 border rounded-0 border-0 shadow-none "
                >
                  <option value="" selected>
                    Last 2 weeks
                  </option>
                </select>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between gap-2 align-items-center py-3 px-0 rounded rounded-2 w-100 border border-1">
              <div className="d-flex align-items-center flex-column w-100 border border-end-1 border-bottom-0 border-start-0 border-top-0">
                <p>
                  <b>66</b>
                </p>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-3px' }}>
                  All Candidates
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100 border border-end-1 border-bottom-0 border-start-0 border-top-0">
                <p>
                  <b>33</b>
                </p>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-3px' }}>
                  Not Contacted
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100 border border-end-1 border-bottom-0 border-start-0 border-top-0">
                <p>
                  <b>79</b>
                </p>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-3px' }}>
                  Contacted
                </small>
              </div>
              <div className="d-flex align-items-center flex-column w-100">
                <p>
                  <b>12</b>
                </p>
                <small className="smaller-text-12 text-black-50" style={{ marginTop: '-3px' }}>
                  Replied
                </small>
              </div>
            </div>
            <div className="d-flex flex-column w-100 pt-3 border border-end-1 border-bottom-0 border-start-0 border-top-0">
              <p>
                <b className="small">Demographics of Saved Candidates (Gender)</b>
              </p>
              <small className="smaller-text-12 text-black-50" style={{ marginTop: '-3px' }}>
                Save more candidates to see gender reports
              </small>
            </div>
            <div style={{ width: '350px', marginTop: '-80px' }}>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={data1}
                    cx="50%"
                    cy="50%"
                    innerRadius={55}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={-5}
                    dataKey="value"
                  >
                    {data1.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend layout="vertical" align="right" verticalAlign="middle" />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div> */}
        {/* <div
          className="bg-white flex-fill w-100 rounded rounded-2"
          style={{ maxWidth: '250px' }}
        ></div> */}
      </div>
    </>
  )
}

export default ProjectOverview
