import {CCol, CRow} from "@coreui/react-pro";
import VerticalDots from "../../assets/icons/vertical-dots.svg";
import React, {useEffect, useState} from "react";
import {useGetAllCohorts} from "../../hooks/admin/adminApiHooks";
import ShowLoading from "../../components/ShowLoading";
import CohortBeneficiaries from "./CohortBeneficiaries";
import TableNavigation from "../../components/TableNavigation";
import AddCohortModal from "../../components/modals/AddCohortModal";
import AddTrainerModal from "../../components/modals/AddTrainerModal";
import KPIManagerBanner from "./KPIManagerBanner";
import UploadBeneficiariesModal from "../../components/modals/UploadBeneficiariesModal";

const CohortDashboard = (
    {
        cohortModalVisible,
        setCohortModalVisible,
        trainerModalVisible,
        setTrainerModalVisible,
    }) => {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [viewCohort, setViewCohort] = useState(false)
    const [cohort, setCohort] = useState(null)
    const [cohortId, setCohortId] = useState(0)
    const [uploadModalVisible, setUploadModalVisible] = useState(false)

    const {data, isLoading, refetch} = useGetAllCohorts(page, limit)

    useEffect(() => {
        refetch()
    }, [page, limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    const TableRow = ({data}) => {
        const [optionsVisible, setOptionsVisible] = useState(false);
        return <tr className="py-2 my-1">
            <td>
                <div className="d-flex flex-row align-items-center pt-2">
                    <input type="checkbox" className="me-2 larger-checkbox"/>
                    <h6 className="m-0" onClick={() => {
                        setViewCohort(true)
                        setCohort(data?.displayName)
                        setCohortId(data?.id)
                    }}>{data?.displayName}</h6>
                </div>
            </td>
            <td>{data?.name}</td>
            <td>{data?.year}</td>
            <td onClick={() => setOptionsVisible(true)} onMouseLeave={() => setOptionsVisible(false)}>
                <div>
                    <img src={VerticalDots} alt="Options"/>
                    {optionsVisible && <div className="d-flex flex-column bg-white shadow-sm p-1 trainer-actions">
                        <small className="px-2 py-1 pe-5" onClick={() => {
                            setViewCohort(true)
                            setCohort(data?.displayName)
                            setCohortId(data?.id)
                        }}>View</small>
                        <small className="px-2 py-1 pe-5" onClick={() => {
                            setCohortId(data?.id)
                            setUploadModalVisible(true)
                        }}>Upload Beneficiaries</small>
                        <small className="px-2 py-1 pe-5">Delete</small>
                    </div>}
                </div>
            </td>
        </tr>
    }

    return <div>
        {!viewCohort ? <>
                <CRow className="mb-3 bg-white p-3">
                    <KPIManagerBanner
                        setTrainerModalVisible={setTrainerModalVisible}
                        setCohortModalVisible={setCohortModalVisible}
                        title={'Cohorts'}
                        totalItems={data?.data?.data?.meta?.totalItems}
                    />
                </CRow>
                <CRow>
                    <CCol md={12}>
                        <table className="table border-0" id="reports-table">
                            <thead className="thead-inverse">
                            <tr className="bg-transparent text-primary">
                                <th>Cohort</th>
                                <th>Batch</th>
                                <th>Year</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.data?.data?.items?.map((item, index) => (
                                <TableRow data={item} key={index}/>
                            ))}
                            </tbody>
                        </table>
                        <TableNavigation
                            page={page}
                            setLimit={setLimit}
                            setPage={setPage}
                            totalPages={data?.data?.data?.meta?.totalPages}
                        />
                    </CCol>
                </CRow>
            </>
            :
            <CohortBeneficiaries
                cohort={cohort}
                setViewCohort={setViewCohort}
                cohortId={cohortId}
            />
        }

        <AddCohortModal
            setVisible={setCohortModalVisible}
            visible={cohortModalVisible}
        />

        <AddTrainerModal
            setVisible={setTrainerModalVisible}
            visible={trainerModalVisible}
        />

        <UploadBeneficiariesModal
            setVisible={setUploadModalVisible}
            visible={uploadModalVisible}
            cohortId={cohortId}
        />
    </div>
}

export default CohortDashboard