/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ChatContactCard from './ChatContactCard'
import useSocket from 'src/hooks/user/useSocket'
import { SOCKET_EVENTS, SOCKET_LISTENERS } from 'src/constant/constants'
import useSocketEvent from 'src/hooks/user/useSocketEvent'
import { CFormInput } from '@coreui/react-pro'
import { ReactComponent as SearchFilter } from '../../../assets/icons/searchFilter.svg'
import { ReactComponent as JobSearch } from '../../../assets/icons/jobSearch.svg'
import ShowLoading from 'src/components/ShowLoading'

const ChatContactList = ({ selectedUser, setSelectedUser, setComposeEmail }) => {
  const [chatUsers, setChatUsers] = useState([])
  const [searchString, setSearchString] = useState('')
  const { socket } = useSocket()
  const [loadingChatUsers, setloadingChatUsers] = useState(true)
  const urlParams = new URLSearchParams(window.location.search)
  const activeUserId = urlParams.get('activeUserId')

  useEffect(() => {
    setTimeout(() => {
      socket?.emit(SOCKET_EVENTS.GET_CHATS)
    }, 2000)
  }, [])

  useEffect(() => {
    if (activeUserId) {
      const searchUser = chatUsers.find((user) => Number(activeUserId) === user.memberId)
      setSelectedUser(searchUser)
    }
  }, [chatUsers, activeUserId])

  useSocketEvent([
    {
      name: SOCKET_LISTENERS.UPDATED_CHAT_LIST,
      cb: (data) => {
        setChatUsers(data)
        setloadingChatUsers(false)
      },
    },
    {
      name: SOCKET_LISTENERS.RESPONSE_MESSAGE,
      cb: () => {
        socket?.emit(SOCKET_EVENTS.GET_CHATS)
      },
    },
  ])

  const filteredUsers = chatUsers?.filter((user) =>
    user?.fullname?.toLowerCase().includes(searchString),
  )

  return (
    <div className="bg-white">
      <div className="d-flex bg-white small border-bottom shadow-sm align-items-center py-1 px-2 w-100">
        <JobSearch />
        <CFormInput
          type="search"
          id="searchInput"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value.toLowerCase())}
          placeholder="Search"
          aria-describedby="searchInput"
          className="px-1 small smallText text-sm font-weight-light bg-transparent text-black rounded-0 border-white border-0 shadow-none"
        />
        <SearchFilter />
      </div>
      <div
        className="overflow-y-auto border-2 bg-white"
        style={{ maxHeight: '100%', height: '85vh' }}
      >
        {loadingChatUsers ? (
          <ShowLoading />
        ) : filteredUsers?.length ? (
          filteredUsers.map((user, index) => {
            return (
              <ChatContactCard
                key={index}
                user={user}
                isSelected={user?.memberId === selectedUser?.memberId}
                setSelectedUser={setSelectedUser}
                setComposeEmail={setComposeEmail}
              />
            )
          })
        ) : (
          <p
            className="fw-normal text-center py-3 px-1"
            style={{ color: '#7A7474', fontSize: '13px' }}
          >
            You dont have any chats
          </p>
        )}
      </div>
    </div>
  )
}

export default ChatContactList
