import React, { useEffect, useState } from 'react'
import { useGetUserMessages } from '../../../hooks/user/userApiHooks'
import { CCol, CContainer, CForm, CFormInput, CRow } from '@coreui/react-pro'
import ShowLoading from '../../../components/ShowLoading'
import moment from 'moment'
import DisplayMessage from '../../../components/User/DisplayMessage'
import { BsDot } from 'react-icons/bs'
import { useAppContext } from '../../../context/user/UserAppProvider'

const Messages = () => {
  const [messages, setMessages] = useState([])
  const [displayMessage, setDisplayMessage] = useState({
    company: '',
    createdAt: '',
    location: '',
    title: '',
    image: '',
  })
  const [activeMessage, setActiveMessage] = useState(0)
  const [activeTab, setActiveTab] = useState('all')
  const [unread, setUnread] = useState(0)

  const { isLoading, isSuccess, data } = useGetUserMessages()
  const { userId } = useAppContext()

  useEffect(() => {
    setMessages(data?.data?.data?.items)

    if (messages?.length > 0) {
      let message = messages[0]
      const url = message?.url?.substr(message?.url?.lastIndexOf('/') + 1)

      setDisplayMessage({
        company: message?.company,
        createdAt: message?.createdAt,
        location: message?.location,
        title: message?.title,
        image: message?.image,
        url: url,
        id: message?.id,
        latest: message?.latest,
      })
    }
    setUnread(messages?.filter((i) => i?.latest)?.length)
  }, [data?.data?.data?.items, messages])

  if (isLoading) {
    return <ShowLoading />
  }

  const handleClickMessage = (index) => {
    let message = messages[index]
    const url = message?.url?.substr(message?.url?.lastIndexOf('/') + 1)
    setDisplayMessage({
      company: message?.company,
      createdAt: message?.createdAt,
      location: message?.location,
      title: message?.title,
      image: message?.image,
      url: url,
      id: message?.id,
      latest: message?.latest,
    })
    setActiveMessage(index)
  }

  return (
    <>
      {isSuccess && (
        <CContainer className="mb-5">
          <h4 className="pb-3">Messages</h4>
          <CRow className="d-flex flex-row justify-content-between">
            <CCol md={4} className="shadow-sm border rounded py-4 mb-5">
              <h5 className="m-0">All Messages</h5>
              <p className="small-text-gray m-0">
                {messages?.length} {`Message${messages?.length > 1 ? 's' : ''}`}
              </p>

              <CForm onSubmit={() => {}} className="my-4">
                <div className="w-100 border px-2 py-1 rounded d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                  <CFormInput
                    type="text"
                    id="companyName"
                    placeholder="Search"
                    className="bg-transparent border rounded-0 border-white border-0 shadow-none"
                  />
                </div>
              </CForm>

              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex flex-row justify-content-start">
                  <p
                    className={`me-2 small-text-gray cursor-pointer ${
                      activeTab === 'all' && 'active-message-tab'
                    }`}
                    onClick={() => setActiveTab('all')}
                  >
                    All
                  </p>
                  <p
                    className={`small-text-gray cursor-pointer ${
                      activeTab === 'unread' && 'active-message-tab'
                    }`}
                    onClick={() => setActiveTab('unread')}
                  >
                    Unread ({unread})
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <p className="small-text-gray cursor-pointer">Make all as read</p>
                </div>
              </div>
              <div className="messages-pane">
                {messages?.map(({ company, createdAt, location, title, url, latest }, index) => {
                  return (
                    <div key={index} onClick={() => handleClickMessage(index)}>
                      <CRow
                        className={`cursor-pointer py-2 message ${
                          index === activeMessage ? 'active-message' : ''
                        }`}
                      >
                        <CCol md={12}>
                          <div>
                            <div className="d-flex flex-row justify-content-between align-items-start w-100">
                              <div className="w-70">
                                <p className="m-0 small-text-gray text-truncate">{company}</p>
                              </div>
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                <BsDot
                                  className={latest ? `text-primary` : `text-disabled`}
                                  size={25}
                                />
                                <p className="small-text-gray m-0">
                                  {moment(createdAt).format('L')}
                                </p>
                              </div>
                            </div>
                            <div className="w-75">
                              <p className="text-truncate text-black m-0">{title}</p>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                  )
                })}
              </div>
            </CCol>
            {!!displayMessage && (
              <CCol md={8} className="px-3">
                <CCol md={12} className="shadow-sm border rounded p-0">
                  <DisplayMessage message={displayMessage} userId={userId} />
                </CCol>
              </CCol>
            )}
          </CRow>
        </CContainer>
      )}
    </>
  )
}

export default Messages
