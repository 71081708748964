import React, { useEffect } from 'react';

const GoogleTranslate = () => {
    useEffect(() => {
        // Function to initialize Google Translate widget
        const initializeGoogleTranslate = () => {
            if (typeof window.google !== 'undefined' && typeof window.google.translate !== 'undefined') {
                try {
                    new window.google.translate.TranslateElement(
                        {
                            pageLanguage: 'en', // Default page language
                            layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                        },
                        'google_translate_element'
                    );
                } catch (error) {
                    console.error('Error initializing Google Translate:', error);
                }
            } else {
                console.error('Google Translate script is not ready.');
            }
        };

        // Load the Google Translate script only if it hasn't been loaded
        if (!window.googleTranslateElementInit) {
            window.googleTranslateElementInit = initializeGoogleTranslate;

            const script = document.createElement('script');
            script.id = 'google-translate-script';
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            script.async = true;
            script.onload = () => initializeGoogleTranslate(); // Ensure initialization after script load
            document.body.appendChild(script);
        } else {
            // If the script is already loaded, initialize immediately
            initializeGoogleTranslate();
        }

        // Cleanup on unmount (optional)
        return () => {
            const script = document.getElementById('google-translate-script');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return(
        <div className="w-100 d-flex justify-content-end px-2">
            <div id="google_translate_element" className=''></div>
        </div>
    );
};

export default GoogleTranslate;
