import React, {useState} from 'react'
import ProjectPipeline from "./ProjectPipeline";
import TalentPool from "./TalentPool";
import ProjectOverview from "./ProjectOverview";
import {dateFormat} from "../../constant";
import {MessageContextProvider} from "../../context/messaging/MessageProvider";

function ProjectDetails({data, project}) {
    const [tab, setTab] = useState(1)

    return (
        <MessageContextProvider>
            <div className="d-flex justify-content-between align-items-center pt-3">
                <div className='d-flex gap-3'>
                    <h6 className='fw-bolder text-black'>{project?.pipeline_job_title}</h6>
                </div>
                <small className="smaller-text-12 text-black-50">Created {dateFormat(project?.pipeline_created_at)}</small>
            </div>

            <div className="d-flex w-100 border border-bottom-1 gap-2 w-100 border-end-0 border-top-0 border-start-0">
                <div onClick={() => {
                    setTab(1)
                }}
                     className={`${tab === 1 && "fw-bold text-primary border"} cursor-pointer border-bottom-4 border-primary p-2 border-end-0 border-top-0 border-start-0`}>Overview
                </div>
                <div onClick={() => {
                    setTab(2)
                }}
                     className={`${tab === 2 && "fw-bold text-primary border"} cursor-pointer border-bottom-4 border-primary p-2 border-end-0 border-top-0 border-start-0`}>Talent
                    Pool
                </div>
                <div onClick={() => {
                    setTab(3)
                }}
                     className={`${tab === 3 && "fw-bold text-primary border"} cursor-pointer border-bottom-4 border-primary p-2 border-end-0 border-top-0 border-start-0`}>Pipeline
                </div>
            </div>
            <div className='mt-3'>
                {tab === 1 && <ProjectOverview/>}
                {tab === 2 && <TalentPool projectId={project?.pipeline_id} data={data}/>}
                {tab === 3 && <ProjectPipeline data={data}/>}
            </div>
        </MessageContextProvider>
    );
}

export default ProjectDetails;
