import React from 'react'
import './assets/scss/style.scss'
import UserAppProvider from './context/user/UserAppProvider'
import AppRouter from './routing/user/AppRouter'
import { Toaster } from 'react-hot-toast'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            style: {
              color: '#18743F',
            },
          },
          error: {
            style: {
              color: '#BF4141',
            },
          },
        }}
      />
      <AppRouter />
    </>
  )
}

const AppWithContext = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserAppProvider>
        <App />
      </UserAppProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
export default AppWithContext
