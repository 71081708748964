import React, {useEffect, useState} from 'react';
import {
    CForm,
    CFormInput,
    CLoadingButton, CModal,
    CModalBody, CModalHeader,
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {useVerifyParkwayWallet} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useNavigate} from "react-router-dom";
import {useUploadUsers} from "../../hooks/admin/adminApiHooks";

function UploadUsers() {
    const [visible, setVisible] = useState(true)
    const navigate = useNavigate()
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);

        }
    };

    const {
        handleSubmit,
        formState: {errors},
        register
    } = useForm({

    })

    const {isLoading, isSuccess, data, isError, error, mutate} = useUploadUsers()

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }

        if (isSuccess) {
            setVisible(false)
            success_notify('Upload Successful')
            navigate(-1)

        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    const handleUpload = (data) => {

        if (!file) {
            alert("Please select a file first!");
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        // Use React Query's mutate to perform the file upload
        mutate(formData);
    }
    return (
        <>
            <CModal
                backdrop="static"
                alignment="center"
                visible={visible}
                onClose={() => {
                    setVisible(false)
                    navigate(-1)
                }}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setVisible(false)}>
                    <div className="d-flex w-100 justify-content-center text-3 fw-bolder text-center">Upload Users</div>
                </CModalHeader>
                <CModalBody>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className="mt-2 mb-4 fw-medium">Register Users by uploading them using an excel sheet</p>
                            <CForm className="w-75" onSubmit={handleSubmit(handleUpload)}>
                                <div className="mb-2">
                                    <CFormInput
                                        required
                                        type="file"
                                        name="profile"
                                        id="uploadUser"
                                        accept=".xls,.xlsx"
                                        className="text-center"
                                        onChange={handleFileChange}
                                    />

                                </div>

                                <div className="d-flex justify-content-center">
                                    <CLoadingButton loading={isLoading} disabled={isLoading}
                                                    color="primary"
                                                    type="submit"
                                                    className="text-white my-3 px-5"
                                    >
                                        {isLoading ? 'Uploading...' : 'Upload'}
                                    </CLoadingButton>
                                </div>
                            </CForm>
                        </div>
                </CModalBody>

            </CModal>
        </>
    );
}

export default UploadUsers;
