import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {retrieveLoggedInUser} from '../localStorage'

axios.defaults.maxContentLength = 1000 * 1024 * 1024;

const apiKPIClient = axios.create({
    baseURL: 'https://worknation-kpi-development.ogtlprojects.com/api/v1/',
    headers: {
        Accept: 'application/json',
    },
})
apiKPIClient.interceptors.request.use(function (config) {
    const user = retrieveLoggedInUser()
    config.headers.Authorization = user ? `Bearer ${user?.accessToken}` : ''
    return config
})

apiKPIClient.interceptors.request.use((config) => {
    Nprogress.start()
    return config
})
apiKPIClient.interceptors.response.use(
    (response) => {
        Nprogress.done()
        return response
    },
    (error) => {
        Nprogress.done()
        if (error.response?.status === 403) {
            localStorage.clear()
        }
        return Promise.reject(error)
    },
)

const addNewTrainer = async (formData) => {
    const {data, header} = formData
    return await apiKPIClient.post(`instructors/`, data, header?.header)
}

const getAllTrainers = async () => {
    return await apiKPIClient.get(`instructors`)
}

const getAllTrainees = async () => {
    return await apiKPIClient.get(`applicants`)
}

const getKPITrainers = async (page = 1, limit = 10) => {
    return await apiKPIClient.get(`instructors/?page=${page}&limit=${limit}`)
}

const getKPICourses = async () => {
    return await apiKPIClient.get(`courses/`)
}

const getAllCohorts = async (page = 1, limit = 10, search = "") => {
    return await apiKPIClient.get(`cohorts?page=${page}&limit=${limit}&search=${search}`)
}

const addNewCohort = async (formData) => {
    const {data, header} = formData
    return await apiKPIClient.post(`cohorts`, data, header?.header)
}

const uploadBeneficiaries = async ({formData, cohortId}) => {
    return await apiKPIClient.post(`cohort-applicants/cohort/${cohortId}/upload-users`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

const addTrainerToCohortCourse = async (formData) => {
    const {data, header} = formData
    return await apiKPIClient.post(`instructor-cohort-courses`, data, header?.header)
}

const addTraineeToCohort = async (formData) => {
    const {data, header} = formData
    return await apiKPIClient.post(`cohort-applicants`, data, header?.header)
}

const addTraineeToTrainer = async (formData) => {
    const {data, header} = formData
    return await apiKPIClient.post(`applicant-instructor-assignments`, data, header?.header)
}

const getCohortBeneficiaries = async (cohortId) => {
    return await apiKPIClient.get(`cohort-applicants/cohort/${cohortId}/applicants`)
}

const getTrainerTrainees = async (id = 0, page = 1, limit = 10) => {
    return await apiKPIClient.get(`applicant-instructor-assignments/trainees/${id}?page=${page}&limit=${limit}`)
}

const getTrainerCourses = async (instructorId) => {
    return await apiKPIClient.get(`instructor-cohort-courses/cohorts/${instructorId}`)
}

const appKPICalls = {
    addNewTrainer,
    getAllTrainers,
    getAllTrainees,
    getKPITrainers,
    addNewCohort,
    uploadBeneficiaries,
    getAllCohorts,
    addTrainerToCohortCourse,
    addTraineeToCohort,
    addTraineeToTrainer,
    getCohortBeneficiaries,
    getTrainerTrainees,
    getKPICourses,
    getTrainerCourses
}

export default  appKPICalls