import { CAvatar, CCol } from '@coreui/react-pro'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formattedDate, getInitials } from 'src/components/Utility'

export const ChatCount = ({ count = 0 }) => {
  return (
    <div
      style={{ height: '1.6rem', width: '1.6rem', borderRadius: '50%' }}
      className="bgOrangeColor smallText text-white d-flex justify-content-center align-items-center"
    >
      {count}
    </div>
  )
}

const ChatContactCard = ({ user, isSelected, setSelectedUser, setComposeEmail }) => {
  const activeClasses = isSelected ? 'rounded-md' : ''
  const { fullname, role } = user
  const avatarInitials = getInitials(fullname)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const activeUserId = urlParams.get('activeUserId')

  return (
    <div
      className={`my-2 p-2 d-flex gap-2 cursor-pointer ${activeClasses}`}
      style={{
        border: isSelected ? '1px solid #014337' : '',
        borderBottom: !isSelected ? '1px solid #E8E8E8' : '1px solid #014337',
        borderRadius: isSelected ? '8px' : '',
        backgroundColor: isSelected ? '#F9FFFD' : '',
      }}
      onClick={() => {
        if (activeUserId) {
          navigate({ pathname: window.location.pathname })
        }
        setSelectedUser(user)
        setComposeEmail(false)
      }}
    >
      <CCol className="d-flex items-items-start justify-content-center">
        <CAvatar color="primary" textColor="white">
          {avatarInitials}
        </CAvatar>
      </CCol>
      <CCol xs={7} className="pb-4">
        <p className="textPrimary smallText fw-bold" style={{ fontSize: '16px' }}>
          {fullname}
        </p>
        <p className="fw-normal" style={{ color: '#7A7474', fontSize: '13px' }}>
          Job: {role}
        </p>
        {/*<p className="text-muted fst-normal smallText">FCT - Abuja, Nigeria</p>*/}
      </CCol>
      <CCol className="d-flex justify-content-start flex-column align-items-center">
        <p className="text-muted fst-normal" style={{ textWrap: 'wrap', fontSize: '8px' }}>
          {formattedDate(user?.createdAt || '')?.replace('about ', '')}
        </p>

        {!!Number(user?.unseenMessagesCount) && (
          <div className=" mt-3">
            <ChatCount count={user?.unseenMessagesCount} />
          </div>
        )}
      </CCol>
    </div>
  )
}

export default ChatContactCard
