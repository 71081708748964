import React, {useEffect, useState} from "react";
import {CCol, CContainer, CImage, CLoadingButton, CRow} from "@coreui/react-pro";
import {
    BiBriefcase,
    BiCheckCircle, BiEnvelope,
    BiPaperPlane, BiRightArrowAlt
} from "react-icons/bi";
import DummyPic from '../../../assets/images/dummy-profile-pic.png'
import FilterIcon from '../../../assets/icons/filter-icon.svg'
import {
    useGetCandidatePipeline,
    useGetEmployersJobs,
    useGetJobStatQuery, useJobStatusUpdate
} from "../../../hooks/employer/employerApiHooks";
import {fail_notify, success_notify} from "../../../constant";
import DataNotFound from "../../../components/DataNotFound";
import {useAppContext} from "../../../context/user/UserAppProvider";
import TableNavigation from "../../../components/TableNavigation";
// import {debounce} from "lodash";
import {FaAngleDown} from "react-icons/fa";
import ShowLoading from "../../../components/ShowLoading";

const NewEmployerDashboard = () => {
    const {user} = useAppContext()
    const [filterVisible, setFilterVisible] = useState(false)
    const [jobPage, setJobPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState('')
    const [jobType, setJobType] = useState('')
    const [status, setStatus] = useState(null)
    // const [jobTitleSearch, setJobTitleSearch] = useState('')
    const [jobId, setJobId] = useState(null)
    const [dropDown, setDropDown] = useState(false)

    // const debounceSearchHandler = useCallback(debounce(e => {setJobTitleSearch(e.target.value)}, 600), [])
    const {isLoading, data: jobStat, error: errorJobStat, isError: isErrorJobStat} = useGetJobStatQuery()
    let {data, isError, isSuccess, error, isLoading: isJobsLoading} = useGetEmployersJobs(filter)
    const {data: searchData} = useGetCandidatePipeline();
    const {
        mutate,
        isLoading: isStatusLoading,
        error: statusError,
        isError: statusIsError,
        isSuccess: statusIsSuccess,
        data: statusData
    } = useJobStatusUpdate(status, jobId)

    useEffect(() => {
        setFilter(filterQuery())
    }, [limit, jobPage, search, jobType, status])

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, data, isLoading, isError, error?.response?.data?.message])

    useEffect(() => {
        if (isErrorJobStat) {
            fail_notify(errorJobStat?.response?.data?.message)
        }
    }, [errorJobStat?.response?.data?.message, isErrorJobStat])

    // useEffect(() => {
    //     refetch()
    // }, [jobTitleSearch]);

    useEffect(() => {
        if (statusIsError) {
            fail_notify(statusError?.response?.data?.message)
        }
        if (statusIsSuccess) {
            success_notify('Successful')
        }
    }, [statusIsSuccess, statusData, isStatusLoading, statusIsError, mutate])

    const filterQuery = () => {
        let query = `limit=${limit}&page=${jobPage}`
        if (!!search) {
            query += `&search=${search}`
        }
        if (!!jobType) {
            query += `&jobType=${jobType}`
        }
        if (!!status) {
            query += `&isActive=${status}`
        }
        return query
    }

    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }

    const handleJobStatus = (id, status) => {
        setJobId(id)
        mutate({"status": status})
    }

    if (isJobsLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <h3 className="m-0">Dashboard</h3>
                    <p className="gray-paragrap">Welcome {user?.data?.name || ''}</p>
                </div>
                {/*<div>*/}
                {/*    <input type="text" className="form-control px-3 py-2" placeholder="Search job title"*/}
                {/*           style={{width: '350px'}} onChange={debounceSearchHandler} />*/}
                {/*</div>*/}
            </div>
            <CRow className="mt-5">
                <CCol md={12}>
                    <div>
                        <h6>PINNED DATA</h6>
                    </div>
                </CCol>
            </CRow>
            <CRow className="mb-5">
                <CCol md={8}>
                    <div>
                        <div className="d-flex flex-row justify-content-between">
                            <div
                                className="d-flex flex-row justify-content-between align-items-center w-50 p-4 bg-white border-radius-12 shadow-sm">
                                <div className="d-flex flex-row align-items-center">
                                    <BiBriefcase size={42}/>
                                    <p className="gray-paragraph">Job Posted</p>
                                </div>
                                <h5 className="bg-grey p-2 border-r-5 border-l-5">
                                    <strong>{jobStat?.data?.data?.jobs}</strong></h5>
                            </div>
                            <div
                                className="d-flex flex-row justify-content-between align-items-center w-50 p-4 bg-white border-radius-12 shadow-sm ms-3">
                                <div className="d-flex flex-row align-items-center">
                                    <BiPaperPlane size={42}/>
                                    <p className="gray-paragraph">Applications Received</p>
                                </div>
                                <h5 className="bg-grey p-2 border-r-5 border-l-5">
                                    <strong>{jobStat?.data?.data?.applicants}</strong></h5>
                            </div>
                            <div
                                className="d-flex flex-row justify-content-between align-items-center w-50 p-4 bg-white border-radius-12 shadow-sm ms-3">
                                <div className="d-flex flex-row align-items-center">
                                    <BiCheckCircle size={42}/>
                                    <p className="gray-paragraph">Hiring Success</p>
                                </div>
                                <h5 className="bg-grey p-2 border-r-5 border-l-5">
                                    <strong>{jobStat?.data?.data?.hiringSuccess}%</strong></h5>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center my-3">
                        <h6>ACTIVE JOB POSTS</h6>
                        <div className="border border-3 px-3 py-2 cursor-pointer" onClick={() => setFilterVisible(true)}
                             onMouseLeave={() => setFilterVisible(false)}>
                            <p>FILTER BY <CImage src={FilterIcon}/></p>
                            {filterVisible && <div className="w-30 bg-white border border-1 p-3 posts-filter">
                                <h6>Filter By</h6>
                                <div className="d-flex flex-row justify-content-between gap-3 my-3">
                                    <input type="text" className="form-control" placeholder="Search job"
                                           onChange={(e) => setSearch(e.target.value)}/>
                                </div>
                                <div className="d-flex flex-row justify-content-between gap-3 my-3">
                                    <select className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                        <option value="" selected>Status</option>
                                        <option value="true">Open</option>
                                        <option value="false">Close</option>
                                    </select>
                                    <select className="form-control" onChange={(e) => setJobType(e.target.value)}>
                                        <option value="" selected>Job Type</option>
                                        <option value="Remote">Remote</option>
                                        <option value="OnSite">OnSite</option>
                                        <option value="Hybrid">Hybrid</option>
                                        <option value="Full Time">Full Time</option>
                                        <option value="Part Time">Part Time</option>
                                        <option value="Internship">Internship</option>
                                    </select>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div>
                        {data?.data?.data?.items?.length > 0 ?
                            <>
                                <table className="table" id="reports-table">
                                    <thead className="thead-inverse">
                                    <tr className="bg-transparent">
                                        <th className="text-primary">Posted On</th>
                                        <th className="text-primary">Job</th>
                                        <th className="text-primary">Closing On</th>
                                        <th className="text-primary">Status</th>
                                        <th className="text-primary">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.data?.data?.items.map(({
                                                                      id,
                                                                      createdAt,
                                                                      title,
                                                                      closingDate,
                                                                      applyForJob,
                                                                      isActive
                                                                  }, index) => (
                                        <tr key={index}>
                                            <td>{dateFormat(createdAt)}</td>
                                            <td>{title}</td>
                                            <td>{dateFormat(closingDate)}</td>
                                            <td>
                                                <div className="">
                                                    <CLoadingButton
                                                        loading={isStatusLoading && jobId === id}
                                                        onClick={() => {
                                                            setJobId(id)
                                                            setDropDown(!dropDown)
                                                        }}
                                                        className={`${isActive ? "bg-blue-500" : "bg-danger"} btn-sm rounded rounded-0 text-white`}>{isActive ? "Open" : "Close"}
                                                        &nbsp; {!(isStatusLoading && jobId === id) &&
                                                            <FaAngleDown/>}
                                                    </CLoadingButton>
                                                    {(dropDown && jobId === id) &&
                                                        <div style={{
                                                            minWidth: "150px",
                                                            position: "absolute",
                                                            zIndex: "1000"
                                                        }}
                                                             className="position-absolute rounded shadow p-1 shadow-md mt-1 bg-white">
                                                            <div onClick={() => {
                                                                if (isActive) {
                                                                    return
                                                                }
                                                                handleJobStatus(id, true)
                                                            }}>Open
                                                            </div>
                                                            <div onClick={() => {
                                                                if (!isActive) {
                                                                    return
                                                                }
                                                                handleJobStatus(id, false)
                                                            }}>Close
                                                            </div>
                                                        </div>}
                                                </div>
                                            </td>
                                            <td><a href={`/employer/job-listing/${id}`}>View Details</a></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <TableNavigation totalPages={data?.data?.data?.items?.length / limit}
                                                 setPage={setJobPage} page={jobPage} setLimit={setLimit}/>
                            </>
                            : <DataNotFound/>}
                    </div>
                </CCol>
                <CCol md={4} className="shadow-sm bg-white border-radius-12 p-3">
                    <div className="d-flex flex-row justify-content-between">
                        <div>
                            <h6 className="m-0">JOB APPLICATION PIPELINE</h6>
                            <p className="small-text-gray">{searchData?.data?.data?.meta?.totalItems} Results</p>
                        </div>
                        <p><a href="/">View all</a></p>
                    </div>
                    <div className="mt-2">
                        {searchData?.data?.data?.items?.map(({
                                                                 profile,
                                                                 firstName,
                                                                 lastName,
                                                                 otherName,
                                                                 skillSet
                                                             }, index) => (
                            <div key={index}>
                                <CRow>
                                    <CCol md={8}>
                                        <div className="d-flex flex-row align-items-center">
                                            <CImage src={profile?.passportUrl || DummyPic} width={40} height={40}
                                                    className="image-contain rounded-circle"/>
                                            <div className="ms-1">
                                                <h6 className="m-0 p-0 text-primary">{firstName} {lastName} {otherName}</h6>
                                                <p className="m-0 p-0 xs-gray-text">{profile?.occupation}</p>
                                                <p className="m-0 p-0 xs-gray-text">{profile?.localGovernmentOfResidence} {profile?.stateOfResidence}</p>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol md={4}>
                                        <button className="btn btn-primary btn-sm text-white">Next Stage</button>
                                        <BiEnvelope size={24} className="ms-1"/>
                                    </CCol>
                                </CRow>
                                <div>
                                    <CRow className="my-3">
                                        <CCol md={3}>
                                            <h6><strong>Experience:</strong></h6>
                                        </CCol>
                                        <CCol md={9}>
                                            {profile?.workExperiences?.map(({
                                                                                jobTitle,
                                                                                companyName,
                                                                                startDate,
                                                                                endDate
                                                                            }, index) => (
                                                <p className="small-text-gray text-truncate w-100" key={index}>
                                                    <BiRightArrowAlt size={12}/>
                                                    {jobTitle} at {companyName} - {startDate.substr(0, 4)} - {endDate?.substr(0, 4)}
                                                </p>
                                            ))}
                                        </CCol>
                                    </CRow>
                                    <CRow className="my-3">
                                        <CCol md={3}>
                                            <h6><strong>Education:</strong></h6>
                                        </CCol>
                                        <CCol md={9}>
                                            {profile?.educations?.map(({
                                                                           degree,
                                                                           schoolName,
                                                                           startDate,
                                                                           endDate
                                                                       }, index) => (
                                                <p className="small-text-gray text-truncate w-100" key={index}>
                                                    <BiRightArrowAlt
                                                        size={12}/>{degree} - {startDate?.substr(0, 4)} - {endDate?.substr(0, 4)}<br/>{schoolName}
                                                </p>
                                            ))}
                                        </CCol>
                                    </CRow>
                                    <CRow className="my-3">
                                        <CCol md={3}>
                                            <h6><strong>Skill Test:</strong></h6>
                                        </CCol>
                                        <CCol md={9}>
                                            {skillSet?.[0]?.skills?.map(({title, yearOfExperience}, index) => (
                                                <p className="small-text-gray text-truncate" key={index}>
                                                    {title} - {yearOfExperience}
                                                </p>
                                            ))}
                                        </CCol>
                                    </CRow>
                                </div>
                                <hr/>
                            </div>
                        ))}
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default NewEmployerDashboard;