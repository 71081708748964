import {
    CFormCheck, CLoadingButton,
    CModal,
    CModalBody,
} from "@coreui/react-pro";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useUpdateWalletNotification} from "../../hooks/user/userApiHooks";
import {fail_notify} from "../../constant";
import {isAndroid, isIOS, isMobile} from "react-device-detect";


const NotificationModal = () => {
    const {user, storeUser} = useAppContext()
    const [visible, setVisible] = useState(false)
    const [isChecked, setIsChecked] = useState(true);
    const {isLoading, isSuccess, data, isError, error, mutate} = useUpdateWalletNotification()

    const handleClick = ()=>{
        let data = {accepted: isChecked}
        mutate(data)
        setVisible(false)

    }
    useEffect(() => {
        if (user?.data?.parkway?.parkwayTerms  === null){
            setVisible(true)
            return
        }
        setVisible(false)

    }, [])


    useEffect(() => {

        if (isError) {
            fail_notify("An error occurred, Please try again")
        }

        if(isSuccess){
            let updatedObject = {...user}
            updatedObject.data.parkway.parkwayTerms = isChecked
            storeUser(updatedObject)
            setVisible(false)

            if(isChecked){
                if(isAndroid){
                    window.open("https://play.google.com/store/apps/details?id=com.parkway.yurwallet", "_blank");
                    return
                }

                if(isIOS){
                    window.open("https://apps.apple.com/app/parkway-wallet/id6451072719", "_blank");
                    return;
                }
                //window.open("https://play.google.com/store/apps/details?id=com.parkway.yurwallet", "_blank");
                window.open("https://parkwaywalletapp.page.link/?link=https%3A%2F%2Fwallet.parkway.ng%3FreferralId%3DWorkNationApp&apn=com.parkway.yurwallet&isi=6451072719&ibi=com.parkway.yurwallet", "_blank");

            }
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])


    return (<>
        <CModal backdrop="static" alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalBody
                className="p-5 text-white"
                style={{backgroundImage: "linear-gradient(to right, #2A3362, #236152)"}}
            >
                <h2 className="text-2">NOTIFICATION!!!</h2>
                <div className="mb-3">Dear <strong>{user?.data.firstName} {user?.data.lastName},</strong> <br/></div>
                <p className="text-justify">
                    We are thrilled to inform you about upcoming sponsored training programs available for you! As one of
                    our valued beneficiaries, you have the chance to benefit from various sponsorships that can boost
                    your professional growth.
                </p>
                <div className="mt-2">
                <p className="text-justify">
                    To seize these incredible opportunity and possibly receive stipends and allowances, kindly click
                    &acute;OK&acute; to be registered on Parkway Wallet.
                </p>
                </div>
                    <div className="mt-2">
                 <p className="text-justify">
                     If you are not interested, please feel free to uncheck the box below. Once
                     you&acute;ve made your selection, click &acute;OK&acute; to proceed.

                 </p>

                </div>
                <div className="pt-2">
                    <strong>Best regards,</strong>
                    <p>The WorkNation Team</p>
                </div>
                <div className="d-flex justify-content-between align-items-center text-primary pt-2">

                    <CFormCheck
                        id="flexCheckDefault"
                        label="I agree to be signed up"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        className="text-white fs-5"
                    />
                    <CLoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        className="bg-white text-primary btn-lg"
                        onClick={() => handleClick()}>OK</CLoadingButton>
                </div>
            </CModalBody>
        </CModal>
    </>)
}

export default NotificationModal
