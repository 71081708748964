import { AES } from 'crypto-js'
import { enc } from 'crypto-js'
import { formatDistanceToNow, parseISO } from 'date-fns'
export const encryptText = (text, password) => {
    return AES.encrypt(text, password).toString()
}
export const decryptText = (encryptedText = null, password = null) => {
  if (!encryptedText || !password) {
    return ''
  }
  return AES.decrypt(encryptedText, password).toString(enc.Utf8)
}

export const formattedDate = (dateString) => {
  if (!dateString) {
    let date = new Date();
    dateString = date.toISOString();
  }

  const date = parseISO(dateString)
  return formatDistanceToNow(date, { addSuffix: true })
}

export const getInitials = (name) =>
  name
    ?.split(' ')
    ?.map((name) => name.charAt(0))
    ?.slice(0, 2)
    .join('')
