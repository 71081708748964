import React from 'react'
import { CHAT_TYPE } from 'src/constant/constants'
import { useAppContext } from 'src/context/user/UserAppProvider'

const activeClass = 'fw-bold shadow'
// const actions = ['Delete', 'Archive', 'Search']

const ChatSectionAppHeader = ({ chatType, setChatType, selectedUser, setComposeEmail }) => {
  const isEmailActive = chatType === CHAT_TYPE.email ? activeClass : 'shadow-none'
  const isChatActive = chatType === CHAT_TYPE.chat ? activeClass : 'shadow-none'
  const { user } = useAppContext()

  return (
    <header className="d-flex align-items-center justify-content-between p-2">
      <div>
        <p className="fw-bold fs-6">Application for {selectedUser?.role || 'Role'}</p>
        <p className="fw-normal" style={{ color: '#7A7474', fontSize: '13px' }}>
          Job: {selectedUser?.role || 'Role'}
        </p>
      </div>

      {chatType === CHAT_TYPE.email && selectedUser && user?.data?.role !== 'User' ? (
        <button
          className="border-0 text-white px-2 py-1 rounded-1 smallText"
          style={{ backgroundColor: '#1FAB89' }}
          onClick={() => setComposeEmail(true)}
        >
          COMPOSE MESSAGE
        </button>
      ) : null}
      <div className="d-flex align-items-center gap-2">
        <div
          className="h-3 p-2 d-flex align-items-center justify-content-between gap-2"
          style={{ backgroundColor: '#FCFCFC' }}
        >
          <button
            style={{ width: '84px' }}
            onClick={() => setChatType(CHAT_TYPE.email)}
            className={`bg-white px-2 py-1 border-0 rounded-1 btn text-primary-dark  ${isEmailActive}`}
          >
            Email
          </button>
          <button
            style={{ width: '84px' }}
            onClick={() => setChatType(CHAT_TYPE.chat)}
            className={`bg-white px-2 py-1 border-0 rounded-1 btn text-primary-dark ${isChatActive}`}
          >
            Chat
          </button>
        </div>
        {/* <div>
          <CDropdown>
            <CDropdownToggle color="white" className={'shadow-none'} caret={false}>
              <BsThreeDotsVertical className={''} size={20} />
            </CDropdownToggle>
            <CDropdownMenu>
              {actions.map((action) => {
                return (
                  <CDropdownItem key={action} className={'p-0 bg-red'}>
                    {action}
                  </CDropdownItem>
                )
              })}
            </CDropdownMenu>
          </CDropdown>
        </div> */}
      </div>
    </header>
  )
}

export default ChatSectionAppHeader
