import {
    CCollapse,
    CContainer,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
    CNavLink,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import Logo from '../assets/brand/WN-logo.svg'
import { Link } from 'react-router-dom'
import GoogleTranslate from "./GoogleTranslate";
// import {useAppContext} from "../context/user/UserAppProvider";

const Navbar = () => {
    const [visible, setVisible] = useState(false)
    return (
        <>
            <div className='fixed-top bg-white'>
                <GoogleTranslate/>
                <CNavbar expand="lg" className="nav-header bg-white">
                    <CContainer className="d-flex justify-content-between align-items-center px-5">
                        <CNavbarBrand href="/">
                            <img src={Logo} alt="logo" />
                        </CNavbarBrand>
                        <CNavbarToggler onClick={() => setVisible(!visible)} />
                        <CCollapse
                            className="navbar-collapse"
                            visible={visible}
                            style={{ justifyContent: 'end' }}
                        >
                            <CNavbarNav className="d-flex justify-content-between align-items-center">
                                <CNavItem>
                                    <CNavLink href="/#about" className="mx-3 text-decoration-none text-body">
                                        About
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink href="/#talents" className="mx-3 text-decoration-none text-body">
                                        Talents
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink href="/#employers" className="mx-3 text-decoration-none text-body">
                                        Employers
                                    </CNavLink>
                                </CNavItem>

                                <CDropdown className="" dark component="li" variant="nav-item">
                                    <CDropdownToggle className="text-body">Resources</CDropdownToggle>
                                    <CDropdownMenu className="bg-white">
                                        <Link className="drop-down-link dropdown-item" to="/jobs">
                                            Jobs
                                        </Link>
                                        <Link className="drop-down-link dropdown-item" to="/trainings">
                                            Training & Programs
                                        </Link>
                                    </CDropdownMenu>
                                </CDropdown>

                                <CNavItem>
                                    <CNavLink href="/faqs" className="mx-3 text-decoration-none text-body">
                                        Faqs
                                    </CNavLink>
                                </CNavItem>

                                <CDropdown className="" dark component="li" variant="nav-item">
                                    <CDropdownToggle className="text-body login-btn">Login</CDropdownToggle>
                                    <CDropdownMenu className="bg-white">
                                        <Link className="drop-down-link dropdown-item" to="/login">
                                            Beneficiaries Login
                                        </Link>
                                        <Link className="drop-down-link dropdown-item" to="/auth/login">
                                            Employer Login
                                        </Link>
                                    </CDropdownMenu>
                                </CDropdown>

                                <CDropdown>
                                    <CDropdownToggle className="reg-btn bg-primary text-white ms-2 fw-light">
                                        Register
                                    </CDropdownToggle>
                                    <CDropdownMenu>
                                        <Link className="drop-down-link dropdown-item" to="/user/signup">
                                            As Applicant
                                        </Link>
                                        <Link className="drop-down-link dropdown-item" to="/employer/signup">
                                            As Employer | As Sponsor
                                        </Link>
                                    </CDropdownMenu>
                                </CDropdown>
                            </CNavbarNav>
                        </CCollapse>
                    </CContainer>
                </CNavbar>

            </div>
        </>
    )
}

export default Navbar
