import {apiClient} from './applicant'

// This endpoint is allows the user to build a cv
const CVBuilder = async (formData) => {
    let headers = {
        'Content-Type': "application/json"
    }
    if (formData.isFinal) {
        headers = {
            'Content-Type': 'multipart/form-data',
        }
    }
    return await apiClient.post('cv-builder', formData, {headers})
}

const generatePDF = async (formData) => {
    return await apiClient.post('cv-builder/pdf', formData, {responseType: "arraybuffer"})
}

// this endpoint gets the user cv_builder data
const getCVBuilder = async () => {
    return await apiClient.get(`cv-builder/user`)
}

const fetchUserCv = async () => {
    return await apiClient.get('job-cv/uploads')
}

const uploadUserCV = async (formData) => {
    return await apiClient.post('job-cv/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

const deleteUserCV = async (id) => {

    return await apiClient.delete(`job-cv/uploads/${id}`)
}

const cvBuilderApiCalls = {
    generatePDF,
    CVBuilder,
    getCVBuilder,
    uploadUserCV,
    fetchUserCv,
    deleteUserCV,
}

export default cvBuilderApiCalls
