import { CButton, CFormCheck, CFormTextarea, CRow } from '@coreui/react-pro'
import React from 'react'
import { BiCheck, BiPlus } from 'react-icons/bi'
import DropDownQuestionAccordion from './DropDownQuestionAccordion'

const JobScreeningQuestion = ({
  gotoPage,
  questions,
  toggleQuestion,
  transformedQuestion,
  handleAnswerChange,
  handleFieldChange,
  fieldValues,
}) => {
  const { emailTemplate } = fieldValues
  return (
    <div className=" p-2 h-auto">
      <p className="text-black fs-3 fw-bolder">
        <strong>Job Screening Questions</strong>
      </p>

      {questions?.map((question, index) => (
        <DropDownQuestionAccordion
          key={`question-${question.id}-${index}`}
          question={question}
          handleAnswerChange={handleAnswerChange}
          index={index + 1}
        />
      ))}

      <CRow className="mt-4">
        <p className=" fs-6 fw-bolder">
          <strong>Add Screening Questions:</strong>
        </p>

        <div className="d-flex align-items-start justify-content-start py-2 flex-wrap gap-3">
          {transformedQuestion?.map(({ question, id }, index) => {
            const activeQuestion = questions?.find((item) => item?.id === id)
            return (
              <button
                key={index}
                onClick={() => toggleQuestion(id)}
                style={{
                  cursor: 'pointer',
                  color: '#909090',
                  opacity: activeQuestion ? '0.6' : '1',
                  fontSize: '.9rem',
                }}
                className="inputBorder py-1 ps-2 pe-3 rounded-4 d-flex align-items-center justify-content-start gap-2"
              >
                {activeQuestion ? <BiCheck /> : <BiPlus size={17} />}{' '}
                <span className="text-capitalize">{question}</span>
              </button>
            )
          })}
        </div>
      </CRow>

      <CRow className="mt-4">
        <p className=" fs-6 fw-bolder mb-2">
          <strong>Qualification Settings</strong>
        </p>
        <CFormCheck
          id="qualificationSettings"
          label="Filter out applications and send rejection mails to those who do not meet any must-have qualifications."
          name="qualificationSettings"
          className="fw-light d-flex align-items-center text-muted blockquote-footer small gap-2 ps-2"
          style={{ height: '20px', width: '20px', color: 'red' }}
        />
      </CRow>

      <CFormTextarea
        className="my-4"
        id="emailTemplate"
        name="emailTemplate"
        value={emailTemplate}
        onChange={(e) => handleFieldChange({ name: 'emailTemplate', value: e?.target?.value })}
        rows={5}
      ></CFormTextarea>

      <CRow className="mt-5  d-flex justify-content-between align-items-center gap-3">
        <CButton
          className="bg-transparent shadow-none"
          style={{ color: '#1FAB89', width: '200px', fontSize: '1rem', border: 'none' }}
          onClick={() => gotoPage(0)}
        >
          Go Back
        </CButton>

        <CButton
          style={{ background: '#1FAB89', width: '250px', fontSize: '1rem' }}
          size="lg"
          className="text-white"
          onClick={() => {
            gotoPage(2)
          }}
        >
          PREVIEW & PUBLISH
        </CButton>
      </CRow>
    </div>
  )
}

export default JobScreeningQuestion
