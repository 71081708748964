import React from 'react'

const DateSeperator = ({ date }) => {
  return (
    <div className="d-flex justify-content-between align-items-center my-2">
      <div className="border-bottom w-100 " />
      <div className="smallText px-2 gray200" style={{ textWrap: 'nowrap' }}>
        {date}
      </div>
      <div className="border-bottom w-100" />
    </div>
  )
}

export default DateSeperator
