import React, { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../context/user/UserAppProvider'
import { decryptText } from '../../components/Utility'
import ShowLoading from '../../components/ShowLoading'
import {
  CFormInput,
  CLoadingButton,
} from '@coreui/react-pro'

const LMSLogin = () => {
  const { user, userRole, stakeholderRoles} = useAppContext()
  const [moodleEmail, setMoodleEmail] = useState(user?.data?.email || "")
  const [moodlePass, setMoodlePass] = useState(decryptText(user?.LMSID, user?.accessToken) || "")
  const formRef = useRef(null)

  useEffect(() => {
    if (user && (!userRole.includes('Super Administrator') || !userRole.includes('Support') || stakeholderRoles)) {
      setMoodleEmail(user?.data?.email)
      setMoodlePass(decryptText(user?.LMSID, user?.accessToken))
      if (formRef.current) {
        formRef.current.submit()
      }
    }
  }, [])

  const handleEmailChange = (e) => {
    setMoodleEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setMoodlePass(e.target.value)
  }
  return (
    <>
      {(user && (!userRole.includes('Super Administrator') || !userRole.includes('Support') || stakeholderRoles)) ? (
        <>
          <ShowLoading />
          <form ref={formRef} action="https://wnacademy.worknation.ng/login/index.php" method="POST">
            <input type="hidden" name="username" value={moodleEmail} placeholder="Email" />
            <input type="hidden" name="password" value={moodlePass} placeholder="Password" />
          </form>
        </>
      ) : (
        <div
          className="d-flex flex-column justify-content-center bg-grey px-1"
          style={{ height: '100vh' }}
        >
          <center>
            <div className="card p-5" style={{ maxWidth: '480px' }}>
              <h2 className="fw-bolder">Log In</h2>
              <form noValidate action="https://wnacademy.worknation.ng/login/index.php" method="POST">
                <center className="d-flex flex-column gap-4">
                  <div className="">
                    <CFormInput
                      type="text"
                      id="username"
                      placeholder="Enter Your Email Address"
                      name="username"
                      value={moodleEmail}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div>
                    <CFormInput
                      type="password"
                      id="password"
                      placeholder="Enter Your Password"
                      name="password"
                      value={moodlePass}
                      onChange={handlePasswordChange}
                    />
                  </div>
                </center>
                <div className="pb-3">
                  <CLoadingButton type="submit" className="reg-btn mt-4 px-4 w-100 py-2">
                    Log in
                  </CLoadingButton>
                </div>
              </form>
            </div>
          </center>
        </div>
      )}
    </>
  )
}

export default LMSLogin
