import React from 'react'

const CheckedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#06A256" />
      <path
        d="M14.473 6.80657C14.411 6.74409 14.3373 6.69449 14.256 6.66065C14.1748 6.6268 14.0876 6.60938 13.9996 6.60938C13.9116 6.60938 13.8245 6.6268 13.7433 6.66065C13.662 6.69449 13.5883 6.74409 13.5263 6.80657L8.55964 11.7799L6.47297 9.68657C6.40863 9.62441 6.33267 9.57554 6.24943 9.54274C6.16619 9.50993 6.07731 9.49385 5.98786 9.49539C5.8984 9.49694 5.81013 9.51609 5.72808 9.55176C5.64602 9.58742 5.5718 9.63889 5.50964 9.70324C5.44748 9.76759 5.39861 9.84355 5.3658 9.92679C5.333 10.01 5.31691 10.0989 5.31846 10.1884C5.32001 10.2778 5.33916 10.3661 5.37482 10.4481C5.41049 10.5302 5.46196 10.6044 5.52631 10.6666L8.08631 13.2266C8.14828 13.2891 8.22202 13.3387 8.30326 13.3725C8.3845 13.4063 8.47163 13.4238 8.55964 13.4238C8.64765 13.4238 8.73479 13.4063 8.81603 13.3725C8.89727 13.3387 8.971 13.2891 9.03297 13.2266L14.473 7.78657C14.5406 7.72415 14.5946 7.64838 14.6316 7.56404C14.6685 7.47971 14.6876 7.38864 14.6876 7.29657C14.6876 7.20451 14.6685 7.11344 14.6316 7.0291C14.5946 6.94477 14.5406 6.869 14.473 6.80657Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckedIcon
