export const OPEN_FOR_INTERVIEW = 'Open for interview'
export const PHONE_SCREENING = 'Phone Screening'
export const INTERVIEW = 'Interview Scheduling'
export const ARCHIVE = 'Archive'
export const HOLD = 'Hold'
export const REJECT = 'Reject'
export const HIRE = 'Hire'
export const CHAT_USER = 'chat-user'

// Socket Events
export const SOCKET_EVENTS = {
  CHAT_MESSAGES: 'chat_messages',
  CREATE_MESSAGE: 'create_message',
  GET_CHATS: 'get_chats',
  SEEN_MESSAGE: 'seen_message',
  CREATE_CHAT: 'create_chat',
}

// Socket Listeners
export const SOCKET_LISTENERS = {
  GET_ALL_CHAT_MESSAGES: 'get_all_chat_messages',
  RESPONSE_MESSAGE: 'response_message',
  UPDATED_CHAT_LIST: 'updated_chat_list',
  CHAT_CREATED: 'chat_created',
}

export const CHAT_TYPE = { email: 'EMAIL', chat: 'CHAT' }
export const DUMMY_CHAT_USERS = [
  { name: 'Mustapha Obasanjo', role: 'Front End Developer' },
  { name: 'Samuel Osinbajo', role: '.Net Developer' },
  { name: 'Namadi Sambo Aliyu', role: 'UI/UX Designer' },
  { name: 'Fatima Aliyu', role: 'Human Resource Manager' },
  { name: 'Esther Joseph Uduak', role: 'Legal Officer' },
  { name: 'Summayya Jubril', role: 'Sales Manager' },
  { name: 'Obed Philemon', role: 'Front End Developer' },
  { name: 'Najiu Ken Osagie', role: 'Backend Engineerr' },
]

export const jobTypes = [
  { id: 1, title: 'Full Time' },
  { id: 2, title: 'Part Time' }, // {id: 3, title: 'Contractual'},
  { id: 4, title: 'Remote' },
  { id: 5, title: 'Hybrid' },
  { id: 6, title: 'Internship' },
]

export const experience = [
  { id: 1, title: 'Less than 1 year' },
  { id: 2, title: '1-3 years' },
  {
    id: 3,
    title: '3-5 years',
  },
  { id: 4, title: 'More than 5 years' },
]

export const softSkillPreference = [
  { id: 1, title: 'Adaptability' },
  { id: 2, title: 'Communication' },
  { id: 4, title: 'Problem Solving' },
  { id: 5, title: 'Relationship Building' },
]

export const employmentTypes = [
  { value: 'Full_time', label: 'Full-time' },
  { value: 'Part_time', label: 'Part-time' },
  { value: 'Contract', label: 'Contract' },
  { value: 'Temporary', label: 'Temporary' },
  { value: 'Remote', label: 'Remote' },
  { value: 'Self_employed', label: 'Self-employed' },
  { value: 'Hybrid', label: 'Hybrid' },
  // { value: "internship", label: "Internship" },
  // { value: "freelance", label: "Freelance" },
  // { value: "seasonal", label: "Seasonal" },
  // { value: "volunteer", label: "Volunteer" },
  // { value: "apprenticeship", label: "Apprenticeship" },
  // { value: "zero_hours", label: "Zero Hours Contract" },
  // { value: "casual", label: "Casual" },
  // { value: "consultant", label: "Consultant" }
]

export const jobFunctionsOPtions = [
  {
    value: 'Full-Stack',
    label: 'Full-Stack',
  },
  {
    value: 'Frontend',
    label: 'Frontend',
  },
  {
    value: 'Backend',
    label: 'Backend',
  },
]

export const companyTypes = [
  { value: 'Startup', label: 'Startup' },
  { value: 'Enterprise', label: 'Enterprise' },
  { value: 'Consultancy', label: 'Consultancy' },
  { value: 'Saas', label: 'SaaS (Software as a Service)' },
]

export const jobSteps = ['Job Overview', 'Job Screening Question', 'Preview & Publish']


export const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const SOCKET_BASE_URL = 'wss://worknation-chat-development.ogtlprojects.com'
