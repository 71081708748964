import { CCard, CCardBody, CCardHeader, CCol, CRow, CBadge, CButton } from '@coreui/react-pro'
import React, { useState } from 'react'
import logo from '../../assets/brand/WN-logo.svg'
import { CImage } from '@coreui/react-pro'
import { BiDotsHorizontal } from 'react-icons/bi'
import 'react-quill/dist/quill.snow.css'

const altDescription =
  'Founded in [Year], [Your Company Name] is a leading financial technology firm dedicated to revolutionizing the way people manage their finances. Our team of experts, with  diverse backgrounds in finance, technology, and customer service, is committed to delivering cutting-edge products and services that meet the evolving needs of our clients.'

const JobDetails = ({ fieldValues, skills }) => {
  const { seniorityLevel, companyIndustry, description } = fieldValues
  return (
    <div className="mt-4">
      <CRow>
        <CCol md={8} className="border-end ">
          <div className="small">
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {/* {parseAndFormatHTML(description) || altDescription} */}
          </div>
        </CCol>
        <CCol md={4}>
          <div>
            <h5 className="fs-6 fw-bold my-1">Seniority Level</h5>
            <p className="small">{seniorityLevel}</p>
          </div>

          <div className="mt-3">
            <h5 className="fs-6 fw-bold my-1">Industry</h5>
            <p className="small">{companyIndustry}</p>
          </div>

          <div className="mt-3">
            <h5 className="fs-6 fw-bold my-1">Skills</h5>
            <p className="small">{skills?.join(', ')}</p>
          </div>
        </CCol>
      </CRow>
    </div>
  )
}

const PreviewPublishJob = ({ fieldValues, skills, gotoPage, onSubmit }) => {
  const { company, jobTitle, employmentType, jobType, jobLocation } = fieldValues
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    onSubmit()
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  return (
    <div className="px-2 h-auto">
      <p className="text-black fs-3 fw-bolder mb-0">
        <strong>Job Preview</strong>
      </p>
      <small className="text-black-50" style={{ fontSize: '13px' }}>
        This is a glimpse of how the job ad will appear to job seekers on the platform.
      </small>

      <div className="mt-3">
        <CCard className="mb-4">
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <div>
              <CImage src={logo} alt="Dangote Logo" height={15} />
              <h2 className="mb-0 fs-4">{jobTitle}</h2>
              <p className="text-muted small mb-0">
                {company} - {jobLocation} Africa
              </p>
            </div>
            <div>
              <span className="text-muted me-3 small">
                <strong>Date Posted:</strong> Sept. 24, 2024
              </span>
              <BiDotsHorizontal />
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow className="d-flex justify-content-between">
              <CBadge color="light" textColor="dark" className="me-2  w-auto">
                {jobType}
              </CBadge>
              <CBadge color="light" textColor="dark" className="me-2  w-auto">
                {employmentType}
              </CBadge>
              <CBadge color="light" textColor="dark" className="me-2  w-auto">
                3 - 5Years Experience
              </CBadge>
              <CBadge color="light" textColor="dark" className="me-2  w-auto">
                NGN350,000 - NGN400,000
              </CBadge>
              <CBadge color="light" textColor="dark" className="w-auto">
                Intermediate
              </CBadge>
            </CRow>
            <JobDetails fieldValues={fieldValues} skills={skills} />
          </CCardBody>
          <CRow className="my-3 px-5 d-flex justify-content-between align-items-center gap-3">
            <CButton
              className="bg-transparent shadow-none"
              style={{ color: '#1FAB89', width: 'auto', fontSize: '.8rem', border: 'none' }}
              onClick={() => gotoPage(1)}
            >
              GO BACK
            </CButton>

            <CButton
              style={{ background: '#1FAB89', width: 'auto', fontSize: '.8rem' }}
              size="lg"
              className="text-white rounded-0 shadow-none text-capitalize"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? 'loaidng' : 'PUBLISH JOB'}
            </CButton>
          </CRow>
        </CCard>
      </div>
    </div>
  )
}

export default PreviewPublishJob
