import React, {useEffect, useState} from 'react'
import Navbar from 'src/components/Navbar'
import Footer from 'src/components/Footer'
import {Outlet, useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";
import ShowLoading from "../../components/ShowLoading";
import {STAKEHOLDERS} from "../../constant/roles";

const PageLayout = () => {
    localStorage.removeItem("redirectUrl");
    const navigate = useNavigate();
    const {userRole, onLogout} = useAppContext();
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (STAKEHOLDERS.includes(userRole)) {
            navigate(`/employer/dashboard`, {
                replace: true,
            });
        } else if (userRole && (userRole === "Super Administrator" || userRole === "Support")) {
            navigate(`/admin/dashboard`, {
                replace: true,
            });
        } else if (userRole === 'User') {
            navigate(`/user/dashboard`, {
                replace: true,
            });
        } else {
            onLogout();
            setShow(false);
        }

    }, [])

    if (show) {
        return <ShowLoading/>
    }
    return (
        <div className="h-100">
            <Navbar/>
            <div className="main">
                <Outlet/>
            </div>
            <div className={'landing-page'}>

                <Footer/>
            </div>
        </div>
    )
}

export default PageLayout
