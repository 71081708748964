import * as React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useAppContext} from '../../context/user/UserAppProvider'
import {SUPER_ADMIN, SUPPORT} from "../../constant/roles";

const PrivateAdmin = () => {
    const {user} = useAppContext()
    const location = useLocation()
    const isAuthenticated = !!user;

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location.pathname}} replace/>;
    }

    if (user?.data?.role === SUPER_ADMIN || user?.data?.role === SUPPORT) {
        return <Outlet/>
    } else {
        return <Navigate to={'/access-denied'} state={{from: location.pathname}} replace/>
    }

}

export default PrivateAdmin
