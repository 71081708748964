import React, {useEffect, useState} from 'react'
import {
    CButton,
    CCol,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle, CImage, CModal, CModalBody, CModalFooter,
    CModalHeader, CModalTitle,
    CRow
} from "@coreui/react-pro";
import Table from "../../components/tables/table";
import {useNavigate} from "react-router-dom";
import {BsThreeDots} from "react-icons/bs";
import {useApproveSponsorship, useGetAllScholarships} from "../../hooks/user/jobApiHooks";
import ShowLoading from "../../components/ShowLoading";
import TableNavigation from "../../components/TableNavigation";

const SponsoredCourses = () => {

    const navigate = useNavigate()
    const [sponsorshipStatus, setSponsorshipStatus] = useState('Pending');
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const {
        isLoading,
        data,
        refetch
    } = useGetAllScholarships(page, limit, sponsorshipStatus)

    const {
        mutate
    } = useApproveSponsorship()

    useEffect(() => {
        refetch()
    }, [page, limit]);

    const handleSelectUser = (title, sponsorshipId) => {
        if (sponsorshipStatus === '' || sponsorshipStatus === 'Pending') {
            return
        }
        // /admin/sponsorship
        navigate(`/admin/sponsored-courses-applicants/${title}/${sponsorshipId}`)
    }

    const headers = [
        {name: "Sponsor", sort: true},
        {name: "Scholarship Type", sort: true},
        {name: "Status"},
        {name: "Course", sort: true},
        {name: "Action"}
    ]

    if (isLoading) {
        return <ShowLoading/>
    }
    const sponsoredCourses = data?.data?.data?.sponsorship?.items
    const stats = data?.data?.data?.approvalStatus

    // const viewSponsor =(item)=>{
    //
    // }
    return (
        <>
            <CContainer className="mt-1">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <h5 className="text-2  ps-1">Sponsored Courses</h5>
                </div>
                <CRow className="py-3">
                    <CCol md={12}>
                        <button
                            className={`btn ${sponsorshipStatus === 'Pending' ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2 ms-2`}
                            onClick={() => setSponsorshipStatus('Pending')}>
                            {`Pending (${stats?.Pending}) `}
                        </button>
                        <button
                            className={`btn ${sponsorshipStatus === 'Approved' ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2`}
                            onClick={() => setSponsorshipStatus('Approved')}>
                            {`Approved (${stats?.Approved})`}
                        </button>
                        <button
                            className={`btn ${sponsorshipStatus === "Rejected" ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2`}
                            onClick={() => setSponsorshipStatus('Rejected')}>
                            {`Rejected (${stats?.Rejected})`}
                        </button>
                    </CCol>
                </CRow>
                <Table headers={headers}>
                    {
                        sponsoredCourses?.map((item, index) => {
                            return <tr key={index}
                                       className={`${sponsorshipStatus === "Approved" ? ' cursor-pointer' : ''} bg-white border`}
                            >
                                <td className="td-single-line fw-medium"
                                    onClick={() => handleSelectUser(item?.title, item.id)}>{item?.sponsor?.name}</td>
                                <td className="fw-medium"
                                    onClick={() => handleSelectUser(item?.title, item.id)}>{item?.sponsorshipType}</td>
                                <td className="fw-medium"
                                    onClick={() => handleSelectUser(item?.title, item.id)}>{item?.approvalStatus}</td>
                                <td className="fw-medium" onClick={() => handleSelectUser(item?.title, item.id)}>{item?.title}</td>
                                <td>
                                    <CDropdown>
                                        <CDropdownToggle color="white" className={'shadow-none'} caret={false}>
                                            <BsThreeDots className={'text-primary'} size={20}/>
                                        </CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem onClick={() => {
                                                setDetail(item)
                                                setVisible(!visible)
                                            }} className={'p-0'}>
                                                View Details
                                            </CDropdownItem>
                                            {
                                                sponsorshipStatus === "Pending" &&
                                                <CDropdownItem className={'p-0'}
                                                               onClick={() => mutate({
                                                                   sponsorshipId: item.id,
                                                                   "isApprove": 'Approved'
                                                               })}
                                                >
                                                    Approve
                                                </CDropdownItem>
                                            }
                                            {
                                                sponsorshipStatus === "Pending" &&
                                                <CDropdownItem
                                                    className={'p-0'}
                                                    onClick={() => mutate({
                                                        sponsorshipId: item.id,
                                                        "isApprove": 'Rejected'
                                                    })}
                                                >
                                                    Reject
                                                </CDropdownItem>
                                            }
                                        </CDropdownMenu>
                                    </CDropdown>
                                </td>
                            </tr>

                        })
                    }
                </Table>
                <TableNavigation setLimit={setLimit} page={page} setPage={setPage} totalPages={sponsoredCourses?.length / limit} />
                <CModal
                    backdrop="static"
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="StaticBackdropExampleLabel">Sponsorship Details</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                <strong>Sponsor Name:</strong> {detail?.sponsor?.name}
                                <br/>
                            </div>
                            <div>
                                <strong>Scholarship Type:</strong> {detail?.paymentType}
                                <br/>
                            </div>
                            <div>
                                <strong>Status:</strong> {detail?.approvalStatus}
                                <br/>
                            </div>
                            <div>
                                <strong>Course:</strong> {detail?.title}
                                <br/>
                            </div>
                            <div>
                                <strong>Purpose and Objectives:</strong> {detail?.objectives || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Relationship to Beneficiaries:</strong> {detail?.relationshipToBeneficiaries || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>No Of Students To Be Sponsored:</strong> {detail?.numberOfSlots || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Sponsorship Amount (₦):</strong> {detail?.sponsorshipAmount || "Nil"}
                                <br/>
                            </div>

                            <div>
                                <strong>Interest Rate:</strong> {detail?.interestRate || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Loan Tenure:</strong> {detail?.loanTenure || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Payment Occurrence:</strong> {detail?.paymentOccurrence || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Recovery Amount:</strong> {detail?.recoveryAmount || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Recovery End Date:</strong> {detail?.recoveryEndDate || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Recovery Occurrence:</strong> {detail?.recoveryOccurrence || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Recovery Start Date:</strong> {detail?.recoveryStartDate || "Nil"}
                                <br/>
                            </div>
                            <div>
                                <strong>Proof Of Financial Capability:</strong> {!detail?.proofOfFinancialCapabilityUrl && "Nil"}
                                {detail?.proofOfFinancialCapabilityUrl && <div>
                                    <a href={detail?.proofOfFinancialCapabilityUrl} target="_blank" rel="noreferrer"
                                       className="px-2">
                                        <CImage src={detail?.proofOfFinancialCapabilityUrl} style={{height: "120px", width: "120px"}}
                                                className="social-image"/>
                                    </a>
                                </div>}
                                <br/>
                            </div>
                            <div>
                                <strong>Proof Of Identity:</strong> {!detail?.proofOfIdentityUrl && "Nil"}
                                {detail?.proofOfIdentityUrl && <div>
                                    <a href={detail?.proofOfIdentityUrl} target="_blank" rel="noreferrer"
                                       className="px-2">
                                        <CImage src={detail?.proofOfIdentityUrl} style={{height: "120px", width: "120px"}}
                                                className="social-image"/>
                                    </a>
                                </div>}
                                <br/>
                            </div>

                            <div>
                                <strong>Letter Of Reference:</strong> {!detail?.letterOfReferenceUrl && "Nil"}
                                {detail?.letterOfReferenceUrl && <div>
                                    <a href={detail?.letterOfReferenceUrl} target="_blank" rel="noreferrer"
                                       className="px-2">
                                        <CImage src={detail?.letterOfReferenceUrl} style={{height: "120px", width: "120px"}}
                                                className="social-image"/>
                                    </a>
                                </div>}
                                <br/>
                            </div>
                        </div>

                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setVisible(false)}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>
            </CContainer>

        </>
    )
}

export default SponsoredCourses
