import * as Yup from 'yup';

export const TinSchema = Yup.object().shape({
  stakeHolderType: Yup.string().required('Please Select User Type'),

  institutionType: Yup.string().when('stakeHolderType', {
    is: (stakeHolderType) => stakeHolderType === 'Institution',
    then: Yup.string().required('Institution Type is required'),
    otherwise: Yup.string().notRequired(),
  }),

  tin: Yup.string().when('stakeHolderType', {
    is: (stakeHolderType) => stakeHolderType !== 'BPO' && stakeHolderType !== 'Recruiting Firm',
    then: Yup.string().required('TIN is required'),
    otherwise: Yup.string().notRequired(),
  }),

  organizationStatus: Yup.string().when('stakeHolderType', {
    is: (stakeHolderType) => stakeHolderType === 'NGO',
    then: Yup.string().required('Organization Status is required'),
    otherwise: Yup.string().notRequired(),
  }),
});