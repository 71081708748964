import { CButton, CCol, CContainer, CImage, CRow } from '@coreui/react-pro'
import React, { useState } from 'react'
import ShowLoading from 'src/components/ShowLoading'
import { useGetLMSCourse } from 'src/hooks/admin/adminApiHooks'
import DummyProfilePic from '../../assets/images/dummy-profile-pic.png'
import { Link } from 'react-router-dom'
import ViewModuleModal from 'src/components/modals/Employer/ViewModuleModal'

const ConvertUserDisplay = ({ data }) => {
  const [isImgError, setImgError] = useState(false)

  return (
    <div className="d-flex flex-row justify-content-start align-items-center p-0">
      {isImgError ? (
        <CImage
          src={DummyProfilePic}
          alt="Profile Picture"
          style={{
            width: '44px',
            height: '44px',
            marginRight: '10px',
            objectFit: 'contain',
            borderRadius: '50%',
          }}
        />
      ) : (
        <CImage
          src={data}
          alt="Profile Picture"
          style={{
            width: '44px',
            height: '44px',
            marginRight: '10px',
            objectFit: 'contain',
            borderRadius: '50%',
          }}
          isError={() => setImgError(true)}
        />
      )}
    </div>
  )
}

const ConvertAction = ({ openedDropDown, setOpenedDropDown, item, setOpenModuleModal }) => {
  return (
    <div
      className="d-flex align-items-center gap-2  justify-content-center h-flex"
      style={{
        height: '44px',
      }}
    >
      <Link to={`/admin/lms-courses/${item}`}>
        <CButton className="btn btn-sm text-white btn-primary">View users</CButton>
      </Link>
      <CButton className="btn btn-sm " variant="outline" onClick={() => setOpenModuleModal(true)}>
        View module
      </CButton>
    </div>
  )
}

const LMSCourses = () => {
  const [openedDropDown, setOpenedDropDown] = useState(null)
  const { isLoading, data } = useGetLMSCourse()
  const [openModuleModal, setOpenModuleModal] = useState(false)
  const courses = data?.data?.data

  return (
    <>
      <ViewModuleModal visible={openModuleModal} setVisible={setOpenModuleModal} />

      {isLoading && <ShowLoading />}
      {courses && (
        <CContainer className="mt-4">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5 className="text-2 mb-3 ps-1">LMS Courses</h5>
          </div>
          <CRow className="mt-3 mb-5">
            <CCol md={12}>
              <table className="table border-0" id="reports-table">
                <thead className="thead-inverse">
                  <tr className="bg-transparent text-primary">
                    <th>User</th>
                    <th>ShortName</th>
                    <th>FullName</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {courses?.map((item, index) => {
                    return (
                      <tr key={index} className="">
                        <td>
                          <ConvertUserDisplay data={item.courseImageUrl} />
                        </td>
                        <td>{item.shortname}</td>
                        <td>{item.fullname}</td>
                        <td>
                          <ConvertAction
                            item={item.id}
                            openedDropDown={openedDropDown}
                            setOpenedDropDown={setOpenedDropDown}
                            setOpenModuleModal={setOpenModuleModal}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CCol>
          </CRow>
        </CContainer>
      )}
    </>
  )
}

export default LMSCourses
