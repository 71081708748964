/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect } from 'react'
import { MessageContext } from 'src/context/messaging/MessageProvider'

const useSocketEvent = (events) => {
  const context = useContext(MessageContext)
  const socket = context?.socket

  const listenToEvents = useCallback(() => {
    if (events?.length && socket) {
      events.forEach(({ name, cb }) => {
        const handler = (data) => cb(data)
        socket.on(name, handler)

        return () => {
          socket.off(name, handler)
        }
      })
    }
  }, [])

  useEffect(listenToEvents, [])

  return { socket }
}

export default useSocketEvent
