/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import DateSeperator from './DateSeperator'
import { CForm, CFormInput, CLoadingButton } from '@coreui/react-pro'
import useSocket from 'src/hooks/user/useSocket'
import useSocketEvent from 'src/hooks/user/useSocketEvent'
import { SOCKET_EVENTS, SOCKET_LISTENERS } from 'src/constant/constants'
import ChatView from './ChatView'
import { BiSend } from 'react-icons/bi'

const WhatsappLikeChat = ({ user }) => {
  const [messages, setMessage] = useState([])
  const [msgValue, setMsgValue] = useState('')
  // const [isTyping, setIsTyping] = useState(false)
  const { socket } = useSocket()

  useEffect(() => {
    if (user) {
      socket?.emit(SOCKET_EVENTS.CHAT_MESSAGES, {
        chat_id: user.chatId,
        memberId: user.memberId,
      })
      socket?.emit(SOCKET_EVENTS.SEEN_MESSAGE, { chat_id: user?.chatId })
    }
  }, [user])

  const handleMessageEvent = (data) => {
    setMessage((prev) => [...prev, data])
    socket?.emit(SOCKET_EVENTS.SEEN_MESSAGE, { chat_id: user?.chatId })
  }
  const handleMessageHistoryEvent = (data) => setMessage(data)

  useSocketEvent([
    {
      name: SOCKET_LISTENERS.RESPONSE_MESSAGE,
      cb: handleMessageEvent,
    },
    {
      name: SOCKET_LISTENERS.GET_ALL_CHAT_MESSAGES,
      cb: handleMessageHistoryEvent,
    },
  ])

  const handleSendMessage = () => {
    const data = { receiver_id: user?.memberId, chat_id: user.chatId, message: msgValue }
    socket?.emit(SOCKET_EVENTS.CREATE_MESSAGE, data)
    setMsgValue('')
  }

  const chatMessages = useMemo(() => messages, [messages, user])

  return (
    <div className="position-relative">
      {user ? (
        <>
          <DateSeperator date="july 29,2024" />
          <ChatView messages={chatMessages} user={user} />
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center text-muted"
          style={{ height: '71vh', overflowY: 'auto', paddingBottom: '2rem' }}
        >
          Select a user to chat with...
        </div>
      )}
      {user ? (
        <div
          style={{ borderRadius: '2rem' }}
          className="border bg-white px-2 py-1 d-flex items-center justify-content-between position-absolute bottom-0 shadow-md w-100"
        >
          <CForm
            className="w-100 d-flex align-items-center justify-content-center pe-2"
            onSubmit={(e) => e.preventDefault()}
          >
            <CFormInput
              type="text"
              id="sendMessage"
              aria-describedby="sendMessage"
              value={msgValue}
              onChange={(e) => setMsgValue(e.target.value)}
              style={{ fontSize: '12px' }}
              className="px-1 font-weight-light bg-transparent text-black rounded-0 border-white border shadow-none"
            />
            <div className="d-flex align-items-center justify-content-center gap-1">
              {/* <button className="bg-transparent btn border-0 ">
            <BiLink />
          </button>
          <button className="bg-transparent btn border-0 ">
            <BsMicFill />
          </button> */}
              <CLoadingButton
                type="submit"
                className="reg-btn px-3 w-full py-1"
                style={{ borderRadius: '' }}
                onClick={handleSendMessage}
              >
                <BiSend />
              </CLoadingButton>
            </div>
          </CForm>
        </div>
      ) : null}
    </div>
  )
}

export default WhatsappLikeChat
