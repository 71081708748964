import React from 'react';
import {
    CButton,
    CCol,
    CContainer,
    CModal, CModalBody, CModalHeader, CModalTitle,
    CRow
} from "@coreui/react-pro";

const ScholarshipStatementModal = ({visible, setVisible, statement}) => {

    return <CModal
        backdrop="static"
        visible={visible}
        alignment="center"
        onClose={() => {
            setVisible(false)
        }}
    >
        <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>Scholarship Statement</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <CRow>
                    <CCol md={12}>
                        <div dangerouslySetInnerHTML={{__html: statement}} />
                        <br/>
                        <div>
                            <CButton
                                className="btn btn-md btn-warning text-white"
                                onClick={() => setVisible(false)}
                            >Close</CButton>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </CModalBody>
    </CModal>
}

export default ScholarshipStatementModal;